import api from 'components/api'
import types from 'components/people/person/actions'
import typesPeople from 'components/people/actions'
import { notifyErrors } from 'common/notifications/actions/add'
import { normalize } from 'normalizr'
import arrayOfPeopleSchema from 'components/people/actions/schema'

const personUpdateMailTo = (value, addrId) => (dispatch, getState) => {
  const alreadyLoading = getState().people.loading.mailToId
  if (!alreadyLoading) {
    const personId = getState().people.person.id

    // 'value' is true or false
    const newVal = value ? addrId : false
    const oldVal = value ? false : addrId

    // change person's mail_to_id now (optimistic update)
    dispatch({
      type: types.PERSON_MAIL_TO_ID_START,
      personId,
      newVal,
    })

    const url = `/people/${personId}`
    return api
      .patch(url, { mail_to_id: newVal })
      .then(res => {
        const people = res.data
        const normalized = normalize(people, arrayOfPeopleSchema)

        // more than one person has this mail_to_id
        if (people.length > 1) {
          // if starting on people#show, the conflicted person needs to be added to the store (haven't hit people#index yet)
          dispatch({
            type: typesPeople.PEOPLE_MAIL_TO_ID_UPDATE,
            data: normalized,
          })
        }

        dispatch({
          type: types.PERSON_MAIL_TO_ID_SUCCESS,
          ids: normalized.result,
          currentPersonId: personId,
        })
      })
      .catch(({ response }) => {
        notifyErrors(dispatch, response)

        dispatch({
          type: types.PERSON_MAIL_TO_ID_ERROR,
          status: response.status,
          personId,
          newVal: oldVal,
        })
      })
  }
}

export default personUpdateMailTo
