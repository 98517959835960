/* eslint jsx-a11y/label-has-associated-control: 0 */ /* <- rewire, restore */
import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import { withCommas } from 'components/listings/listing/utils'
import css from 'components/listings/search/styles'

export const Component = ({ fullCount, listingCount, width }) => {
  const partialCount = `${listingCount} of ${fullCount}`
  const counts = listingCount === fullCount ? listingCount : partialCount

  return (
    <form
      className={[css.formOrListing, css.searchForm].join(' ')}
      style={{ width: `${width}px` }}
    >
      <h1
        style={{
          textAlign: 'center',
          margin: '3px 0 2px',
          fontSize: '20px',
        }}
      >
        {withCommas(counts)} Listings
      </h1>

      <label htmlFor="priceMin">Price</label>

      <label htmlFor="priceMax" className="sr-only">
        Price (max)
      </label>

      <div className={css.multiBox}>
        <Field
          className={`form-control ${css.formControl}`}
          id="priceMin"
          name="priceMin"
          autoComplete="off"
          component="input"
          type="text"
          pattern="[0-9]*"
          placeholder="Min"
        />

        <Field
          className={`form-control ${css.formControl}`}
          id="priceMax"
          name="priceMax"
          autoComplete="off"
          component="input"
          type="text"
          pattern="[0-9]*"
          placeholder="Max"
        />
      </div>

      <label htmlFor="acresMin">Acres</label>

      <label htmlFor="acresMax" className="sr-only">
        Acres (max)
      </label>

      <div className={css.multiBox}>
        <Field
          className={`form-control ${css.formControl}`}
          id="acresMin"
          name="acresMin"
          autoComplete="off"
          component="input"
          type="number"
          step="0.1"
          placeholder="Min"
        />

        <Field
          className={`form-control ${css.formControl}`}
          id="acresMax"
          name="acresMax"
          autoComplete="off"
          component="input"
          type="number"
          step="0.1"
          placeholder="Max"
        />
      </div>

      <label htmlFor="street">Street</label>
      <Field
        className={`form-control ${css.formControl}`}
        id="street"
        name="street"
        autoComplete="off"
        component="input"
        type="text"
      />

      <div className={css.multiLabel}>
        <label htmlFor="city">City</label>
        <label htmlFor="zip">Zip</label>
      </div>

      <div className={css.multiBox}>
        <Field
          className={`form-control ${css.formControl}`}
          id="city"
          name="city"
          autoComplete="off"
          component="input"
          type="text"
        />

        <Field
          className={`form-control ${css.formControl}`}
          id="zip"
          name="zip"
          autoComplete="off"
          component="input"
          type="text"
          pattern="[0-9]*"
        />
      </div>

      <div className={css.multiLabel}>
        <label htmlFor="county">County</label>
        <label htmlFor="subd">Subdivision</label>
      </div>

      <div className={css.multiBox}>
        <Field
          className={`form-control ${css.formControl}`}
          id="county"
          name="county"
          autoComplete="off"
          component="input"
          type="text"
        />

        <Field
          className={`form-control ${css.formControl}`}
          id="subd"
          name="subd"
          autoComplete="off"
          component="input"
          type="text"
        />
      </div>

      <div className={css.multiLabel}>
        <label htmlFor="mlsNum">MLS Number</label>
        <label htmlFor="sort">Sort</label>
      </div>

      <div className={css.multiBox}>
        <Field
          className={`form-control ${css.formControl}`}
          id="mlsNum"
          name="mlsNum"
          autoComplete="off"
          component="input"
          type="text"
          pattern="[0-9]*"
        />

        <Field
          className={`form-control ${css.formControl}`}
          id="sort"
          name="sort"
          component="select"
        >
          <option value="priceMin">Price: Low</option>
          <option value="priceMax">Price: High</option>
          <option value="acresMax">Acres: High</option>
          <option value="acresMin">Acres: Low</option>
          <option value="dateNew">Date: New</option>
          <option value="dateOld">Date: Old</option>
        </Field>
      </div>
    </form>
  )
}

Component.propTypes = {
  fullCount: PropTypes.number.isRequired,
  listingCount: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
}

export default reduxForm({
  form: 'ListingsLand',
  // save values when moving to show page
  destroyOnUnmount: false,
  initialValues: { sort: 'priceMin' },
})(Component)
