import types from 'components/people/person/actions'
import typesAddressee from 'components/addresses/address/addressee/actions'
import typesApp from 'components'

export const initState = []

const peopleFilteredReducer = (state = initState, action) => {
  switch (action.type) {
    case types.PERSON_MAIL_TO_ID_SUCCESS: {
      return action.ids
    }

    case typesAddressee.ADDRESSEE_FETCH_SUCCESS: {
      return action.people.result
    }

    case typesApp.LOCATION_CHANGE: {
      return initState
    }

    default: {
      return state
    }
  }
}

export default peopleFilteredReducer
