import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import history from 'components/history'
import notifyAdd, { notifyErrors } from 'common/notifications/actions/add'
import LoadingBar from 'common/loading/bar'

export class VerifyCont extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    textErr: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }

  static defaultProps = { textErr: false }

  componentDidMount() {
    const { dispatch, textErr } = this.props
    if (textErr) {
      notifyErrors(dispatch, {}, { text: textErr, timeout: 8000 })
    } else {
      notifyAdd(dispatch, { text: 'Email Verified', timeout: 5000 })
    }

    history.push('/sign-in')
  }

  render() {
    return <LoadingBar loading={true} />
  }
}

export const mapState = ({ auth }) => ({
  textErr: auth.verifyEmail && auth.verifyEmail.error,
})

export default connect(mapState)(VerifyCont)
