import React from 'react'
import { FaExternalLinkAlt } from 'react-icons/fa'

export default function ExtLinkIcon() {
  return (
    <span>
      &nbsp;
      <FaExternalLinkAlt color="#ccc" size={12} />
    </span>
  )
}
