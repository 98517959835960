import { OrderedSet } from 'immutable'
import typesApp from 'components'
import typesGuides from 'components/guides/actions'
import { process } from 'app/javascript/components/guides/reducers'

export const initState = OrderedSet([])

const guidesAllIdsReducer = ($$state = initState, action) => {
  switch (action.type) {
    case typesApp.HYDRATE: {
      if (action.location.includes('/guide')) {
        return OrderedSet(process(action.data).result)
      }

      return $$state
    }

    case typesGuides.GUIDES_FETCH_SUCCESS: {
      return OrderedSet(action.data.result)
    }

    default: {
      return $$state
    }
  }
}

export default guidesAllIdsReducer
