import api from 'components/api'
import types from 'components/taxes/actions'
import { process } from 'app/javascript/components/taxes/reducers'

export const url = '/kentucky-property-taxes.json'

const taxesFetch = () => dispatch => {
  dispatch({ type: types.TAXES_FETCH_START })

  return api
    .get(url)
    .then(res =>
      dispatch({
        type: types.TAXES_FETCH_SUCCESS,
        data: process(res.data),
      })
    )
    .catch(error =>
      dispatch({
        type: types.TAXES_FETCH_FAILURE,
        status: error.status,
      })
    )
}

export default taxesFetch
