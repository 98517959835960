import React from 'react'
import PropTypes from 'prop-types'
import BtnConfirm from 'common/buttons/confirm'
import BtnDestroy from 'common/buttons/destroy'

const BtnDestroyConfirm = ({ action, cancelPosition, css }) => (
  <BtnConfirm cancelPosition={cancelPosition}>
    <BtnDestroy action={action} classname={css.BtnDestroy} />
  </BtnConfirm>
)

BtnDestroyConfirm.propTypes = {
  action: PropTypes.func.isRequired,
  cancelPosition: PropTypes.string.isRequired,
  css: PropTypes.shape({}),
}

BtnDestroyConfirm.defaultProps = { css: { BtnDestroy: '' } }

export default BtnDestroyConfirm
