import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import fetch from 'common/comments/actions/fetch'
import LoadingBar from 'common/loading/bar'
import CommentForm from 'common/comments/new'
import selected from 'common/comments/selectors/selected'
import Comment from 'common/comments/comment'

const CommentsComponent = ({ comments, loading }) => {
  const commentList = comments.map(comment => (
    <Comment key={comment.get('id')} comment={comment} />
  ))

  return (
    <>
      <h4 style={{ marginTop: '1rem' }}>Comments</h4>

      <CommentForm />

      <div style={{ marginTop: '12px' }}>
        <LoadingBar loading={loading} />
      </div>

      <ul className="list-group">{commentList}</ul>
    </>
  )
}

const commentsPropTypes = PropTypes.arrayOf(
  ImmutablePropTypes.contains({
    id: PropTypes.number.isRequired,
    commentable_id: PropTypes.number.isRequired,
    commentable_type: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
  })
)

CommentsComponent.propTypes = {
  comments: commentsPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
}

export class CommentsContainer extends PureComponent {
  static propTypes = {
    fetch: PropTypes.func.isRequired,
    stale: PropTypes.bool.isRequired,
  }

  componentDidMount() {
    const { fetch } = this.props
    fetch()
  }

  componentDidUpdate(prevProps) {
    const { fetch, stale } = this.props
    if (stale && !prevProps.stale) {
      fetch()
    }
  }

  render() {
    return <CommentsComponent {...this.props} />
  }
}

export const mapState = state => ({
  comments: selected(state),
  loading: state.comments.loading,
  stale: state.comments.stale,
})

export default connect(
  mapState,
  { fetch }
)(CommentsContainer)
