/* eslint jsx-a11y/label-has-for: 0 */
/* eslint jsx-a11y/label-has-associated-control: 0 */
// labels with .sr-only can't wrap the input because
// they're hidden with CSS so the input will be too
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import { signIn } from 'components/auth/actions'
import css from 'components/auth/styles'

export const SignIn = ({ handleSubmit, signIn }) => (
  <form className={css.signInForm} onSubmit={handleSubmit(signIn)}>
    <div className="form-group">
      <label className="sr-only" htmlFor="email">
        Email
      </label>

      <Field
        className="form-control"
        id="email"
        name="email"
        component="input"
        type="email"
        placeholder="Email"
      />
    </div>

    <div className="form-group">
      <label className="sr-only" htmlFor="password">
        Password
      </label>

      <Field
        className="form-control"
        id="password"
        name="password"
        component="input"
        type="password"
        placeholder="Password"
      />
    </div>

    <button type="submit" className="btn btn-outline-primary">
      Sign In
    </button>

    <Link to="/forgot">Reset Password</Link>
  </form>
)

SignIn.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
}

export default connect(
  null,
  { signIn }
)(reduxForm({ form: 'SignIn' })(SignIn))
