import React from 'react'
import PropTypes from 'prop-types'
import { IconContext } from 'react-icons'
import { FiTrash2 } from 'react-icons/fi'
import Button from 'common/buttons'

const BtnDestroyComp = ({ action, classname }) => (
  <Button
    action={action}
    classes={`btn btn-sm btn-outline-danger ${classname}`}
  >
    <IconContext.Provider value={{ style: { verticalAlign: 'sub' } }}>
      <FiTrash2 />
    </IconContext.Provider>
  </Button>
)

BtnDestroyComp.propTypes = {
  action: PropTypes.func.isRequired,
  classname: PropTypes.string,
}

BtnDestroyComp.defaultProps = { classname: '' }

export default BtnDestroyComp
