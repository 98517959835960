import { createSelector } from 'reselect'
import memberOwner from 'components/people/person/memberOwner'

const getAddress = (
  addrId,
  addrText,
  memberOwner,
  isMailingToThisAddr,
  mutating,
  loading
) => ({ addrId, addrText, memberOwner, isMailingToThisAddr, mutating, loading })

export const addresses$$byIdSelector = ({ addresses }) => addresses.$$byId
export const addrIdSelector = (_, { addrId }) => addrId
export const addrTextSelector = createSelector(
  addresses$$byIdSelector,
  addrIdSelector,
  (addresses$$byId, addrId) =>
    addresses$$byId.get(String(addrId)).get('address')
)

export const people$$byIdSelector = ({ people }) => people.$$byId
export const personIdSelector = ({ people }) => people.person.id
export const personSelector = createSelector(
  people$$byIdSelector,
  personIdSelector, // personId is string
  (people$$byId, personId) => people$$byId.get(personId)
)

export const memberOwnerSelector = createSelector(
  state => state, // pass `state` through to `memberOwner`
  personIdSelector,
  (state, personId) => memberOwner(state, personId) // needed for spy
)

export const mailToIdSelector = createSelector(
  personSelector,
  person => person.getIn(['data', 'mail_to_id'])
)
export const isMailingToSelector = createSelector(
  mailToIdSelector,
  mailToId => _intOrFalse(mailToId)
)
// person mail_to_id match addrId?
export const isMailingToThisAddrSelector = createSelector(
  isMailingToSelector,
  addrIdSelector,
  (isMailingTo, addrId) => isMailingTo === addrId
)

// address ID or false
export const addrLoadingSelector = ({ people }) => people.loading.mailToId

// deleting addrId happening now?
export const deletingThisTieSelector = ({ ties }, { addrId }) =>
  ties.deleting === addrId
// mailing to addrId happening now?
export const mailToThisAddrSelector = createSelector(
  addrLoadingSelector,
  addrIdSelector,
  (addrLoading, addrId) => addrLoading === addrId
)
export const loadingSelector = createSelector(
  deletingThisTieSelector,
  mailToThisAddrSelector,
  (deleting, mailing) => deleting || mailing
)
export const mutatingSelector = ({ addresses }) => addresses.mutating

export default createSelector(
  addrIdSelector,
  addrTextSelector,
  memberOwnerSelector,
  isMailingToThisAddrSelector,
  mutatingSelector,
  loadingSelector,
  getAddress
)

export const _intOrFalse = value => {
  switch (typeof value) {
    case 'string':
      return Number(value)
    case 'object':
      return false // null
    default:
      return value
  }
}
