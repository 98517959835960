import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import getPerson from 'components/people/person/get'
import getPersonFullName from 'components/people/person/namePassword/name/getFull'
import Edit from 'components/people/person/namePassword/name/edit'
import css from 'components/people/person/namePassword/name/styles'

export const NameDisplay = ({ editing, name, visible }) => {
  if (!visible) {
    return null
  }

  return editing ? <Edit /> : <div className={css.text}>{name}</div>
}

NameDisplay.propTypes = {
  editing: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
}

export const mapState = state => {
  const { person } = state.people
  const personObj = getPerson(state, person.id)

  return {
    editing: person.editing === 'name',
    name: getPersonFullName(personObj),
    visible: person.editing !== 'password',
  }
}

export default connect(mapState)(NameDisplay)
