import api from 'components/api'
import types from 'components/schools/actions'
import { notifyErrors } from 'common/notifications/actions/add'
import { normalize } from 'normalizr'
import { arrayOfSchoolsSchema } from 'components/schools/actions/schema'

const schoolsFetch = () => (dispatch, getState) => {
  const needRates = getState().schools.$$allIds.size === 0
  if (needRates) {
    dispatch({ type: types.SCHOOLS_FETCH_START })

    return api
      .get('/schools.json')
      .then(res =>
        dispatch({
          type: types.SCHOOLS_FETCH_SUCCESS,
          data: normalize(res.data, arrayOfSchoolsSchema),
        })
      )
      .catch(({ response }) => {
        notifyErrors(dispatch, response)

        const status = response.status
        dispatch({ type: types.SCHOOLS_FETCH_FAILURE, status })
      })
  }
}

export default schoolsFetch
