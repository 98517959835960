import { fromJS } from 'immutable'
import typesApp from 'components'
import typesCounties from 'components/counties/actions'
import { process } from 'app/javascript/components/counties/reducers'

export const initState = fromJS({})

const countiesByIdReducer = ($$state = initState, action) => {
  switch (action.type) {
    case typesApp.HYDRATE: {
      if (action.location.includes('/counties')) {
        return fromJS(process(action.data).entities.counties)
      }

      return $$state
    }

    case typesCounties.COUNTIES_FETCH_SUCCESS: {
      return fromJS(action.data.entities.counties)
    }

    default: {
      return $$state
    }
  }
}

export default countiesByIdReducer
