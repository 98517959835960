import { createSelector } from 'reselect'

const id = (_, id) => id
const $$byId = ({ agents }) => agents.$$byId
const agent = (id, $$byId) => $$byId.get(String(id))
const getAgent = createSelector(
  id,
  $$byId,
  agent
)

export default getAgent
