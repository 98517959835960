import React from 'react'
import PropTypes from 'prop-types'
import { withCommas } from 'components/listings/listing/utils'

export const SqFt = ({ listing }) => {
  if (listing.get('ki') === 1 || !!listing.get('glar')) {
    return null
  }

  return (
    <table className="table">
      <tbody>
        <tr>
          <th>Total Finished SqFt:</th>
          <td>{withCommas(listing.get('sqft'))}</td>
        </tr>
        <tr>
          <th>Above Ground SqFt:</th>
          <td>{withCommas(listing.get('squp') || 0)}</td>
        </tr>
        <tr>
          <th>Bsmt Finished SqFt:</th>
          <td>{withCommas(listing.get('sqdnf') || 0)}</td>
        </tr>
        <tr>
          <th>Bsmt Total SqFt:</th>
          <td>{withCommas(listing.get('sqdn') || 0)}</td>
        </tr>
        <tr>
          <th>Bsmt Percent Finished:</th>
          <td>{listing.get('bsmt_pct_fin') || 0}%</td>
        </tr>
        <tr>
          <th>Level 1 SqFt:</th>
          <td>{withCommas(listing.get('sq1') || 0)}</td>
        </tr>
        <tr>
          <th>Level 2 SqFt:</th>
          <td>{withCommas(listing.get('sq2') || 0)}</td>
        </tr>
        <tr>
          <th>Level 3 SqFt:</th>
          <td>{withCommas(listing.get('sq3') || 0)}</td>
        </tr>
      </tbody>
    </table>
  )
}
SqFt.propTypes = { listing: PropTypes.shape() }
SqFt.defaultProps = { listing: undefined }

export default SqFt
