/* eslint jsx-a11y/label-has-associated-control: 0 */
// labels with .sr-only can't wrap the input because
// they're hidden with CSS so the input will be too
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import types from 'components/people/person/actions'
import onKeyDown from 'common/formUtils/onKeyDown'
import update from 'components/people/person/namePassword/password/update'
import css from 'components/people/person/namePassword/password/styles'

export const PwEditComp = ({ handleSubmit, onKeyDown, update }) => (
  <form className={`${css.form} form-inline`} onSubmit={handleSubmit(update)}>
    <label className="sr-only" htmlFor="password">
      New Password
    </label>

    <Field
      className="form-control"
      id="password"
      name="password"
      component="input"
      type="password"
      placeholder="New Password"
      autoComplete="off"
      autoFocus={true}
      onKeyDown={onKeyDown}
    />

    <button type="submit" className={`btn btn-outline-primary ${css.save}`}>
      Save
    </button>
  </form>
)

PwEditComp.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
}

export const mapDispatch = dispatch => {
  const cb = () => dispatch({ type: types.PERSON_PASSWORD_EDIT_TOGGLE })

  return {
    update: data => dispatch(update(data)),
    onKeyDown: event => onKeyDown(event, cb),
  }
}

export default connect(
  null,
  mapDispatch
)(reduxForm({ form: 'PersonPasswordEdit' })(PwEditComp))
