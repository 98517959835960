import { createSelector } from 'reselect'

const getComments = ($$byId, $$ids, personId) =>
  $$ids.reduce((finalCommentList, id) => {
    const comment = $$byId.get(String(id))

    const typeIsPerson = comment.get('commentable_type') === 'Person'
    const thisCommentableId = comment.get('commentable_id')
    const thisPersonsComment = String(thisCommentableId) === personId

    if (typeIsPerson && thisPersonsComment) {
      finalCommentList.push(comment)
    }

    return finalCommentList
  }, []) // this array becomes the 'finalCommentList'

const $$byId = ({ comments }) => comments.$$byId
const $$ids = ({ comments }) => comments.$$allIds
const personId = ({ people }) => people.person.id

export default createSelector(
  $$byId,
  $$ids,
  personId,
  getComments // last argument has select logic
)
