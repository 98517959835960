import { createSelector } from 'reselect'
import queryString from 'query-string'

const parsePageNum = query => {
  const parsed = queryString.parse(query)
  const page = Number(parsed.page || 1)
  return page < 1 ? 1 : page
}

export default createSelector(
  ({ search }) => search,
  parsePageNum
)
