import { fromJS } from 'immutable'
import typesApp from 'components'
import types from 'common/notifications/actions'

export const initState = fromJS({})

export default function notificationsByIdReducer($$state = initState, action) {
  switch (action.type) {
    case typesApp.LOCATION_CHANGE: {
      return initState
    }

    case types.NOTIFICATIONS_ADD: {
      return $$state.merge({
        [action.data.id]: { ...action.data },
      })
    }

    case types.NOTIFICATIONS_REMOVE: {
      return $$state.delete(action.data.toString())
    }

    default: {
      return $$state
    }
  }
}
