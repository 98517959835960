import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import MemberEdit from 'components/members/selectTag'
import memberIdEdit from 'components/people/person/actions/memberId'
import getPerson from 'components/people/person/get'

export const MemberEditFormComp = ({ memberId, memberIdEdit }) => (
  <form className="form-group form-inline">
    <MemberEdit selectValue={memberId} handleChange={memberIdEdit} />
  </form>
)

MemberEditFormComp.propTypes = {
  memberId: PropTypes.string.isRequired,
  memberIdEdit: PropTypes.func.isRequired,
}

export const mapState = state => {
  const { id } = state.people.person
  const person = getPerson(state, id)
  const memberId = String(person.get('member_id'))

  return { memberId }
}

export default connect(
  mapState,
  { memberIdEdit }
)(MemberEditFormComp)
