import { OrderedSet } from 'immutable'
import types from 'components/addresses/actions'
import typesAuth from 'components/auth/actions'
import typesPerson from 'components/people/person/actions'
import typesApp from 'components'

export const initState = OrderedSet([])

const addrAllIdsReducer = ($$state = initState, action) => {
  switch (action.type) {
    case typesApp.HYDRATE:
    case typesAuth.SIGN_IN_SUCCESS:
    case typesPerson.PERSON_FETCH_SUCCESS:
    case types.ADDRESS_UNTIED_FETCH_SUCCESS:
    case types.VERIFY_FETCH_SUCCESS: {
      const entities = action.data.entities
      if (entities) {
        const desiredObjects = entities.addresses
        if (desiredObjects) {
          return $$state.union(OrderedSet.fromKeys(desiredObjects))
        }
      }

      return $$state
    }

    default: {
      return $$state
    }
  }
}

export default addrAllIdsReducer
