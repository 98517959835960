import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Airbrake from 'common/errorBoundary/airbrake'

export const ErrorComp = () => (
  <h3 style={{ textAlign: 'center' }}>
    Something went wrong; we'll get it fixed.
    <br />
    Please reload the page to try again.
  </h3>
)

class ErrorBoundary extends Component {
  static propTypes = { children: PropTypes.node.isRequired }

  constructor(props) {
    super(props)
    this.state = { hasError: false }
    this.airbrake = Airbrake
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true })

    this.airbrake.notify({
      error: error,
      params: { info: info },
    })
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props

    return hasError ? <ErrorComp /> : children
  }
}

export default ErrorBoundary
