import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { fetch as fetchDetails } from 'components/details/actions'
import fetchPeople from 'components/people/actions/fetch'
import ImmutablePropTypes from 'react-immutable-proptypes'
import Pagination, { paginationData } from 'common/pagination'
import setPageTitle from 'components/utils/setPageTitle'
import selected from 'components/people/selectors/selected'
import Search from 'components/people/search'
import Person from 'components/people/indexRow'
import BreadcrumbPeople from 'components/people/breadcrumb'
import css from 'components/people/styles'

export class People extends PureComponent {
  static propTypes = {
    fetchDetails: PropTypes.func.isRequired,
    fetchPeople: PropTypes.func.isRequired,
    goToPage: PropTypes.func.isRequired,
    location: PropTypes.shape({}).isRequired,
    page: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    pageRecords: ImmutablePropTypes.listOf(PropTypes.string).isRequired,
    recordCountTotal: PropTypes.number.isRequired,
    selectedRecords: ImmutablePropTypes.list.isRequired,
  }

  constructor(props) {
    super(props)

    // fetching so someone can enter at ?page=6 and not redirect to ?page=1
    this.state = { fetching: true }
  }

  componentDidMount() {
    const { fetchDetails, fetchPeople } = this.props
    fetchPeople().then(() => this.setState({ fetching: false }))
    fetchDetails()
    this.pageTitleUpdate()
  }

  componentWillUpdate(nextProps) {
    this.pageNumberShouldChange(nextProps)
  }

  componentDidUpdate(prevProps) {
    this.pageShouldScrollToTop(prevProps)
    this.pageTitleShouldUpdate(prevProps)
  }

  componentWillUnmount() {
    setPageTitle('')
  }

  pageNumberShouldChange = nextProps => {
    const { goToPage, selectedRecords } = this.props

    const selectedRecordsChanged = selectedRecords !== nextProps.selectedRecords
    const { fetching } = this.state
    if (!fetching && selectedRecordsChanged) {
      goToPage(1) // selectedRecordsChanged so reset to page 1
    }
  }

  pageShouldScrollToTop = prevProps => {
    const { page } = this.props
    if (prevProps.page !== page) {
      window.scrollTo(0, 0)
    }
  }

  pageTitleShouldUpdate = prevProps => {
    const { page, pageCount } = this.props
    const prevPage = prevProps.page
    const prevPageCount = prevProps.pageCount
    const differentPage = page !== prevPage
    const differentPageCount = pageCount !== prevPageCount

    if (differentPage || differentPageCount) {
      this.pageTitleUpdate()
    }
  }

  pageTitleUpdate = () => {
    const { page, pageCount } = this.props
    let text = 'People'
    if (pageCount !== 0) {
      text = `${text}: page ${page} / ${pageCount}`
    }
    setPageTitle(text)
  }

  render() {
    const { location, pageCount, recordCountTotal, pageRecords } = this.props

    const people = pageRecords.map(id => <Person key={id} id={id} />)
    const peopleList = <ul className={`list-group ${css.list}`}>{people}</ul>

    return (
      <>
        <BreadcrumbPeople count={recordCountTotal} />
        <Search />
        {peopleList}
        <Pagination location={location} pages={pageCount} />
      </>
    )
  }
}

export const mapState = (state, { location }) =>
  paginationData(selected(state), location, 30)

export default connect(
  mapState,
  { fetchDetails, fetchPeople }
)(People)
