import api from 'components/api'
import { notifyErrors } from 'common/notifications/actions/add'
import types from 'components/people/person/actions'

const personUpdatePassword = data => (dispatch, getState) => {
  if (!data.password) {
    dispatch({ type: types.PERSON_PASSWORD_EDIT_TOGGLE })
  } else {
    dispatch({ type: types.PERSON_PASSWORD_UPDATE_START })

    const personId = getState().people.person.id
    return api
      .patch(`/people/${personId}`, data)
      .then(() => dispatch({ type: types.PERSON_PASSWORD_UPDATE_SUCCESS }))
      .catch(({ response }) => {
        notifyErrors(dispatch, response)

        dispatch({
          type: types.PERSON_PASSWORD_UPDATE_FAILURE,
          status: response.status,
        })
      })
  }
}

export default personUpdatePassword
