import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import peopleFilterClear from 'components/people/actions/filterClear'
import PersonCreateBtn from 'components/people/createBtn'
import selectOptions from 'components/members/selectTag/options'
import memberSelector from 'components/members/selectTag/selected'
import currMemId from 'components/people/search/currMemId'
import Kind, { promptVal } from 'components/people/search/kind'
import css from 'components/people/search/styles'

export const ampersand = value => value && value.replace(/&/g, 'and')

export const FormComponent = ({ handleSubmit, onKeyDown, submitting }) => (
  <>
    <form className="form-group form-inline">
      <Field
        name="emailOrPhone"
        placeholder="Email or Phone"
        component={textInput}
        onKeyDown={onKeyDown}
      />

      <Field name="kind" placeholder="Kind" component={Kind} />

      <Field name="postcards" placeholder="Postcards" component={Postcards} />
    </form>

    <form className="form-group form-inline">
      <Field
        name="firstName"
        placeholder="First"
        component={textInput}
        onKeyDown={onKeyDown}
        normalize={ampersand}
      />

      <Field
        name="lastName"
        placeholder="Last"
        component={textInput}
        onKeyDown={onKeyDown}
        normalize={ampersand}
      />

      <Field
        name="memberId"
        placeholder="Member"
        component={ConnectedMembers}
      />

      <PersonCreateBtn handleSubmit={handleSubmit} submitting={submitting} />
    </form>
  </>
)

FormComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

const textInput = field => (
  <input
    {...field.input}
    className={`form-control ${css.inline}`}
    type="text"
    placeholder={field.placeholder}
    autoComplete="off"
    onKeyDown={field.onKeyDown}
  />
)

const Members = props => {
  const { input, meta } = props
  const isOpen = meta.active
  const prompt = isOpen ? 'Any Member' : 'Member'

  props = {
    ...props,
    prompt,
    promptDisabled: false,
  }

  const fieldHasVal = input.value !== ''
  const placeholder = {}
  if (meta.pristine || !fieldHasVal) {
    placeholder.color = '#999'
  }

  return (
    <select
      className={`form-control ${css.inline}`}
      style={placeholder}
      {...input}
    >
      {selectOptions(props)}
    </select>
  )
}

Members.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
  }).isRequired,
}

export const ConnectedMembers = connect(state => ({
  isAdmin: !!state.auth.isAdmin,
  members: memberSelector(state),
}))(Members)

const Postcards = props => {
  const { input, meta } = props
  const isOpen = meta.active
  const prompt = isOpen ? 'Both' : 'Postcards'

  props = {
    ...props,
    prompt,
  }

  const fieldHasVal = input.value !== ''
  const placeholder = {}
  if (meta.pristine || !fieldHasVal) {
    placeholder.color = '#999'
  }

  return (
    <select
      className={`form-control ${css.inline}`}
      style={placeholder}
      {...input}
    >
      <option key="postcards-any" value="">
        {prompt}
      </option>
      <option key="postcards-yes" value={true}>
        Postcards: Yes
      </option>
      <option key="postcards-no" value={false}>
        Postcards: No
      </option>
    </select>
  )
}

Postcards.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
  }).isRequired,
}

export const formName = 'peopleSearch'

export const mapState = state => {
  const result = {
    enableReinitialize: true, // values update on state change
    keepDirtyOnReinitialize: true, // dirty fields not reset
    initialValues: { kind: promptVal },
  }

  const notManager = !state.auth.isManager
  const form = state.form[formName]
  if (notManager && form && form.values) {
    const currMemberId = currMemId(state)
    result.initialValues.memberId = currMemberId

    const keys = Object.keys(form.values)
    const dirty = keys.filter(key => {
      const theseFields = ['firstName', 'lastName', 'emailOrPhone']
      if (theseFields.includes(key)) {
        // just need to know if a field is dirty
        return (
          form.values['firstName'] ||
          form.values['lastName'] ||
          form.values['emailOrPhone']
        )
      }
    })
    const hasFirstOrLast = dirty.length > 0

    const kindNotSet = [promptVal, undefined].includes(form.values.kind)
    if (hasFirstOrLast && kindNotSet) {
      result.initialValues.memberId = ''
    }

    // agents can only filter their own people
    if (form.values.memberId !== currMemberId) {
      result.kind = promptVal
    }
  }

  return result
}

class Form extends PureComponent {
  static propTypes = {
    change: PropTypes.func.isRequired,
    kind: PropTypes.string,
  }

  static defaultProps = {
    kind: null,
  }

  componentWillReceiveProps(nextProps) {
    const { change } = this.props
    if (nextProps.kind) {
      change('kind', nextProps.kind)
    }
  }

  render() {
    return <FormComponent {...this.props} />
  }
}

export default connect(
  mapState,
  { onKeyDown: peopleFilterClear } // not passed through for tests?
)(reduxForm({ form: formName })(Form))
