import { combineReducers } from 'redux'

import $$byId from 'components/addresses/reducers/byId'
import $$allIds from 'components/addresses/reducers/allIds'
import $$untiedIds from 'components/addresses/reducers/untiedIds'
import mutating from 'components/addresses/reducers/mutating'
import suggestionSearchText from 'components/addresses/reducers/suggestionSearchText'
import madeSuggestion from 'components/addresses/reducers/madeSuggestion'
import loading from 'components/addresses/reducers/loading'
import currentRequest from 'components/addresses/reducers/currentRequest'
import $$suggestions from 'components/addresses/reducers/suggestions'

export default combineReducers({
  $$byId,
  $$allIds,
  $$untiedIds,
  mutating,
  suggestionSearchText,
  madeSuggestion,
  loading,
  currentRequest,
  $$suggestions,
})

// // // Avoid Action Creator and state naming collision!!!
// // // suggestionSearchText
// // // madeSuggestion
