import types from 'components/details/actions'
import getDetail from 'components/details/detail/get'

const detailEdit = id => (dispatch, getState) => {
  const detail = getDetail(getState(), id)
  dispatch({
    type: types.DETAIL_EDIT_TOGGLE,
    category: `${detail.get('category')}s`,
    id,
  })
}

export default detailEdit
