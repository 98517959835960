import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Breadcrumb from 'common/breadcrumb/component'

export const BreadcrumbPeopleComp = ({ isManager, count }) => {
  const postcardLink = (
    <li>
      <a href="/members.csv">Postcard Lists</a>
    </li>
  )

  return (
    <Breadcrumb>
      <li>
        {count} {count !== 1 ? 'People' : 'Person'}
      </li>

      {isManager && postcardLink}
    </Breadcrumb>
  )
}

BreadcrumbPeopleComp.propTypes = {
  count: PropTypes.number.isRequired,
  isManager: PropTypes.bool,
}

BreadcrumbPeopleComp.defaultProps = {
  isManager: null,
}

export const mapState = ({ auth }, { count }) => ({
  isManager: auth.isManager,
  count,
})

export default connect(mapState)(BreadcrumbPeopleComp)
