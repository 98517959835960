import types from 'components/addresses/actions'
import typesTies from 'components/ties/actions'
import typesPerson from 'components/people/person/actions'
import typesApp from 'components'

export const initState = false

export default function loadingReducer(state = initState, action) {
  switch (action.type) {
    case typesPerson.PERSON_MAIL_TO_ID_START:
    case typesTies.TIES_DEL_PER_ADDR_START:
    case types.ADDRESS_MOVE_START:
    case types.ADDRESS_LOADING_START: {
      return true
    }

    case typesPerson.PERSON_MAIL_TO_ID_SUCCESS:
    case typesPerson.PERSON_MAIL_TO_ID_ERROR:
    case types.ADDRESS_MOVE_SUCCESS:
    case types.ADDRESS_MOVE_FAILURE:
    case types.SUGGESTIONS_CLEAR:
    case types.SUGGESTIONS_FETCH_SUCCESS:
    case types.SUGGESTIONS_FETCH_ERROR:
    case types.VERIFY_FETCH_SUCCESS:
    case types.VERIFY_FETCH_ERROR:
    case typesTies.TIES_DEL_PER_ADDR_SUCCESS:
    case typesTies.TIES_DEL_PER_ADDR_FAILURE:
    case typesApp.LOCATION_CHANGE: {
      return false
    }

    default: {
      return state
    }
  }
}
