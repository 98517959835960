import { createSelector } from 'reselect'
import onClient from 'components/utils/onClient'

const onClientSelector = () => onClient
const titleSelector = title => title
const titleSetter = (onClient, title) => {
  if (onClient) {
    document.title = title
  }
}

export default createSelector(
  onClientSelector,
  titleSelector,
  titleSetter
)
