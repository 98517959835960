import React from 'react'
import PropTypes from 'prop-types'

const RoomSizes = ({ sizes }) => {
  const order = [
    'kit',
    'din',
    'liv',
    'fam',
    'mbd',
    'mba',
    'bd2',
    'bd3',
    'bd4',
    'bd5',
    'oth1',
    'oth2',
    'oth3',
  ]

  const meta = {
    kit: 'Kitchen',
    din: 'Dining',
    liv: 'Living',
    fam: 'Family',
    mbd: 'Master Bed',
    mba: 'Master Bath',
    bd2: 'Bedroom 2',
    bd3: 'Bedroom 3',
    bd4: 'Bedroom 4',
    bd5: 'Bedroom 5',
    oth1: 'Other',
    oth2: 'Other',
    oth3: 'Other',
  }

  const Rows = () => {
    return order.map(key => {
      if (sizes[key]) {
        return (
          <tr key={key}>
            <th>{meta[key]}:</th>
            <td>{sizes[key]}</td>
          </tr>
        )
      }

      return null
    })
  }

  return (
    <table className="table">
      <tbody>
        <Rows />
      </tbody>
    </table>
  )
}

RoomSizes.propTypes = { sizes: PropTypes.shape().isRequired }

export default RoomSizes
