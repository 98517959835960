import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withCommas } from 'components/listings/listing/utils'

export const RegZComp = ({ mortgage }) => {
  let { rate, term, down } = mortgage

  if (down < 1) {
    down = down === 0 ? `$${down}` : `${down * 100}%`
  } else {
    down = `$${withCommas(down)}`
  }

  return (
    <div>
      {`* ${term} years @ ${rate}% with ${down} down (taxes and insurance are extra)`}
    </div>
  )
}

RegZComp.propTypes = {
  mortgage: PropTypes.shape({
    down: PropTypes.number.isRequired,
    rate: PropTypes.number.isRequired,
    term: PropTypes.number.isRequired,
  }).isRequired,
}

export const mapState = ({ auth }) => ({ mortgage: auth.mortgage })
export default connect(mapState)(RegZComp)
