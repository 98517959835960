import React, { PureComponent } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import types from 'common/notifications/actions'
import css from 'common/notifications/notification/styles'

export class Notification extends PureComponent {
  static propTypes = {
    notification: ImmutablePropTypes.contains({
      id: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
      timeout: PropTypes.number,
      type: PropTypes.string,
    }),
    remove: PropTypes.func.isRequired,
  }

  // will be removed from store before this unmounts (due to CSSTransitionGroup)
  static defaultProps = { notification: null }

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillMount() {
    // save notification data here becuse it will be removed from the store and can't transition (CSSTransitionGroup) from the DOM without data
    const { notification } = this.props
    this.setState({ notification })
  }

  componentDidMount() {
    const { notification, remove } = this.props
    const time = notification.get('timeout')

    if (time) {
      setTimeout(() => {
        remove(notification.get('id'))
      }, time)
    }
  }

  deactivate = () => {
    const { notification } = this.state
    const { remove } = this.props
    remove(notification.get('id'))
  }

  render() {
    const { notification } = this.state

    const type = notification.get('type') || 'notification'
    return (
      <div className={css[type]}>
        {notification.get('text')}

        <button type="button" className={css.close} onClick={this.deactivate}>
          ✕
        </button>
      </div>
    )
  }
}

const mapState = ({ notifications }, { id }) => ({
  notification: notifications.$$byId.get(String(id)),
})

const mapDispatch = (dispatch, { id }) => ({
  remove: () =>
    dispatch({
      type: types.NOTIFICATIONS_REMOVE,
      data: id,
    }),
})

export default connect(
  mapState,
  mapDispatch
)(Notification)
