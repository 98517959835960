const onKeyDown = (event, cb) => {
  // 27 is escape key
  if (event.keyCode === 27) {
    cb()

    // So redux-form (firefox) doesn't issue another 'change'
    // with old value which prevents resetting to ''
    event.preventDefault()
  }
}

export default onKeyDown
