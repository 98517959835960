import api from 'components/api'
import notifyAdd, { notifyErrors } from 'common/notifications/actions/add'
import { normalize } from 'normalizr'
import * as schema from 'components/details/actions/schema'
import types from 'components/details/actions'

const detailVerify = detail => dispatch => {
  const category = `${detail.category}s`

  dispatch({ type: types.DETAIL_VERIFY_START, category })

  return api
    .patch(`/details/${detail.id}`)
    .then(res => {
      dispatch({
        type: types.DETAIL_VERIFY_SUCCESS,
        category,
        data: normalize(res.data, schema.detail),
      })

      if (category === 'emails') {
        const text = `Check ${detail.info_formatted} for a verification link`
        notifyAdd(dispatch, { text, timeout: 8000 })
      }
    })
    .catch(({ response }) => {
      notifyErrors(dispatch, response)

      dispatch({
        type: types.DETAIL_VERIFY_FAILURE,
        category,
        status: response.status,
      })
    })
}

export default detailVerify
