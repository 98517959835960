import { OrderedSet } from 'immutable'
import typesApp from 'components'

export const initState = OrderedSet([])

const agentsAllIdsReducer = ($$state = initState, action) => {
  switch (action.type) {
    case typesApp.HYDRATE: {
      // skip this when location === /agents
      const entities = action.members.entities
      if (entities && entities.agents) {
        return OrderedSet(Object.keys(entities.agents))
      }

      return $$state
    }

    default: {
      return $$state
    }
  }
}

export default agentsAllIdsReducer
