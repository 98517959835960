import { fromJS } from 'immutable'
import types from 'components/addresses/actions'

export const initState = fromJS([])

export default function addrAllIdsReducer($$state = initState, action) {
  switch (action.type) {
    case types.ADDRESS_UNTIED_FETCH_SUCCESS: {
      let addrs

      const desiredObjects = action.data.entities.addresses
      if (desiredObjects) {
        addrs = Object.keys(desiredObjects)
      }

      // Just replace state incase something is deleted
      return fromJS(addrs || [])
    }

    default: {
      return $$state
    }
  }
}
