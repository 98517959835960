import React from 'react'
import PropTypes from 'prop-types'
import css from 'common/breadcrumb/styles'

const Breadcrumb = ({ classes, children }) => (
  <ol className={`${css.style} ${classes}`}>{children}</ol>
)

Breadcrumb.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.string,
}

Breadcrumb.defaultProps = {
  classes: '',
}

export default Breadcrumb
