import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FaRegEdit, FaTimes } from 'react-icons/fa'
import typesAddresses from 'components/addresses/actions'
import typesDetails from 'components/details/actions'
import css from 'components/details/list/mutateBtn/styles'

export const MutateButton = ({ mutating, toggle, title }) => (
  <button
    type="button"
    className={`btn btn-sm btn-outline-primary ${css.btn}`}
    onClick={toggle}
  >
    {mutating ? <FaTimes /> : <FaRegEdit />}
    &nbsp;
    <span>{title}</span>
  </button>
)

MutateButton.propTypes = {
  mutating: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
}

const isAddress = title => title.indexOf('ddress') > -1

export const mapState = (state, { title }) => {
  if (isAddress(title)) {
    return { mutating: state.addresses.mutating }
  }

  return { mutating: !!state.details.mutating[title.toLowerCase()] }
}

export const mapDispatch = (dispatch, { title }) => {
  if (isAddress(title)) {
    const action = { type: typesAddresses.ADDRESSES_MUTATE_TOGGLE }
    return { toggle: () => dispatch(action) }
  }

  const action = {
    type: typesDetails.DETAILS_MUTATE_TOGGLE,
    category: title.toLowerCase(),
  }
  return { toggle: () => dispatch(action) }
}

export default connect(
  mapState,
  mapDispatch
)(MutateButton)
