import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import selectedCounties from 'components/taxes/selected'
import LoadingBar from 'common/loading/bar'
import Search from 'components/taxes/search'
import County from 'components/taxes/county'
import ValueBox from 'components/taxes/valueBox'

const TaxFormComp = ({ counties, handleSubmit, loading, values }) => (
  <form className="form-group" onSubmit={handleSubmit}>
    <h1>Kentucky Property Taxes</h1>

    <LoadingBar loading={loading} />

    {!loading && (
      <div className="form-inline">
        <ValueBox />
        <Search />
      </div>
    )}

    {!loading &&
      counties.map(county => (
        <County key={county.id} county={county} value={values.value} />
      ))}
  </form>
)

TaxFormComp.propTypes = {
  counties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  values: PropTypes.shape({ value: PropTypes.string }).isRequired,
}

const mapStateTaxForm = (state, { values }) => ({
  counties: selectedCounties(state, values.location),
  loading: state.taxes.$$byCounty.size === 0,
})

export default connect(mapStateTaxForm)(TaxFormComp)
