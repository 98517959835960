import { fromJS } from 'immutable'
import typesApp from 'components'
import typesGuides from 'components/guides/actions'
import { process } from 'app/javascript/components/guides/reducers'

export const initState = fromJS({})

const guidesByIdReducer = ($$state = initState, action) => {
  switch (action.type) {
    case typesApp.HYDRATE: {
      if (action.location.includes('/guide')) {
        return fromJS(process(action.data).entities.guides)
      }

      return $$state
    }

    case typesGuides.GUIDES_FETCH_SUCCESS: {
      return fromJS(action.data.entities.guides)
    }

    default: {
      return $$state
    }
  }
}

export default guidesByIdReducer
