import api from 'components/api'
import { notifyErrors } from 'common/notifications/actions/add'
import types from 'components/bah/actions'

const bahRatesFetch = () => (dispatch, getState) => {
  const needRates = getState().bah.all.size === 0
  if (needRates) {
    dispatch({ type: types.BAH_RATES_FETCH_START })

    return api
      .get('/bah-rates.json')
      .then(res =>
        dispatch({
          type: types.BAH_RATES_FETCH_SUCCESS,
          data: res.data,
        })
      )
      .catch(({ response }) => {
        notifyErrors(dispatch, response)

        const status = response.status
        dispatch({ type: types.BAH_RATES_FETCH_FAILURE, status })
      })
  }
}

export default bahRatesFetch
