import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import LoadingBar from 'common/loading/bar'
import css from 'common/loading/page/styles'

export const LoadingPageComp = ({ loading }) => (
  <div className={css.container}>
    <LoadingBar loading={loading} />
  </div>
)

LoadingPageComp.propTypes = { loading: PropTypes.bool.isRequired }

export const mapState = ({ loading }) => ({ loading })

export default connect(mapState)(LoadingPageComp)
