import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Switch from '@material-ui/core/Switch'
import personUpdateMailTo from 'components/people/person/actions/mailToId'
import Addressee from 'components/addresses/address/addressee'
import mapState from 'components/addresses/address/selected'

export const AdminComp = ({
  addrId,
  mutating,
  memberOwner,
  isMailingToThisAddr,
  personUpdateMailTo,
}) => {
  if (memberOwner) {
    return (
      <span style={{ marginLeft: mutating ? '-0.5rem' : '-0.8rem' }}>
        <Switch
          checked={isMailingToThisAddr}
          onChange={personUpdateMailTo}
          color="primary"
        />

        {isMailingToThisAddr && <Addressee addrId={addrId} />}
      </span>
    )
  }

  return null
}

AdminComp.propTypes = {
  addrId: PropTypes.number.isRequired,
  isMailingToThisAddr: PropTypes.bool.isRequired,
  memberOwner: PropTypes.bool.isRequired,
  mutating: PropTypes.bool.isRequired,
  personUpdateMailTo: PropTypes.func.isRequired,
}

export const mapDispatch = (dispatch, { addrId }) => ({
  personUpdateMailTo: ({ target }) =>
    dispatch(personUpdateMailTo(target.checked, addrId)),
})

export default connect(
  mapState,
  mapDispatch
)(AdminComp)
