import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import getPerson from 'components/people/person/get'
import forUrl from 'common/parameterize'
import getMemberFirstName from 'components/members/getFirstName'
import getPersonFullName from 'components/people/person/namePassword/name/getFull'
import css from 'components/people/indexRow/styles'

export const PersonIndexRow = ({ id, name, memberName }) => (
  <li className={`${css.listItem} list-group-item`}>
    <Link to={`/people/${id}-${forUrl(name)}`}>{name}</Link>
    <span>{memberName}</span>
  </li>
)

PersonIndexRow.propTypes = {
  id: PropTypes.string.isRequired,
  memberName: PropTypes.string,
  name: PropTypes.string.isRequired,
}

PersonIndexRow.defaultProps = { memberName: null }

export const mapState = (state, { id }) => {
  const person = getPerson(state, id)

  return {
    memberName: getMemberFirstName(state, person.get('member_id')),
    name: getPersonFullName(person) || '(no name)',
  }
}

export default connect(mapState)(PersonIndexRow)
