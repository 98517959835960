import api from 'components/api'
import { notifyErrors } from 'common/notifications/actions/add'
import { normalize } from 'normalizr'
import arrayOfPeopleSchema from 'components/people/actions/schema'
import types from 'components/addresses/address/addressee/actions'

const addresseeFetch = addrId => {
  return dispatch => {
    dispatch({ type: types.ADDRESSEE_FETCH_START })

    return api
      .get(`/addresses/${addrId}.json`)
      .then(res =>
        dispatch({
          type: types.ADDRESSEE_FETCH_SUCCESS,
          address: res.data.addresses[0],
          people: normalize(res.data.people, arrayOfPeopleSchema),
        })
      )
      .catch(({ response }) => {
        notifyErrors(dispatch, response)

        dispatch({
          type: types.ADDRESSEE_FETCH_FAILURE,
          status: response.status,
        })
      })
  }
}

export default addresseeFetch
