import api from 'components/api'
import history from 'components/history'
import { notifyErrors } from 'common/notifications/actions/add'
import { normalize } from 'normalizr'
import * as schema from 'common/comments/actions/schema'
import types from 'common/comments/actions'

const commentsFetch = () => dispatch => {
  dispatch({ type: types.COMMENTS_FETCH_START })
  const { pathname } = history.location

  return api
    .get(`${pathname}/comments`)
    .then(res =>
      dispatch({
        type: types.COMMENTS_FETCH_SUCCESS,
        data: normalize(res.data, schema.arrayOfComments),
      })
    )
    .catch(({ response }) => {
      const custom = { text: 'Could not load comments' }
      notifyErrors(dispatch, response, custom)

      dispatch({
        type: types.COMMENTS_FETCH_FAILURE,
        status: response.status,
      })
    })
}

export default commentsFetch
