/* eslint jsx-a11y/no-autofocus: 0 */
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import setPageTitle from 'components/utils/setPageTitle'
import isBlank from 'common/formUtils/validations'
import ErrorMessage from 'common/errorMessage'
import css from 'components/auth/styles'

export const FieldWithSubmit = props => {
  const {
    attrs,
    input,
    meta: { error, submitting, submitFailed },
  } = props
  const showError = submitFailed && error
  const errClass = showError ? 'is-invalid' : ''
  const classes = `form-control ${errClass}`
  const btnClass = showError ? 'secondary' : 'primary'

  return (
    <>
      <div className="form-group">
        <input
          {...input}
          className={classes}
          id={attrs.name}
          type={attrs.name}
          placeholder={attrs.placeholder}
          disabled={submitting}
          autoFocus={true}
        />
      </div>

      <div>
        <button
          type="submit"
          className={`btn btn-outline-${btnClass}`}
          disabled={submitting}
        >
          {attrs.btnText}
        </button>
      </div>

      {showError && <ErrorMessage message={error} />}
    </>
  )
}

FieldWithSubmit.propTypes = {
  attrs: PropTypes.shape({
    btnText: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
  }).isRequired,
  input: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    submitting: PropTypes.bool.isRequired,
    submitFailed: PropTypes.bool.isRequired,
  }).isRequired,
}

const Form = props => {
  const { action, handleSubmit, attrs } = props
  return (
    <form className={css.signInForm} onSubmit={handleSubmit(action)}>
      <div className="form-group">
        <label className="sr-only" htmlFor={attrs.name}>
          {attrs.placeholder}
        </label>

        <Field component={FieldWithSubmit} name={attrs.name} attrs={attrs} />
      </div>
    </form>
  )
}

Form.propTypes = {
  action: PropTypes.func.isRequired,
  attrs: PropTypes.shape({
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export class CommonFormCont extends PureComponent {
  componentDidMount() {
    setPageTitle('Password Reset')
  }

  componentWillUnmount() {
    setPageTitle('')
  }

  render() {
    return <Form {...this.props} />
  }
}

export const validate = (values, { attrs }) => {
  const errors = {}

  const name = attrs.name
  if (isBlank(values[name])) {
    errors[name] = attrs.errMsg
  }

  return errors
}

export const mapDispatch = (dispatch, { action }) => ({
  action: values => dispatch(action(values)),
})

const onSubmitFail = errors => {
  const keys = Object.keys(errors)
  document.getElementById(keys[0]).focus()
}

export default connect(
  null,
  mapDispatch
)(reduxForm({ onSubmitFail, validate })(CommonFormCont))
