import types from 'components/addresses/actions'
import typesApp from 'components'

export const initState = null

export default function suggestErrorReducer(state = initState, action) {
  switch (action.type) {
    case types.SUGGESTIONS_FETCH_ERROR: {
      return action.error
    }

    case typesApp.LOCATION_CHANGE:
    case types.SUGGESTIONS_CLEAR:
    case types.SUGGESTIONS_FETCH_SUCCESS:
    case types.VERIFY_FETCH_SUCCESS: {
      return initState
    }

    default: {
      return state
    }
  }
}
