import api from 'components/api'
import types from 'components/people/person/actions'
import history from 'components/history'
import { notifyErrors } from 'common/notifications/actions/add'

const personDelete = () => (dispatch, getState) => {
  const personId = getState().people.person.id

  return api
    .delete(`/people/${personId}`)
    .then(res => {
      history.push('/people')

      dispatch({
        type: types.PERSON_DELETE_SUCCESS,
        data: { result: personId },
      })
    })
    .catch(({ response }) => {
      notifyErrors(dispatch, response)
      dispatch({ type: types.PERSON_DELETE_FAILURE, status: response.status })
    })
}

export default personDelete
