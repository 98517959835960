import api from 'components/api'
import types from 'components/people/actions'
import { notifyErrors } from 'common/notifications/actions/add'
import { normalize } from 'normalizr'
import arrayOfPeopleSchema from 'components/people/actions/schema'

const peopleFetch = () => dispatch => {
  dispatch({ type: types.PEOPLE_FETCH_START })

  return api
    .get('/people.json')
    .then(res => {
      dispatch({
        type: types.PEOPLE_FETCH_SUCCESS,
        data: normalize(res.data, arrayOfPeopleSchema),
      })
    })
    .catch(({ response }) => {
      notifyErrors(dispatch, response)
      dispatch({ type: types.PEOPLE_FETCH_FAILURE, status: response.status })
    })
}
export default peopleFetch
