import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Untie from 'components/addresses/untie'
import Admin from 'components/addresses/address/admin'
import { addrTextSelector } from 'components/addresses/address/selected'
import css from 'components/addresses/address/styles'

export const AddressComp = ({ addrId, addrText, mutating, provided }) => (
  <li
    className={`list-group-item ${css.item} ${mutating ? css.mutating : ''}`}
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
  >
    <Untie addrId={addrId} />
    <Admin addrId={addrId} />
    {addrText}
  </li>
)

AddressComp.propTypes = {
  addrId: PropTypes.number.isRequired,
  addrText: PropTypes.string.isRequired,
  mutating: PropTypes.bool.isRequired,
  provided: PropTypes.shape({}).isRequired,
}

export const mapState = (state, props) => ({
  addrId: props.addrId,
  addrText: addrTextSelector(state, props),
  mutating: state.addresses.mutating,
})

export default connect(mapState)(AddressComp)
