import api from 'components/api'
import types from 'components/ties/actions'
import { notifyErrors } from 'common/notifications/actions/add'

const tieDestroyPersonAddress = addressId => (dispatch, getState) => {
  const personId = getState().people.person.id

  dispatch({
    type: types.TIES_DEL_PER_ADDR_START,
    addressId,
  })

  const url = `/ties/0?person_id=${personId}&address_id=${addressId}`

  return api
    .delete(url)
    .then(res =>
      dispatch({ type: types.TIES_DEL_PER_ADDR_SUCCESS, personId, addressId })
    )
    .catch(({ response }) => {
      notifyErrors(dispatch, response)
      dispatch({
        type: types.TIES_DEL_PER_ADDR_FAILURE,
        status: response.status,
      })
    })
}

export default tieDestroyPersonAddress
