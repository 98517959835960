import { fromJS } from 'immutable'
import types from 'common/comments/actions'

export const initState = fromJS({})

export default function commentsByIdReducer($$state = initState, action) {
  switch (action.type) {
    case types.COMMENT_CREATE_SUCCESS:
    case types.COMMENTS_FETCH_SUCCESS: {
      return $$state.merge(action.data.entities.comments)
    }

    default: {
      return $$state
    }
  }
}
