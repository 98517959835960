import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { dateDisplay } from 'components/listings/listing/utils'
import getListing from 'components/listings/listing/get'
import css from 'components/listings/listing/summary/row7/styles'

export const Row7Comp = ({ dt, kind, sub, yr }) =>
  kind === 0 ? (
    <div className={css.row}>
      <div>Year: {yr}</div>
      <div>{dateDisplay(dt)}</div>
    </div>
  ) : (
    <div className={`${css.row} ${css.rowLand}`}>
      Subdivision: {sub || 'None'}
    </div>
  )

Row7Comp.propTypes = {
  dt: PropTypes.number.isRequired,
  kind: PropTypes.number.isRequired,
  sub: PropTypes.string,
  yr: PropTypes.number,
}

Row7Comp.defaultProps = {
  sub: null,
  yr: null,
}

export const mapState = (state, { ml }) => {
  const listing = getListing(state, ml)
  return {
    dt: listing.get('dt'),
    kind: listing.get('ki'),
    sub: listing.get('sub'),
    yr: listing.get('yr'),
  }
}

export default connect(mapState)(Row7Comp)
