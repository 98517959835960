import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getFormValues } from 'redux-form'
import personCreate from 'components/people/person/actions/create'
import BtnConfirm from 'common/buttons/confirm'
import { formName } from 'components/people/search'

export const CreateBtn = ({ action, submitting }) => (
  <button
    type="button" // defaults to 'submit'
    onClick={action}
    className="btn btn-sm btn-outline-danger"
    disabled={submitting}
  >
    Create
  </button>
)

CreateBtn.propTypes = {
  action: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

export const CreateConfirmComp = ({
  handleSubmit,
  personCreate,
  submitting,
  values,
}) => {
  if (values && (values.firstName || values.lastName)) {
    return (
      <BtnConfirm cancelPosition="left">
        <CreateBtn
          action={handleSubmit(personCreate)}
          submitting={submitting}
        />
      </BtnConfirm>
    )
  }

  return null
}

CreateConfirmComp.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  personCreate: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
}

CreateConfirmComp.defaultProps = { values: {} }

export const mapState = state => ({ values: getFormValues(formName)(state) })

export default connect(
  mapState,
  { personCreate }
)(CreateConfirmComp)
