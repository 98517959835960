// Can't stop filtering at X (e.g., 30) since we have to filter all listings to display count.

// Need to memoize [listings] for each sort option.
// probably best to memoize all filters

// // Would this work with zip codes since 42701 would also be 4 & 42 & 427 & 4270 (even 270 & 701)?
// This would probably only work with bedrooms and bathrooms since they are limited values (e.g., 6 br max).
// const dictionary = {
//   br: { '3': [id, id, id], '4': [id, id, id] },
//   ba: { '2': [id, id, id], '3': [id, id, id] },
// }

import { createSelector } from 'reselect'
import { fromJS } from 'immutable'

const formValue = (formState, field) => {
  const form = formState.ListingsLand
  if (form && form.values) {
    let fieldValue = form.values[field]
    if (field === 'sort') {
      return fieldValue
    }

    if (fieldValue) {
      fieldValue = fieldValue.trim()

      const numFields = ['priceMin', 'priceMax', 'acresMin', 'acresMax']
      if (numFields.includes(field)) {
        return onlyDigits(fieldValue)
      }

      return fieldValue.toLowerCase()
    }
  }
}

const getSort = ({ form }) => formValue(form, 'sort')
const getPriceMin = ({ form }) => formValue(form, 'priceMin')
const getPriceMax = ({ form }) => formValue(form, 'priceMax')
const getAcresMin = ({ form }) => formValue(form, 'acresMin')
const getAcresMax = ({ form }) => formValue(form, 'acresMax')
const getStreet = ({ form }) => formValue(form, 'street')
const getCity = ({ form }) => formValue(form, 'city')
const getZip = ({ form }) => formValue(form, 'zip')
const getSubd = ({ form }) => formValue(form, 'subd')
const getCounty = ({ form }) => formValue(form, 'county')
const getMlsNum = ({ form }) => formValue(form, 'mlsNum')
const getAllIds = ({ listings }) => listings.$$allIds
const getById = ({ listings }) => listings.$$byId

const listingsToArray = (ids, listings) => ids.map(id => listings.get(id))
// createSelector puts listings in array just once (unless listings change)
const arrOfListings = createSelector(
  [getAllIds, getById],
  listingsToArray
)

const sortFn = sorter => {
  switch (sorter) {
    case 'acresMin': {
      return (a, b) => a.get('acre_apro') - b.get('acre_apro')
    }

    case 'acresMax': {
      return (a, b) => b.get('acre_apro') - a.get('acre_apro')
    }

    case 'dateNew': {
      return (a, b) => b.get('dt') - a.get('dt')
    }

    case 'dateOld': {
      return (a, b) => a.get('dt') - b.get('dt')
    }

    case 'priceMax': {
      return (a, b) => b.get('pr') - a.get('pr')
    }

    // priceMin
    default: {
      return (a, b) => {
        let diff = a.get('pr') - b.get('pr') // use MLS if same price
        return diff === 0 ? a.get('ml') - b.get('ml') : diff
      }
    }
  }
}

const sortedListings = (sortStr, listings) => {
  const fn = sortFn(sortStr)
  // spread because new arr needed or nothing changes (can't be same obj)
  return [...listings].sort(fn)
}

// createSelector here so sorting doesn't happen just because price, etc changed
const getSortedListings = createSelector(
  [getSort, arrOfListings],
  sortedListings
)

const getFiltered = (
  priceMin,
  priceMax,
  acresMin,
  acresMax,
  street,
  city,
  zip,
  subd,
  county,
  mlsNum,
  listings
) =>
  listings.reduce((arr, listing) => {
    // Most common filters first to avoid needlessly filtering all listings
    const passed =
      rangeCheck(priceMin, listing.get('pr'), priceMax) &&
      rangeCheck(acresMin, listing.get('acre_apro'), acresMax) &&
      stringCheck(listing.get('county'), county) &&
      stringCheck(listing.get('ad1'), street) &&
      stringCheck(listing.get('ci'), city) &&
      stringCheck(listing.get('zi'), zip) &&
      stringCheck(listing.get('sub'), subd) &&
      stringCheck(listing.get('ml'), mlsNum)

    if (passed) {
      return arr.push(listing)
    }

    return arr
  }, fromJS([]))

// createSelector prevents recalculating if other state changes (not form state)
export default createSelector(
  [
    getPriceMin,
    getPriceMax,
    getAcresMin,
    getAcresMax,
    getStreet,
    getCity,
    getZip,
    getSubd,
    getCounty,
    getMlsNum,
    getSortedListings,
  ],
  getFiltered
)

export const rangeCheck = (min, listingVal, max) => {
  const valueToCheck = min || max
  if (!valueToCheck) {
    return true // not checking this field, so all listings pass
  }

  listingVal = Number(listingVal)

  if (min && listingVal < min) {
    return false
  }

  // skip unless max is set
  if (max) {
    // filter if no min OR max is greater than min
    if (!min || max >= min) {
      if (listingVal > max) {
        return false
      }
    }
  }

  return true // proceed if haven't returned `false` yet
}

export const stringCheck = (listingVal, stringVal) => {
  if (!stringVal) {
    return true // not checking this field, so all listings pass
  }

  if (!listingVal) {
    return false // value from server might be `undefined` (less data to send)
  }

  listingVal = listingVal.toLowerCase().trim()
  return listingVal.indexOf(stringVal) !== -1
}

export const onlyDigits = val => {
  if (val) {
    const cleanVal = val.replace(/[^\d\.]/g, '')
    return Number(cleanVal)
  }
}
