import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Link } from 'react-router-dom'
import css from 'components/bah/row/styles'

const Row = ({ bah, rank }) => {
  bah = bah.toJS()

  const rankRow = (
    <td>
      <Link to={`/bah-rates/${bah.rank}-Fort-Knox-Kentucky`}>{bah.rank}</Link>
    </td>
  )

  var rateRow = kind => {
    // avoid undefined
    let rate = bah[kind]
    if (rate) {
      // insert commas
      rate = rate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    let result = <td>${rate}</td>

    let change = bah[`${kind}_change`]
    if (bah.year > 1998) {
      // oldest rate doesn't have "change"
      // &nbsp; prevents 'change' from being broken onto a
      // new line (other cells become smaller to make space)
      result = (
        <td>
          ${rate}
          &nbsp;
          <span className={css.change}>({change})</span>
        </td>
      )
    }

    return result
  }

  return (
    <tr>
      <td>{bah.year}</td>
      {!rank && rankRow}
      {rateRow('with')}
      {rateRow('without')}
    </tr>
  )
}

Row.propTypes = {
  bah: ImmutablePropTypes.contains({
    rank: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired,
    with: PropTypes.number.isRequired,
    with_change: PropTypes.string.isRequired,
    without: PropTypes.number.isRequired,
    without_change: PropTypes.string.isRequired,
  }).isRequired,
  rank: PropTypes.string,
}

Row.defaultProps = { rank: null }

export default Row
