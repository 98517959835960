// inputs wrapped with label don't need <label for='x'>
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'
import { withCommas } from 'components/listings/listing/utils'
import { MdChevronLeft, MdExpandMore } from 'react-icons/md'
import css from 'components/taxes/county/rates/row/styles'

const rateCalc = (rate, value) => rate * (value / 10000)

const toMoney = (rate, value) =>
  withCommas(
    rateCalc(rate, value)
      .toFixed(2)
      .replace('.00', '')
  )

const isGeneralSchool = (countyName, tax) => {
  const isSchool = tax.kind.toLowerCase() === 'school'
  const name = `GENERAL ${countyName} COUNTY`.toLowerCase()
  return isSchool && name === tax.name.toLowerCase()
}

const RowComp = ({ countyId, countyName, tax, value, years }) => {
  const [expanded, setExpanded] = useState(false)
  const taxFirst = tax[0]

  const props = {
    type: 'checkbox',
    className: css.check,
    component: 'input',
    value: `v${taxFirst.code}`,
    name: `county-${countyId}.${taxFirst.kind.toLowerCase()}`,
  }

  // This tax may not be used now (only historical)
  if (String(taxFirst.year) !== years[0]) {
    props.disabled = true
  }

  const isState = taxFirst.kind.toLowerCase() === 'state'
  const isCounty = taxFirst.kind.toLowerCase() === 'county'
  const isSchool = taxFirst.kind.toLowerCase() === 'school'
  const isCity = taxFirst.kind.toLowerCase() === 'city'
  const isSpecial = taxFirst.kind.toLowerCase() === 'special'

  if (isState || isCounty) {
    props.disabled = true
    props.checked = true
    props.initialValue = `v${taxFirst.code}`

    const co = `county-${countyId}`
    props.name = `${co}.${taxFirst.kind.toLowerCase()}-${taxFirst.code}`
  }

  if (isSchool || isCity) {
    props.type = 'radio'

    if (isGeneralSchool(countyName, taxFirst)) {
      props.initialValue = `v${taxFirst.code}`
    }
  }

  const isNoCity = taxFirst.name === 'NONE'
  if (isCity && isNoCity) {
    const val = 'no city selected'
    props.initialValue = val
    props.value = val
  }

  const text = tax => {
    let kind = tax.kind
    let name = tax.name

    if (isSchool) {
      kind = kind.replace('SCHOOL', 'SCH')
      name = name.replace('GENERAL ', '').replace('INDEPENDENT', 'IND.')
    }

    if (isSpecial) {
      kind = kind.replace('SPECIAL', 'MISC')
      name = name.replace('DISTRICT', 'DIST.')
    }

    return `${kind}: ${name}`
  }

  const toggle = () => {
    setExpanded(!expanded)
  }

  const Inner = ({ tax, value }) => (
    <div className={css.expandedInner}>
      <div className={css.sumRow}>
        {tax.map((byYear, i) => (
          <div key={byYear.year} className={css.sumCell}>
            <div>{byYear.year}</div>

            <div className={css.value}>${toMoney(byYear.rate, value)}</div>

            <div className={css.perMonth}>
              $
              {withCommas(
                (rateCalc(byYear.rate, value) / 12)
                  .toFixed(2)
                  .replace('.00', '')
              )}
              /mo
            </div>
          </div>
        ))}
      </div>
    </div>
  )

  // Inner.propTypes = {
  //   tax: x,
  //   value: x,
  // }

  const Current = ({ tax, latestYear }) => {
    let amount = 0
    if (String(tax.year) === String(latestYear)) {
      amount = toMoney(tax.rate, value)
    }
    return `$${amount}`
  }

  const klass = expanded ? css.expanded : ''

  return (
    <li className={`list-group-item ${klass} ${css.item}`}>
      <div className={css.header}>
        <label>
          <Field {...props} />
          {text(taxFirst)}
        </label>

        <div className={css.btnWrap}>
          <Current tax={taxFirst} latestYear={years[0]} />

          <button type="button" className={css.btn} onClick={toggle}>
            {expanded ? <MdExpandMore /> : <MdChevronLeft />}
          </button>
        </div>
      </div>

      {expanded && <Inner tax={tax} value={value} />}
    </li>
  )
}

RowComp.propTypes = {
  countyName: PropTypes.string.isRequired,
  tax: PropTypes.arrayOf(
    // newest to oldest year
    PropTypes.shape({
      year: PropTypes.number.isRequired,
      county_id: PropTypes.number,
      kind: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      rate: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

export default RowComp
