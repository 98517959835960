import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import getAutocomplete from 'components/addresses/actions/getAutocomplete'
import getVerify from 'components/addresses/actions/getVerify'
import suggestSearchText from 'components/addresses/actions/suggestionSearchText'
import AddressNewComponent from 'components/addresses/new/component'
import madeSuggestionAction from 'components/addresses/actions/madeSuggestion'

class AddressNewContainer extends PureComponent {
  static propTypes = {
    getAutocomplete: PropTypes.func.isRequired,
    getVerify: PropTypes.func.isRequired,
    madeSuggestion: PropTypes.bool.isRequired,
    madeSuggestionAction: PropTypes.func.isRequired,
    suggestions: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    suggestionSearchText: PropTypes.string.isRequired,
    suggestSearchText: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.debouncedSuggestions = debounce(value => {
      props.getAutocomplete(value)
    }, 275)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this)
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(
      this
    )
  }

  // required as of v6.0.0
  onSuggestionsFetchRequested({ value, reason }) { }

  // 'enter' or 'click' a suggestion.
  // onChange doesn't handle 'enter', and we need access to suggestion
  onSuggestionSelected(event, { suggestion }) {
    const { getVerify, madeSuggestionAction } = this.props
    getVerify(suggestion)

    // prevents handleSubmit from firing
    madeSuggestionAction(true)
  }

  // when suggestion selected, this determines the
  // value of the input (choose the correct property of the object)
  getSuggestionValue(suggestion) {
    return suggestion
  }

  handleChange(event, { newValue, method }) {
    // 'up' and 'down' just update input text
    if (['down', 'up', 'type'].indexOf(method) !== -1) {
      const { suggestSearchText } = this.props
      suggestSearchText(newValue)

      // only 'type' fetches results
      if (method === 'type') {
        this.debouncedSuggestions(newValue)
      }
    }
  }

  handleSubmit(event) {
    event.preventDefault()

    // prevent submitting more than once at a time
    // isSubmitting state?

    const { getVerify, madeSuggestion, suggestionSearchText } = this.props

    const text = suggestionSearchText
    const textIsPresent = text.length > 0
    const notSuggestion = !madeSuggestion

    if (textIsPresent && notSuggestion) {
      getVerify(text)
    }
  }

  inputProps() {
    const { suggestionSearchText } = this.props

    return {
      className: 'form-control',
      placeholder: 'Address',
      autoComplete: 'off',
      autoFocus: true,
      value: suggestionSearchText,
      onChange: this.handleChange.bind(this),
    }
  }

  renderSuggestion(suggestion) {
    return <span>{suggestion}</span>
  }

  render() {
    const { suggestions } = this.props

    return (
      <AddressNewComponent
        onSubmit={this.handleSubmit}
        suggestions={suggestions}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        onSuggestionSelected={this.onSuggestionSelected}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        inputProps={this.inputProps()}
      />
    )
  }
}

const mapState = state => ({
  suggestionSearchText: state.addresses.suggestionSearchText,
  suggestions: state.addresses.$$suggestions.toJS(),
  madeSuggestion: state.addresses.madeSuggestion,
})

export default connect(mapState, {
  suggestSearchText,
  madeSuggestionAction,
  getAutocomplete,
  getVerify,
})(AddressNewContainer)
