import React, { Component } from 'react'
import { connect } from 'react-redux'
import transit from 'transit-immutable-js'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import ErrorBoundary from 'common/errorBoundary'
import onClient from 'components/utils/onClient'
import typesApp from 'components'
import AddressesUntied from 'components/addresses/pages/untied'
import Bah from 'components/bah'
import ErrorNotFound from 'components/errors/404'
import LoadingPage from 'common/loading/page'
import MarketingHeader from 'common/marketingHeader'
import Guide from 'components/guides'
import ListingIndex from 'components/listings'
import ListingShow from 'components/listings/listing'
import MembersIndex from 'components/members'
import NavAdmin from 'common/navAdmin'
import NavMain from 'common/navMain'
import Notifications from 'common/notifications'
import PeopleIndex from 'components/people'
import PeopleShow from 'components/people/person'
import PrivateRoute from 'components/auth/privateRoute'
import Taxes from 'components/taxes'
import Seller from 'components/contact/seller'
import SignIn from 'components/auth'
import Reset from 'components/auth/reset'
import Forgot from 'components/auth/forgot'
import VerifyEmail from 'components/details/detail/verify-email'
import css from 'common/layout/styles'

export const StoreWrapComp = ({ children, onClient, state }) => {
  if (onClient) {
    return <div>{children}</div>
  }

  return <div data-store={transit.toJSON(state)}>{children}</div>
}

StoreWrapComp.propTypes = {
  children: PropTypes.node.isRequired,
  onClient: PropTypes.bool.isRequired,
  state: PropTypes.shape({}).isRequired,
}

export const mapStateStoreWrap = state => ({ onClient, state })
const StoreWrapConn = connect(mapStateStoreWrap)(StoreWrapComp)

const RtWrap = props => {
  const { path, priv } = props
  return (
    <ErrorBoundary key={path}>
      {priv ? <PrivateRoute {...props} /> : <Route {...props} />}
    </ErrorBoundary>
  )
}

RtWrap.propTypes = {
  path: PropTypes.string,
  priv: PropTypes.bool,
}

RtWrap.defaultProps = {
  path: 'catch-all',
  priv: false,
}

export const LayoutComp = ({ isMember }) => (
  <ErrorBoundary>
    <StoreWrapConn>
      <Notifications />
      <LoadingPage />
      {isMember && <NavAdmin />}
      <MarketingHeader />
      <NavMain />

      <ErrorBoundary>
        <div className={css.mainContent}>
          <Switch>
            {isMember && (
              <RtWrap
                priv={true}
                path="/addresses/untied"
                component={AddressesUntied}
              />
            )}
            <RtWrap path="/bah-rates" exact={true} component={Bah} />
            <RtWrap path="/bah-rates/:id" component={Bah} />
            <RtWrap path="/sell-your-house" component={Seller} />
            <RtWrap
              path="/contact-hardinhomes-fort-knox-agents"
              component={MembersIndex}
            />
            {isMember && <RtWrap path="/guide" component={Guide} />}
            <RtWrap
              path="/land-for-sale"
              exact={true}
              component={ListingIndex}
            />
            <RtWrap path="/land-for-sale/:id" component={ListingShow} />
            <RtWrap
              path="/louisville-homes-for-sale"
              exact={true}
              component={ListingIndex}
            />
            <RtWrap
              path="/louisville-homes-for-sale/:id"
              component={ListingShow}
            />
            {isMember && (
              <RtWrap
                priv={true}
                path="/people"
                exact={true}
                component={PeopleIndex}
              />
            )}
            <RtWrap path="/kentucky-property-taxes" component={Taxes} />
            <RtWrap
              priv={true}
              path="/me"
              exact={true}
              component={PeopleShow}
            />
            <RtWrap priv={true} path="/people/:id" component={PeopleShow} />
            <RtWrap path="/forgot" component={Forgot} />
            <RtWrap path="/me/:token/:id" component={Reset} />
            <RtWrap path="/verify-email/:token/:id" component={VerifyEmail} />
            <RtWrap path="/sign-in" component={SignIn} />
            <Route component={ErrorNotFound} />
          </Switch>
        </div>
      </ErrorBoundary>
    </StoreWrapConn>
  </ErrorBoundary>
)

LayoutComp.propTypes = { isMember: PropTypes.bool.isRequired }
export const mapStateLayoutComp = ({ auth }) => ({ isMember: !!auth.isMember })
const LayoutCompConn = connect(mapStateLayoutComp)(LayoutComp)

export class LayoutCont extends Component {
  static propTypes = {
    authId: PropTypes.number,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageChanged: PropTypes.func.isRequired,
  }

  static defaultProps = { authId: null }

  componentDidUpdate(prevProps) {
    const { authId, location, pageChanged } = this.props
    const prevPage = prevProps.location.pathname
    const currPage = location.pathname
    if (currPage !== prevPage) {
      pageChanged(authId)
    }
  }

  render() {
    // `LayoutCompConn` needs different props to rerender so pass location
    const {
      location: { pathname, search },
    } = this.props
    return <LayoutCompConn whatever={pathname + search} />
  }
}

const mapState = ({ auth }) => ({ authId: auth.id })

const mapDispatch = (dispatch, { location }) => ({
  pageChanged: authId => {
    dispatch({
      type: typesApp.LOCATION_CHANGE,
      url: location.pathname,
      authId,
    })
  },
})

export default connect(mapState, mapDispatch)(LayoutCont)
