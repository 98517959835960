import detailFixture from 'components/details/fixture'
import addressFixture from 'components/addresses/fixture'
import tokenFixture from 'components/people/person/admin/tokens/tokenFixture'

export default {
  id: 720,
  first_name: 'Frank',
  last_name: 'Smith',
  member_id: '23',
  kind: '1',
  created_at: '2016-05-26T19:48:14.117Z',
  updated_at: '2016-05-26T19:48:14.117Z',
  data: {
    created_at_in_days: 333,
    date_history: ['date 2', 'date 1'],
    days_active_count: 33,
    ip_history: ['IP 2', 'IP 1'],
    mail_to_id: '55', // string value
  },
  tokens: { key: tokenFixture },
  addresses: [
    addressFixture,
    {
      ...addressFixture,
      id: addressFixture.id + 1,
      address: addressFixture.address + '11111',
    },
  ],
  details: [detailFixture],
}
