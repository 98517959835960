import api from 'components/api'
import { notifyErrors } from 'common/notifications/actions/add'
import types from 'components/addresses/address/addressee/actions'

const addresseeUpdate = (addrId, data) => dispatch => {
  dispatch({ type: types.ADDRESSEE_UPDATE_START })

  const url = `/addresses/${addrId}.json`
  return api
    .patch(url, data)
    .then(res => dispatch({ type: types.ADDRESSEE_UPDATE_SUCCESS }))
    .catch(({ response }) => {
      notifyErrors(dispatch, response)
      dispatch({
        type: types.ADDRESSEE_UPDATE_FAILURE,
        status: response.status,
      })
    })
}

export default addresseeUpdate
