import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import history from 'components/history'
import { resetPw } from 'components/auth/actions'
import CommonForm from 'components/auth/commonForm'

export class ResetCont extends PureComponent {
  static propTypes = { redirect: PropTypes.bool.isRequired }

  componentDidMount() {
    const { redirect } = this.props
    if (redirect) {
      history.push('/forgot')
    }
  }

  render() {
    return (
      <CommonForm
        action={resetPw}
        form="Reset"
        attrs={{
          name: 'password',
          placeholder: 'Password',
          btnText: 'Save Password',
          errMsg: 'Enter a password',
        }}
      />
    )
  }
}

export const mapState = ({ auth }) => ({
  redirect: !!(auth.pwReset && auth.pwReset.error),
})

export default connect(mapState)(ResetCont)
