import getPerson from 'components/people/person/get'
import isSelf from 'components/people/person/utils/isSelf'

// need to pass entire state to `getPerson` selector (can't destructure)
const memberOwner = (state, subjectId) => {
  if (state.auth.isAdmin) {
    return true
  }

  const doerId = state.auth.id
  const doer = getPerson(state, doerId)
  const doerRoles = doer.get('roles')
  if (!doerRoles) {
    return false
  }
  const doerRank = rank(doerRoles)

  const subject = getPerson(state, subjectId)
  const subjectRoles = subject.get('roles')
  const subjectRank = rank(subjectRoles)

  const outRanks = doerRank > subjectRank
  if (outRanks) {
    return true
  }

  const underRanks = subjectRank > doerRank
  if (underRanks) {
    return false
  }

  // when it's the member's person, but not the member herself
  const self = isSelf(doerId, subjectId)
  if (!self && state.auth.isMember) {
    const subjectMemberId = subject.get('member_id')
    if (!subjectMemberId) {
      return true // "No Member"
    }

    const doerMemberId = String(doer.get('member_id'))
    const personOfMember = doerMemberId === String(subjectMemberId)
    return personOfMember
  }

  return false // default
}

const rank = roles => {
  if (!roles) {
    return 0
  }

  if (roles.includes('admin')) {
    return 2
  }

  if (roles.includes('manager')) {
    return 1
  }

  return 0 // anyone else (even :member)
}

export default memberOwner
