import { createSelector } from 'reselect'

const $$byId = ({ guides }) => guides.$$byId
const $$allIds = ({ guides }) => guides.$$allIds

export default createSelector(
  $$byId,
  $$allIds,
  ($$byId, $$allIds) => $$allIds.map(id => $$byId.get(String(id)))
)
