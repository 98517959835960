import { combineReducers } from 'redux'
import { normalize } from 'normalizr'
import * as schema from 'components/guides/actions/schema'
import $$byId from 'components/guides/reducers/byId'
import $$allIds from 'components/guides/reducers/allIds'
import expanded from 'components/guides/reducers/expanded'

export const process = data => {
  return normalize(data, schema.arrayOfGuides)
}

export default combineReducers({
  $$byId,
  $$allIds,
  expanded,
})
