import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import action from 'components/ties/actions/destroyPersonAddress'
import BtnDestroyConfirm from 'common/buttons/destroyConfirm'
import css from 'components/addresses/untie/styles'

export const UntieComp = ({ action, mutating }) => {
  if (mutating) {
    return <BtnDestroyConfirm action={action} cancelPosition="left" css={css} />
  }

  return null
}

UntieComp.propTypes = {
  action: PropTypes.func.isRequired,
  mutating: PropTypes.bool.isRequired,
}

export const mapState = ({ addresses }) => ({ mutating: addresses.mutating })

export const mapDispatch = (dispatch, { addrId }) => ({
  action: () => dispatch(action(addrId)),
})

export default connect(
  mapState,
  mapDispatch
)(UntieComp)
