import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import BtnCancel from 'common/buttons/cancel'

export default class BtnConfirm extends PureComponent {
  static propTypes = {
    cancelPosition: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = { confirming: false }
    this.cancel = () => this.setState({ confirming: false })
    this.check = () => this.setState({ confirming: true })

    const { cancelPosition, children } = props

    this.childProps = {
      ...children.props,
      action: this.check,
    }
    this.finalAction = children.props.action

    this.left = cancelPosition === 'left'
    this.right = cancelPosition === 'right'
  }

  render() {
    const {
      childProps,
      left,
      right,
      state: { confirming },
      props: { children },
    } = this

    childProps.action = confirming ? this.finalAction : this.check

    const btnCancel = <BtnCancel action={this.cancel} />

    return (
      <span>
        {left && confirming && btnCancel}
        {left && confirming && ' '}
        {React.cloneElement(children, { ...this.childProps })}
        {right && confirming && ' '}
        {right && confirming && btnCancel}
      </span>
    )
  }
}
