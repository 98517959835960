import { createSelector } from 'reselect'

const id = (_, id) => String(id)
const $$byId = ({ details }) => details.$$byId
const detail = (id, $$byId) => $$byId.get(id)
const getDetail = createSelector(
  id,
  $$byId,
  detail
)

export default getDetail
