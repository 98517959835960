import { combineReducers } from 'redux'
import $$allIds from 'components/schools/reducers/allIds'
import $$byId from 'components/schools/reducers/byId'
import $$byMls from 'components/schools/reducers/byMls'

export default combineReducers({
  $$allIds,
  $$byId,
  $$byMls,
})
