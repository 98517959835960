import React from 'react'
import Modal from 'common/modal'
import { modalOpen } from 'common/modal/actions'
import {
  FaRegArrowAltCircleDown,
  FaRegArrowAltCircleUp,
  FaUndoAlt,
} from 'react-icons/fa'
import { ImFire } from 'react-icons/im'
import css from 'components/listings/listing/summary/row1/legendModal/styles'

export const modalName = 'legend'

export const open = event => dispatch => {
  event.preventDefault()
  dispatch(modalOpen(modalName))
}

const LegendModalComp = () => (
  <Modal title="Legend" name={modalName} minWidth="225px">
    <div className={css.modal}>
      <div>
        <ImFire /> New Listing
      </div>
      <div>
        <FaRegArrowAltCircleUp /> Price Increase
      </div>
      <div>
        <FaRegArrowAltCircleDown /> Price Decrease
      </div>
      <div>
        <FaUndoAlt /> Back on the Market
      </div>
    </div>
  </Modal>
)

export default LegendModalComp
