import typesAddressee from 'components/addresses/address/addressee/actions'
import typesPerson from 'components/people/person/actions'
import types from 'common/modal/actions'

export const initState = {
  name: null,
  props: {},
}

export default function modal(state = initState, action) {
  switch (action.type) {
    case types.MODAL_OPEN: {
      return {
        name: action.name,
        props: action.props,
      }
    }

    case typesPerson.PERSON_MAIL_TO_ID_SUCCESS: {
      if (action.ids.length > 1) {
        // has to match the correct modal's name
        return { name: 'addressee' }
      }

      return state
    }

    case typesAddressee.ADDRESSEE_UPDATE_SUCCESS:
    case typesAddressee.ADDRESSEE_FETCH_FAILURE:
    case types.MODAL_CLOSE: {
      return initState
    }

    default: {
      return state
    }
  }
}
