import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import LoadingBar from 'common/loading/bar'
import kindIdEdit from 'components/people/person/actions/kindId'
import getPerson from 'components/people/person/get'
import optionsKind from 'components/people/person/admin/kindEdit/options'
import css from 'components/people/person/admin/kindEdit/styles'

export const KindEditComp = ({ kindId, kindIdEdit, loading }) => {
  // do not mutate `options` object
  let options = optionsKind

  let styleClass = 'form-control'
  const kindNotYetSet = kindId === '0'
  if (kindNotYetSet) {
    styleClass += ` ${css.unset}`
    options = [<option key={0} value={0} />, ...options]
  }

  return (
    <>
      <LoadingBar loading={loading} />

      <form className="form-group form-inline">
        <label htmlFor="kindId">
          <select
            id="kindId"
            className={styleClass}
            onChange={kindIdEdit}
            value={kindId}
          >
            {options}
          </select>
          &nbsp; Kind
        </label>
      </form>
    </>
  )
}

KindEditComp.propTypes = {
  kindId: PropTypes.string.isRequired,
  kindIdEdit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export const mapState = state => {
  const { id } = state.people.person
  const person = getPerson(state, id)

  return {
    kindId: String(person.get('kind')),
    loading: state.people.loading.kind,
  }
}

export default connect(
  mapState,
  { kindIdEdit }
)(KindEditComp)
