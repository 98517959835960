import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FaRegEdit, FaTimes } from 'react-icons/fa'
import types from 'components/people/person/actions'
import css from 'components/people/person/namePassword/buttons/button/styles'

export const ButtonComp = ({ action, editing, title }) => (
  <button
    className={`btn btn-sm btn-outline-secondary ${css.style}`}
    type="button"
    onClick={action}
  >
    {editing ? <FaTimes /> : <FaRegEdit />}
    &nbsp;
    {title}
  </button>
)

ButtonComp.propTypes = {
  action: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
}

export const mapState = ({ people }, { title }) => ({
  editing: people.person.editing === title.toLowerCase(),
})

export const mapDispatch = (dispatch, { title }) => {
  let type = types.PERSON_NAME_EDIT_TOGGLE
  if (title === 'Password') {
    type = types.PERSON_PASSWORD_EDIT_TOGGLE
  }
  return { action: () => dispatch({ type }) }
}

export default connect(
  mapState,
  mapDispatch
)(ButtonComp)
