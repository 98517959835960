import { OrderedSet } from 'immutable'
import typesApp from 'components'
import typesListings from 'components/listings/actions'
import typesListing from 'components/listings/listing/actions'
import { process } from 'app/javascript/components/listings/reducers'

export const initState = OrderedSet([])

const listingsAllIdsReducer = ($$state = initState, action) => {
  switch (action.type) {
    case typesApp.HYDRATE: {
      if (action.location.includes('/land-for-sale')) {
        return OrderedSet(process(action).result)
      }

      return $$state
    }

    case typesListings.LISTINGS_FETCH_SUCCESS: {
      return OrderedSet(action.data.result)
    }

    case typesListing.LISTING_FETCH_SUCCESS: {
      return $$state.add(action.data.result)
    }

    default: {
      return $$state
    }
  }
}

export default listingsAllIdsReducer
