import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import css from 'common/pagination/styles'

// PureComponent necessary for `prevDefault()` + `navTo()` in the `constructor`
class PrevNext extends PureComponent {
  constructor(props) {
    super(props)

    this.prevDefault = this.prevDefault.bind(this)
    this.navTo = this.navTo.bind(this)
  }

  prevDefault = e => {
    const { disabled } = this.props
    if (disabled) {
      e.preventDefault()
    }
  }

  navTo = () => {
    const { pathname, val } = this.props
    return {
      pathname,
      search: `?page=${val}`,
    }
  }

  render() {
    const { disabled, rel, content } = this.props
    let classes = `btn btn-outline-secondary ${css.prevNext}`
    if (disabled) {
      classes += ` ${css.prevNextDisabled}`
    }

    return (
      <Link
        disabled={disabled}
        rel={rel}
        className={classes}
        onClick={this.prevDefault}
        to={this.navTo()}
      >
        <span aria-hidden="true">{content}</span>
      </Link>
    )
  }
}

PrevNext.propTypes = {
  content: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
  rel: PropTypes.string.isRequired,
  val: PropTypes.number.isRequired,
}

export default PrevNext
