import getPerson from 'components/people/person/get'
import getDetails from 'components/details/selected'
import { addrTextSelector } from 'components/addresses/address/selected'
import css from 'components/contact/utils/styles'

export const validate = values => {
  const { email, phone } = values

  const errors = {}
  if (!validEmail(email) && !validPhone(phone)) {
    errors.method = 'Required'
  }

  return errors
}

const validEmail = email => email && email.match(/\S+@\S+\.\S+/)

const validPhone = phone => {
  if (phone) {
    const lengthGt5 = phone.length > 5
    const hasDigits = phone.match(/\d/)
    if (hasDigits) {
      const hasGt2Digits = phone.match(/\d/g).length > 2 // min 3
      return lengthGt5 && hasGt2Digits
    }
  }
}

export const contactClass = (touched, errors) => {
  const base = ''
  const entered = touched['email'] || touched['phone']
  if (entered && errors['method']) {
    return `${base} ${css.error}`
  }
  return base
}

export const mapState = state => {
  const { id } = state.auth
  const contactInfo = {
    id,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    address: '',
  }

  if (id) {
    let $$person
    $$person = getPerson(state, id)

    if ($$person) {
      contactInfo.first_name = $$person.get('first_name')
      contactInfo.last_name = $$person.get('last_name')

      // fetch completed?
      if ($$person.get('details')) {
        let details
        const seeAll = false // don't need it all
        details = getDetails(state, $$person, seeAll)

        contactInfo.email = details.emails[0].info_formatted
        const phone = details.phones[0]
        if (phone) {
          contactInfo.phone = phone.info_formatted
        }

        const addrId = $$person.get('addresses').toJS()[0]
        if (addrId) {
          contactInfo.address = addrTextSelector(state, { addrId })
        }
      }
    }
  }

  return { contactInfo }
}
