import { fromJS } from 'immutable'
import typesApp from 'components'
import typesPerson from 'components/people/person/actions'
import types from 'components/addresses/actions'
import typesAuth from 'components/auth/actions'
import typesAddressee from 'components/addresses/address/addressee/actions'

export const initState = fromJS({})

const addrByIdReducer = ($$state = initState, action) => {
  switch (action.type) {
    case typesApp.HYDRATE:
    case typesAuth.SIGN_IN_SUCCESS:
    case typesPerson.PERSON_FETCH_SUCCESS:
    case types.ADDRESS_UNTIED_FETCH_SUCCESS:
    case types.VERIFY_FETCH_SUCCESS: {
      const entities = action.data.entities
      if (entities) {
        return $$state.merge(entities.addresses)
      }

      return $$state
    }

    case typesAddressee.ADDRESSEE_FETCH_SUCCESS: {
      const updated = { [action.address.id]: action.address }
      return $$state.merge(updated)
    }

    default: {
      return $$state
    }
  }
}

export default addrByIdReducer
