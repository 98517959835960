import React from 'react'
import PropTypes from 'prop-types'
import { notGlar, withCommas } from 'components/listings/listing/utils'
import css from 'components/listings/listing/glar/styles'

export const Lot = ({ listing }) => {
  if (notGlar(listing)) {
    return null
  }

  const acre = listing.get('acreInfo').toJS()

  return (
    <table className={`table ${css.wrapper}`}>
      <tbody>
        <tr className={css.attn}>
          <td colSpan="3" style={{ textAlign: 'center' }}>
            Land Summary
          </td>
        </tr>
        <tr>
          <th>Acreage</th>
          <td>{withCommas(listing.get('acre_apro'))}</td>
        </tr>
        <tr>
          <th>Lot SqFt</th>
          <td>{withCommas(listing.get('lotSq'))}</td>
        </tr>
        <tr>
          <th>Source</th>
          <td>{listing.get('acreSrc')}</td>
        </tr>
        <tr>
          <th>Dimensions</th>
          <td>{listing.get('lot')}</td>
        </tr>
        <tr>
          <td colSpan="2" style={{ textAlign: 'left' }}>
            Pasture({acre.pas || ' '}) Tillable({acre.til || ' '}) Timber(
            {acre.tim || ' '})
          </td>
        </tr>
        <tr>
          <th>Lake / Pond</th>
          <td>{acre.pon || 0}</td>
        </tr>
        <tr>
          <th>Lot / Block</th>
          <td>{acre.lotBlk}</td>
        </tr>
        <tr>
          <th>Sub-Lot</th>
          <td>{acre.subLot}</td>
        </tr>
        <tr>
          <th>Description</th>
          <td>{listing.get('lotDesc')}</td>
        </tr>
      </tbody>
    </table>
  )
}
Lot.propTypes = { listing: PropTypes.shape() }
Lot.defaultProps = { listing: undefined }

export default Lot
