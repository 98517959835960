import history from 'components/history'
import onClient from 'components/utils/onClient'
import { transitionTime } from 'common/notifications'
import typesAuth from 'components/auth/actions'
import types from 'common/notifications/actions'

export const notifyErrors = (dispatch, errorRes, custom = {}) => {
  let data = { type: 'error', timeout: 5000 }

  // `return` so no "fall through" for switch statement
  switch (errorRes.status) {
    case 304: {
      data.text = 'Nothing changed'
      break
    }

    case 401: {
      dispatch({ type: typesAuth.SIGN_OUT_SUCCESS })
      _pushToSignInPage()

      delete data.timeout // persists
      data.text = 'Please Sign In'
      break
    }

    case 403: {
      data.text = 'Not allowed'
      break
    }

    case 422: {
      data.text = 'Invalid'
      break
    }

    default: {
      const { status } = errorRes
      const statusText = errorRes.statusText || '' // don't show 'undefined'
      data.text = `${statusText} (${status}). Try refreshing.`.trim()
    }
  }

  return notifyAdd(dispatch, { ...data, ...custom })
}

export const _pushToSignInPage = () => {
  if (onClient) {
    history.push('/sign-in')
  }
}

const notifyAdd = (dispatch, data) => {
  if (onClient) {
    data.id = Date.now()
    const notify = () => dispatch({ type: types.NOTIFICATIONS_ADD, data })

    // setTimeout allows previous notification to disappear.
    // Allows LOCATION_CHANGE to happen first so it won't kill related notification
    setTimeout(notify, transitionTime) // this doesn't remove the notification
  }
}

export default notifyAdd
