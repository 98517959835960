import { fromJS } from 'immutable'
import typesApp from 'components'

export const initState = fromJS({})

const agentsByIdReducer = ($$state = initState, action) => {
  switch (action.type) {
    case typesApp.HYDRATE: {
      // skip this when location === /agents
      const entities = action.members.entities
      if (entities && entities.agents) {
        return $$state.merge(entities.agents)
      }

      return $$state
    }

    default:
      return $$state
  }
}

export default agentsByIdReducer
