import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import getListing from 'components/listings/listing/get'
import Tours from 'components/listings/listing/tours'
import Company from 'components/listings/listing/company'

export const LandComp = ({ listing }) => {
  const row = (label, field, alt = '') => {
    const value = listing.get(field)

    return (
      <tr>
        <th>{label}</th>
        <td>{value || alt}</td>
      </tr>
    )
  }

  return (
    <tbody>
      {row('REO (foreclosure)', 'reo', 'No') /* no is nil */}
      {row('PVA', 'pva')}
      {row('Deed Book/Page', 'deed')}
      <Tours ml={listing.get('ml')} />
      {row('Water', 'h2o', 'None')}
      {row('Sewer', 'sew', 'None')}
      {row('Lot Size', 'lot') /* not required */}
      {row('Electric', 'elec')}
      {row('Fence', 'fenc', 'None')}
      {row('Location Features', 'loc_feat', 'None')}
      {row('Other structures', 'out_bldg', 'None')}
      {row('Possession', 'poss') /* not required */}
      {row('Road Frontage', 'road_fr') /* not required */}
      {row('Zoning', 'zone') /* not required */}
      {row('Lot Dimensions', 'lot_dim')}
      {row('Acreage', 'acre_apro')}
      {row('Acreage Source', 'acre_src')}
      {row('Wooded Acres', 'acre_wood') /* not required */}
      {row('Pasture Acres', 'acre_pastu') /* not required */}
      {row('Tillable Acres', 'acre_till') /* not required */}
      <Company co={listing.get('co')} />
      {row('Barns', 'barns') /* NUMBER. not required */}
      {row('Ponds', 'ponds') /* NUMBER. not required */}
      {row('Topography', 'topo')}
      {row('Water Type', 'watf')}
      {row('Water Location', 'lake_loc')}
      {row('Water Features', 'lakef')}
      {row('Road Surface', 'road_surf')}
      {row('HOA Fee Includes', 'ass')}
      {row('Easements', 'ease')}
      {row('Docs', 'docs')}
    </tbody>
  )
}

LandComp.propTypes = { listing: PropTypes.shape().isRequired }

export const mapState = (state, { ml }) => ({ listing: getListing(state, ml) })

export default connect(mapState)(LandComp)
