import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Rates from 'components/taxes/county/rates'

const hardinOrMeade = name => ['hardin', 'meade'].includes(name.toLowerCase())
const primaryMarker = name => (hardinOrMeade(name) ? `*${name}` : name)

const CountyComp = ({ county, value }) => {
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    if (county.name.toLowerCase() === 'hardin') {
      setExpanded(true)
    }
  }, []) // [] <- runs once (like componentDidMount)

  const onClick = e => {
    e.preventDefault()
    setExpanded(!expanded)
  }

  return (
    <div>
      <hr />
      <h4>
        <a onClick={onClick} href={`/${county.name}-County`}>
          {primaryMarker(county.name)} County
        </a>
      </h4>
      {expanded && <Rates county={county} value={value} />}
    </div>
  )
}

CountyComp.propTypes = {
  county: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  value: PropTypes.string,
}

CountyComp.defaultProps = { value: '0' }

export default CountyComp
