import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { notifyErrors } from 'common/notifications/actions/add'
import types, { forgot } from 'components/auth/actions'
import CommonForm from 'components/auth/commonForm'

export class ForgotCont extends PureComponent {
  static propTypes = {
    clear: PropTypes.func.isRequired,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    notify: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { error, clear, notify } = this.props
    if (error) {
      notify(error)
      clear()
    }
  }

  render() {
    return (
      <CommonForm
        action={forgot}
        form="Forgot"
        attrs={{
          name: 'email',
          placeholder: 'Email',
          btnText: 'Request Password',
          errMsg: 'Enter your email address',
        }}
      />
    )
  }
}

export const mapState = ({ auth }) => {
  let error = false
  if (auth.pwReset && auth.pwReset.error) {
    error = auth.pwReset.error
  }

  return { error }
}

export const mapDispatch = dispatch => ({
  clear: () => dispatch({ type: types.CLEAR_ERROR_MSG }),
  notify: msg => notifyErrors(dispatch, {}, { text: msg, timeout: null }),
})

export default connect(
  mapState,
  mapDispatch
)(ForgotCont)
