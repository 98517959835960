import types from 'components/addresses/actions'
import typesApp from 'components'

export const initState = false

const addrMutatingReducer = (state = initState, action) => {
  switch (action.type) {
    case types.ADDRESSES_MUTATE_TOGGLE: {
      return !state
    }

    case types.VERIFY_FETCH_SUCCESS:
    case typesApp.LOCATION_CHANGE: {
      return false
    }

    default: {
      return state
    }
  }
}

export default addrMutatingReducer
