import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import forUrl from 'common/parameterize'
import getListing from 'components/listings/listing/get'
import { baseURL } from 'components/listings/listing/utils'
import css from 'components/listings/listing/summary/row2/styles'

export const Row2Comp = ({ ad1, ci, glar, ml, show, zi }) => {
  // need span for ellipsis to work
  let content = (
    <span>
      {ad1}
      <br />
      {`${ci} KY ${zi}`}
    </span>
  )

  if (!show) {
    const path = forUrl(`${ml}-${ad1}-${ci}-Kentucky-${zi}`)
    content = <Link to={`${baseURL(glar)}/${path}`}>{content}</Link>
  }

  return <div className={css.row}>{content}</div>
}

Row2Comp.propTypes = {
  ad1: PropTypes.string.isRequired,
  ci: PropTypes.string.isRequired,
  glar: PropTypes.bool.isRequired,
  ml: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  zi: PropTypes.string.isRequired,
}

export const mapState = (state, { ml, show }) => {
  const listing = getListing(state, ml)

  return {
    ad1: listing.get('ad1'),
    ci: listing.get('ci'),
    glar: !!listing.get('glar'),
    ml,
    show,
    zi: listing.get('zi'),
  }
}

export default connect(mapState)(Row2Comp)
