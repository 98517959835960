import { fromJS } from 'immutable'
import typesApp from 'components'
import types from 'common/notifications/actions'

export const initState = fromJS([])

export default function notificationsByIdReducer($$state = initState, action) {
  switch (action.type) {
    case typesApp.LOCATION_CHANGE: {
      return initState
    }

    case types.NOTIFICATIONS_ADD: {
      return $$state.unshift(action.data.id)
    }

    case types.NOTIFICATIONS_REMOVE: {
      return $$state.filter(id => action.data !== id)
    }

    default: {
      return $$state
    }
  }
}
