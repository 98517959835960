import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import isSelf from 'components/people/person/utils/isSelf'
// revert to Button once HH1 is gone
// import { signOut } from 'components/auth/actions'
// import Button from 'common/buttons'
import css from 'components/people/person/signOutBtn/styles'

export const BtnSignOut = ({ isMember }) => {
  if (isMember) {
    return null
  }

  return (
    <div className={css.wrapper}>
      <a href="/sign-out" className={`btn btn-outline-danger ${css.fakeBtn}`}>
        Sign Out
      </a>
    </div>
  )
}

BtnSignOut.propTypes = { isMember: PropTypes.bool.isRequired }

export const mapState = ({ auth, people }) => {
  const doerId = auth.id
  return { isMember: !isSelf(doerId, people.person.id) }
}

export default connect(mapState)(BtnSignOut)
