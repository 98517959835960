import types from 'components/addresses/actions'
import typesApp from 'components'

export const initState = ''

export default function suggestionSearchText(state = initState, action) {
  switch (action.type) {
    case types.SUGGESTION_SEARCH_TEXT: {
      return action.text
    }

    case types.SUGGESTIONS_CLEAR:
    case typesApp.LOCATION_CHANGE:
    case types.VERIFY_FETCH_SUCCESS: {
      return initState
    }

    default: {
      return state
    }
  }
}
