import api from 'components/api'
import getDetail from 'components/details/detail/get'
import { notifyErrors } from 'common/notifications/actions/add'
import { normalize } from 'normalizr'
import * as schema from 'components/details/actions/schema'
import types from 'components/details/actions'

export const noChange = (getState, next) => {
  const state = getState()
  const prev = getDetail(state, next.id).toJS()
  const infoSame = prev.info_formatted === next.info_formatted
  const descSame = prev.description === next.description
  return infoSame && descSame
}

const detailUpdate = (detail, personId) => (dispatch, getState) => {
  const category = `${detail.category}s`

  if (noChange(getState, detail)) {
    dispatch({
      type: types.DETAIL_EDIT_TOGGLE,
      category,
      id: detail.id,
    })
  } else {
    dispatch({
      type: types.DETAIL_UPDATE_START,
      category,
    })

    const url = `/people/${personId}/details/${detail.id}`
    return api
      .patch(url, detail)
      .then(res =>
        dispatch({
          type: types.DETAIL_UPDATE_SUCCESS,
          category,
          data: normalize(res.data, schema.detail),
        })
      )
      .catch(({ response }) => {
        const custom = {}
        if (response.data.error) {
          custom.text = response.data.error
        }
        notifyErrors(dispatch, response, custom)

        dispatch({
          type: types.DETAIL_UPDATE_FAILURE,
          category,
          status: response.status,
        })
      })
  }
}

export default detailUpdate
