import api from 'components/api'
import { notifyErrors } from 'common/notifications/actions/add'
import types from 'components/contact/actions'

const contactSeller = values => dispatch => {
  values.type = 'seller' // server needs to know which one
  return api
    .post('/contact', values)
    .then(res => {
      dispatch({
        type: types.CONTACT_SELLER_SUCCESS,
        data: res.data,
      })
    })
    .catch(({ response }) => notifyErrors(dispatch, response))
}

export default contactSeller
