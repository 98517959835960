import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { times } from 'lodash'
import { getCurrentPage, goToPage } from 'common/pagination/utils'
import PrevNext from 'common/pagination/prevNext'

export * from 'common/pagination/utils'

const options = pages =>
  times(pages, index => {
    index = index + 1

    return (
      <option key={index} value={index}>
        page {index}
      </option>
    )
  })

export const PaginationComp = ({
  currentPage,
  isVisible,
  pathname,
  onChange,
  pages,
}) => (
  <nav
    aria-label="Page navigation"
    style={{
      textAlign: 'center',
      visibility: isVisible,
    }}
  >
    <ul style={{ display: 'inline-block' }}>
      <li style={{ display: 'inline' }}>
        <PrevNext
          disabled={currentPage < 2}
          rel="prev"
          content="<"
          val={currentPage - 1}
          pathname={pathname}
        />
      </li>

      <li style={{ margin: '0 1em', display: 'inline' }}>
        <select
          value={currentPage}
          onChange={onChange}
          className="form-control"
          style={{
            width: '110px',
            display: 'inline',
            verticalAlign: 'top',
          }}
        >
          {options(pages)}
        </select>
      </li>

      <li style={{ display: 'inline' }}>
        <PrevNext
          disabled={currentPage === pages}
          rel="next"
          content=">"
          val={currentPage + 1}
          pathname={pathname}
        />
      </li>
    </ul>
  </nav>
)

PaginationComp.propTypes = {
  currentPage: PropTypes.number.isRequired,
  isVisible: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  pages: PropTypes.number.isRequired,
  pathname: PropTypes.string.isRequired,
}

export const mapState = (_, { location, pages }) => ({
  currentPage: getCurrentPage(location),
  isVisible: pages > 1 ? 'visible' : 'hidden',
  onChange: ({ target }) => goToPage(target.value),
  pages,
  pathname: location.pathname,
})

export default connect(mapState)(PaginationComp)
