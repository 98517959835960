export default [
  {
    id: 5,
    name: 'Blake',
    mls: 'BLAKE',
    kind: 'elem',
    rating: '5',
    grades: 'PK-5',
    link:
      'https://www.greatschools.org/kentucky/louisville/781-Blake-Elementary-School/?utm_source=GSAPI\u0026utm_medium=referral',
  },
  {
    id: 74,
    name: 'Bloomfield',
    mls: 'BLOOMFIELD',
    kind: 'midd',
    rating: '3',
    grades: '6-8',
    link:
      'https://www.greatschools.org/kentucky/bloomfield/1186-Bloomfield-Middle-School/?utm_source=GSAPI\u0026utm_medium=referral',
  },
  {
    id: 101,
    name: 'Assumption',
    mls: 'ASSUMPTION',
    kind: 'high',
    rating: null,
    grades: '9-12',
    link:
      'https://www.greatschools.org/kentucky/louisville/1559-Assumption-High-School/?utm_source=GSAPI\u0026utm_medium=referral',
  },
]
