import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import domain from 'components/utils/domain'
import Breadcrumb from 'common/breadcrumb/component'
import css from 'common/navAdmin/styles'

export const NavAdminComp = ({ isAdmin }) => {
  const addrIssuesLink = (
    <li>
      <Link to="/addresses/untied">Address Issues</Link>
    </li>
  )

  return (
    <Breadcrumb classes={css.style}>
      <li>
        <Link to="/guide">Guide</Link>
      </li>
      <li>
        <Link to="/people">People</Link>
      </li>
      <li>
        <a href="https://my.brokermint.com">Brokermint</a>{' '}
      </li>
      <li>
        <a href={`${domain}/agents`}>Agents</a>
      </li>
      <li>
        <a href="/offices">Offices</a>
      </li>
      <li>
        <a href={`${domain}/email_templates`}>Templates</a>
      </li>
      {isAdmin && addrIssuesLink}
    </Breadcrumb>
  )
}

NavAdminComp.propTypes = { isAdmin: PropTypes.bool.isRequired }

export const mapState = ({ auth }) => ({ isAdmin: !!auth.isAdmin })

export default connect(mapState)(NavAdminComp)
