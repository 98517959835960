import types from 'components/addresses/actions'
import axios, { CancelToken, isCancel } from 'axios'
import isBlank from 'common/formUtils/validations'
import { notifyErrors } from 'common/notifications/actions/add'

const getAutocomplete = addr => (dispatch, getState) => {
  const cancelFn = getState().addresses.currentRequest
  if (cancelFn) {
    // cancel prev request (message param optional)
    cancelFn()
  }

  if (!isBlank(addr)) {
    dispatch({ type: types.ADDRESS_LOADING_START })

    const personId = getState().people.person.id
    // can't use 'components/api' since cancelToken is needed
    return axios
      .get(`/people/${personId}/addresses`, {
        params: { address: addr },
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        cancelToken: new CancelToken(cancelFn => {
          // Do it here because function has
          // to be created WITH axios request
          dispatch({
            type: types.ADDRESS_SET_CURRENT_REQUEST,
            data: cancelFn,
          })
        }),
      })
      .then(res =>
        dispatch({
          type: types.SUGGESTIONS_FETCH_SUCCESS,
          suggestions: res.data,
        })
      )
      .catch(error => {
        if (isCancel(error)) {
          // do nothing (work on next request)
        } else {
          const { status } = error.response
          // no need to notify (just show no results)
          if (status !== 404) {
            notifyErrors(dispatch, error.response)
          }

          dispatch({ type: types.SUGGESTIONS_FETCH_ERROR, status })
        }
      })
  } else {
    dispatch({ type: types.SUGGESTIONS_CLEAR })
  }
}

export default getAutocomplete
