import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withCommas } from 'components/listings/listing/utils'
import getListing from 'components/listings/listing/get'
import css from 'components/listings/listing/summary/row6/styles'

export const Row6Comp = ({ bs, county, kind, ml, sqft, sqftpp }) => {
  if (kind === 0) {
    return (
      <div className={css.row}>
        <div>
          {withCommas(sqft)} SqFt
          <span className="text-muted">
            &nbsp;&nbsp;(${Math.round(sqftpp)} sqft)
          </span>
        </div>

        <div style={{ flex: 1, textAlign: 'right' }}>
          {bs ? 'Basement' : 'No Bsmt'}
        </div>
      </div>
    )
  } else {
    return (
      <div className={css.row}>
        <div className={css.landCounty}>{county} County</div>
        <div>{ml}</div>
      </div>
    )
  }
}

Row6Comp.propTypes = {
  bs: PropTypes.bool,
  county: PropTypes.string.isRequired,
  kind: PropTypes.number.isRequired,
  ml: PropTypes.string.isRequired,
  sqft: PropTypes.number,
  sqftpp: PropTypes.number,
}

Row6Comp.defaultProps = {
  bs: null,
  sqft: null,
  sqftpp: null,
}

export const mapState = (state, { ml }) => {
  const listing = getListing(state, ml)
  return {
    bs: listing.get('bs'),
    county: listing.get('county'),
    kind: listing.get('ki'),
    ml: listing.get('ml'),
    sqft: listing.get('sqft'),
    sqftpp: listing.get('sqftpp'),
  }
}

export default connect(mapState)(Row6Comp)
