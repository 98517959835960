export default {
  PERSON_FETCH_START: 'PERSON_FETCH_START',
  PERSON_FETCH_SUCCESS: 'PERSON_FETCH_SUCCESS',
  PERSON_FETCH_FAILURE: 'PERSON_FETCH_FAILURE',

  PERSON_HH1_START: 'PERSON_HH1_START',
  PERSON_HH1_SUCCESS: 'PERSON_HH1_SUCCESS',
  PERSON_HH1_FAILURE: 'PERSON_HH1_FAILURE',

  PERSON_CREATE_START: 'PERSON_CREATE_START',
  PERSON_CREATE_SUCCESS: 'PERSON_CREATE_SUCCESS',
  PERSON_CREATE_FAILURE: 'PERSON_CREATE_FAILURE',

  PERSON_DELETE_SUCCESS: 'PERSON_DELETE_SUCCESS',
  PERSON_DELETE_FAILURE: 'PERSON_DELETE_FAILURE',

  PERSON_NAME_EDIT_TOGGLE: 'PERSON_NAME_EDIT_TOGGLE',
  PERSON_NAME_UPDATE_START: 'PERSON_NAME_UPDATE_START',
  PERSON_NAME_UPDATE_SUCCESS: 'PERSON_NAME_UPDATE_SUCCESS',
  PERSON_NAME_UPDATE_FAILURE: 'PERSON_NAME_UPDATE_FAILURE',

  PERSON_PASSWORD_EDIT_TOGGLE: 'PERSON_PASSWORD_EDIT_TOGGLE',
  PERSON_PASSWORD_UPDATE_START: 'PERSON_PASSWORD_UPDATE_START',
  PERSON_PASSWORD_UPDATE_SUCCESS: 'PERSON_PASSWORD_UPDATE_SUCCESS',
  PERSON_PASSWORD_UPDATE_FAILURE: 'PERSON_PASSWORD_UPDATE_FAILURE',

  PERSON_MEMBER_ID_START: 'PERSON_MEMBER_ID_START',
  PERSON_MEMBER_ID_SUCCESS: 'PERSON_MEMBER_ID_SUCCESS',
  PERSON_MEMBER_ID_FAILURE: 'PERSON_MEMBER_ID_FAILURE',

  PERSON_KIND_ID_START: 'PERSON_KIND_ID_START',
  PERSON_KIND_ID_SUCCESS: 'PERSON_KIND_ID_SUCCESS',
  PERSON_KIND_ID_FAILURE: 'PERSON_KIND_ID_FAILURE',

  PERSON_MAIL_TO_ID_START: 'PERSON_MAIL_TO_ID_START',
  PERSON_MAIL_TO_ID_SUCCESS: 'PERSON_MAIL_TO_ID_SUCCESS',
  PERSON_MAIL_TO_ID_ERROR: 'PERSON_MAIL_TO_ID_ERROR',
}
