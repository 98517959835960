import _create from 'components/details/actions/_create'
import _createOrUpdate from 'components/details/actions/_createOrUpdate'
import _edit from 'components/details/actions/_edit'
import _destroy from 'components/details/actions/_destroy'
import _fetch from 'components/details/actions/_fetch'
import _move from 'components/details/actions/_move'
import _update from 'components/details/actions/_update'
import _verify from 'components/details/actions/_verify'

export const create = _create
export const createOrUpdate = _createOrUpdate
export const edit = _edit
export const destroy = _destroy
export const fetch = _fetch
export const move = _move
export const update = _update
export const verify = _verify

const types = {
  DETAIL_FETCH_SUCCESS: 'DETAIL_FETCH_SUCCESS',
  DETAIL_FETCH_FAILURE: 'DETAIL_FETCH_FAILURE',

  DETAILS_MUTATE_TOGGLE: 'DETAILS_MUTATE_TOGGLE',
  DETAIL_FORM_REINITIALIZED: 'DETAIL_FORM_REINITIALIZED',

  DETAIL_CREATE_START: 'DETAIL_CREATE_START',
  DETAIL_CREATE_SUCCESS: 'DETAIL_CREATE_SUCCESS',
  DETAIL_CREATE_FAILURE: 'DETAIL_CREATE_FAILURE',

  DETAIL_EDIT_TOGGLE: 'DETAIL_EDIT_TOGGLE',
  DETAIL_UPDATE_START: 'DETAIL_UPDATE_START',
  DETAIL_UPDATE_SUCCESS: 'DETAIL_UPDATE_SUCCESS',
  DETAIL_UPDATE_FAILURE: 'DETAIL_UPDATE_FAILURE',

  DETAIL_VERIFY_START: 'DETAIL_VERIFY_START',
  DETAIL_VERIFY_SUCCESS: 'DETAIL_VERIFY_SUCCESS',
  DETAIL_VERIFY_FAILURE: 'DETAIL_VERIFY_FAILURE',

  DETAIL_DESTROY_START: 'DETAIL_DESTROY_START',
  DETAIL_DESTROY_SUCCESS: 'DETAIL_DESTROY_SUCCESS',
  DETAIL_DESTROY_FAILURE: 'DETAIL_DESTROY_FAILURE',

  DETAIL_MOVE_START: 'DETAIL_MOVE_START',
  DETAIL_MOVE_SUCCESS: 'DETAIL_MOVE_SUCCESS',
  DETAIL_MOVE_FAILURE: 'DETAIL_MOVE_FAILURE',
}

export default types
