import api from 'components/api'
import { normalize } from 'normalizr'
import personSchema from 'components/people/person/actions/schema'
import notifyAdd from 'common/notifications/actions/add'
import types from 'components/auth/actions'

export const url = '/sign-in'

export const signIn = values => dispatch => {
  const failed = () => {
    notifyAdd(dispatch, {
      text: 'Incorrect Email or Password',
      type: 'error',
      timeout: 4000,
    })
  }

  if (!values.email || !values.password) {
    failed()
  } else {
    dispatch({ type: types.SIGN_IN_OUT_START })

    return api
      .post(url, { ...values })
      .then(({ data }) => {
        notifyAdd(dispatch, {
          text: 'Signed In',
          timeout: 3000,
        })

        dispatch({
          type: types.SIGN_IN_SUCCESS,
          data: normalize(data, personSchema),
        })
      })
      .catch(({ response }) => {
        const { status } = response
        if (status === 422) {
          failed()
        }

        dispatch({ type: types.SIGN_IN_FAILURE, status })
      })
  }
}
