import api from 'components/api'
import { notifyErrors } from 'common/notifications/actions/add'
import types from 'components/contact/actions'

const question = values => dispatch =>
  api
    .post('/contact', values)
    .then(res => {
      dispatch({
        type: types.CONTACT_QUESTION_SUCCESS,
        data: res.data,
      })
    })
    .catch(({ response }) => notifyErrors(dispatch, response))

export default question
