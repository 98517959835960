import { createSelector } from 'reselect'

const $$byId = ({ members }) => members.$$byId
const $$ids = ({ members }) => members.$$allIds
const $$agentById = ({ agents }) => agents.$$byId

const getMembers = ($$byId, $$ids, $$agentById) =>
  $$ids.map(id => {
    const member = $$byId.get(String(id))

    const agentId = String(member.get('agent')) // agent id
    const agent = $$agentById.get(agentId)

    return {
      id,
      firstName: agent.getIn(['sum', 'first']),
      isActive: member.get('active'),
    }
  })

export default createSelector(
  [$$byId, $$ids, $$agentById],
  getMembers
)
