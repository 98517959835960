import typesComments from 'common/comments/actions'
import typesAddressee from 'components/addresses/address/addressee/actions'
import typesAddresses from 'components/addresses/actions'
import typesDetail from 'components/details/actions'
import typesPerson from 'components/people/person/actions'
import typesTies from 'components/ties/actions'

export const initState = false

const commentsStaleReducer = (state = initState, action) => {
  switch (action.type) {
    case typesComments.COMMENT_CREATE_SUCCESS:
    case typesAddressee.ADDRESSEE_UPDATE_SUCCESS:
    case typesAddresses.VERIFY_FETCH_SUCCESS:
    case typesAddresses.ADDRESS_MOVE_SUCCESS:
    case typesDetail.DETAIL_CREATE_SUCCESS:
    case typesDetail.DETAIL_UPDATE_SUCCESS:
    case typesDetail.DETAIL_VERIFY_SUCCESS:
    case typesDetail.DETAIL_MOVE_SUCCESS:
    case typesDetail.DETAIL_DESTROY_SUCCESS:
    case typesPerson.PERSON_NAME_UPDATE_SUCCESS:
    case typesPerson.PERSON_PASSWORD_UPDATE_SUCCESS:
    case typesPerson.PERSON_KIND_ID_SUCCESS:
    case typesPerson.PERSON_MAIL_TO_ID_SUCCESS:
    case typesPerson.PERSON_MEMBER_ID_SUCCESS:
    case typesTies.TIES_DEL_PER_ADDR_SUCCESS: {
      return true
    }

    case typesComments.COMMENTS_FETCH_SUCCESS:
    case typesComments.COMMENTS_FETCH_FAILURE: {
      return false
    }

    default: {
      return state
    }
  }
}

export default commentsStaleReducer
