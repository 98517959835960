import { createSelector } from 'reselect'

const member$$allIds = ({ members }) => members.$$allIds
const members$$byId = ({ members }) => members.$$byId
const agents$$byId = ({ agents }) => agents.$$byId
const names = (member$$allIds, members$$byId, agents$$byId) =>
  member$$allIds.reduce((arr, id) => {
    const member = members$$byId.get(String(id))
    const agentId = String(member.get('agent'))

    // only show active members
    if (member.get('active')) {
      const agent = agents$$byId.get(agentId).get('sum')
      const first = agent.get('first')
      const last = agent.get('last')
      arr.push(`${first} ${last}`) // returns array length
    }

    return arr // for next iteration
  }, [])

const nameSelector = createSelector(
  member$$allIds,
  members$$byId,
  agents$$byId,
  names
)

export default nameSelector
