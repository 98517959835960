import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { reduxForm, Field, change } from 'redux-form'
import onKeyDown from 'common/formUtils/onKeyDown'
import types from 'components/guides/actions'

export const formName = 'GuideSearch'

export const GuideSearchComp = ({ onKeyDown, onSubmit }) => (
  <form onSubmit={onSubmit}>
    <Field
      className="form-control"
      id="pattern"
      name="pattern"
      component="input"
      placeholder="Search"
      autoComplete="off"
      spellCheck="false"
      autoFocus={true}
      onKeyDown={onKeyDown}
    />
  </form>
)

GuideSearchComp.propTypes = {
  onKeyDown: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export const mapDispatch = dispatch => {
  // handles escape button
  const cb = () => dispatch(change(formName, 'pattern', ''))

  return {
    onKeyDown: event => {
      onKeyDown(event, cb)
      dispatch({ type: types.GUIDES_TOGGLE_ALL })
    },
    onSubmit: event => event.preventDefault(),
  }
}

export default connect(
  null,
  mapDispatch
)(reduxForm({ form: formName })(GuideSearchComp))
