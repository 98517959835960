/* eslint import/prefer-default-export: 0 */
import api from 'components/api'
import { normalize } from 'normalizr'
import personSchema from 'components/people/person/actions/schema'
import history from 'components/history'
import notifyAdd, { notifyErrors } from 'common/notifications/actions/add'
import types from 'components/auth/actions'

export const resetPw = values => (dispatch, getState) => {
  dispatch({ type: types.RESET_START })

  let { pathname } = history.location
  const personId = getState().auth.pwReset.id
  pathname = pathname.replace(/[^/]+$/, personId)

  return api
    .put(pathname, values)
    .then(({ data }) => {
      dispatch({ type: types.SIGN_OUT_SUCCESS })

      dispatch({
        type: types.SIGN_IN_SUCCESS,
        data: normalize(data, personSchema),
      })

      history.push('/me')

      notifyAdd(dispatch, { text: 'Password changed', timeout: 3000 })
    })
    .catch(({ response }) => {
      const { status } = response
      if (status !== 400 && status < 500) {
        history.push('/forgot')
      }

      const custom = { timeout: null } // sticky
      const { msg } = response.data
      if (msg) {
        custom.text = response.data.msg
      }
      notifyErrors(dispatch, response, custom)

      dispatch({ type: types.RESET_FAILURE, status })
    })
}
