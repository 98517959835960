import api from 'components/api'
import history from 'components/history'
import forUrl from 'common/parameterize'
import notifyAdd, { notifyErrors } from 'common/notifications/actions/add'
import types from 'components/people/person/actions'
import getPerson from 'components/people/person/get'

const personUpdateName = formData => (dispatch, getState) => {
  const personId = getState().people.person.id
  const personOld = getPerson(getState(), personId)

  const oldData = {
    first_name: personOld.get('first_name'),
    last_name: personOld.get('last_name'),
  }

  const firstMod = oldData.first_name !== formData.firstName
  const lastMod = oldData.last_name !== formData.lastName

  if (!firstMod && !lastMod) {
    dispatch({ type: types.PERSON_NAME_EDIT_TOGGLE })
  } else {
    const data = {
      first_name: formData.firstName,
      last_name: formData.lastName,
    }

    // change person's name now (optimistic update)
    dispatch({
      type: types.PERSON_NAME_UPDATE_START,
      personId,
      data,
    })

    return api
      .patch(`/people/${personId}`, data)
      .then(res => {
        let data
        if (res.status === 205) {
          // reset_content
          notifyAdd(dispatch, {
            text: 'Nothing changed',
            type: 'error',
            timeout: 3500,
          })

          data = oldData // 205 sends no data
        } else {
          const name = `${res.data.first_name} ${res.data.last_name}`
          history.replace(`/people/${personId}-${forUrl(name)}`)

          data = {
            first_name: res.data.first_name,
            last_name: res.data.last_name,
          }
        }

        dispatch({ type: types.PERSON_NAME_UPDATE_SUCCESS, personId, data })
      })
      .catch(({ response }) => {
        notifyErrors(dispatch, response)

        dispatch({
          type: types.PERSON_NAME_UPDATE_FAILURE,
          personId,
          data: oldData,
          status: response.status,
        })
      })
  }
}

export default personUpdateName
