import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Loading from 'components/people/person/namePassword/loading'
import Name from 'components/people/person/namePassword/name'
import Password from 'components/people/person/namePassword/password'
import Buttons from 'components/people/person/namePassword/buttons'
import css from 'components/people/person/namePassword/styles'

export const NamePasswordComp = ({ content }) => (
  <>
    <Loading />
    <div className={css.namePassword}>
      {content === 'password' ? <Password /> : <Name />}
      <Buttons />
    </div>
  </>
)

NamePasswordComp.propTypes = {
  content: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
}

export const mapState = ({ people }) => ({ content: people.person.editing })

export default connect(mapState)(NamePasswordComp)
