import { createSelector } from 'reselect'

const recordCountSelector = (recordCount, _) => recordCount
const perPageSelector = (_, perPage) => perPage
const pageCount = (recordCount, perPage) => Math.ceil(recordCount / perPage)
export default createSelector(
  recordCountSelector,
  perPageSelector,
  pageCount
)
