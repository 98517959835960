import api from 'components/api'
import { notifyErrors } from 'common/notifications/actions/add'
import types from 'components/people/person/actions'
import getPerson from 'components/people/person/get'

const personMemberId = event => (dispatch, getState) => {
  const nextMemberId = event.target.value
  const personId = getState().people.person.id
  const person = getPerson(getState(), personId)
  const prevMemberId = person.get('member_id')

  // change person's member_id now (optimistic update)
  dispatch({
    type: types.PERSON_MEMBER_ID_START,
    personId,
    memberId: Number(nextMemberId),
  })

  const url = `/people/${personId}`
  return api
    .patch(url, { member_id: nextMemberId })
    .then(res => dispatch({ type: types.PERSON_MEMBER_ID_SUCCESS }))
    .catch(({ response }) => {
      const custom = {}
      if (response.data.error) {
        custom.text = response.data.error
      }
      notifyErrors(dispatch, response, custom)

      dispatch({
        type: types.PERSON_MEMBER_ID_FAILURE,
        personId,
        memberId: prevMemberId,
        status: response.status,
      })
    })
}

export default personMemberId
