import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import setPageTitle from 'components/utils/setPageTitle'
import { Redirect } from 'react-router-dom'
import onClient from 'components/utils/onClient'
import Form from 'components/auth/form'

export class SignInCont extends PureComponent {
  state = { alreadyRedirected: false }

  static propTypes = {
    leaveDomain: PropTypes.bool.isRequired,
    redirectTo: PropTypes.string,
    signedIn: PropTypes.bool.isRequired,
  }

  static defaultProps = { redirectTo: null }

  componentDidMount() {
    setPageTitle('Sign in to your account')
  }

  componentWillReceiveProps(nextProps) {
    const { alreadyRedirected } = this.state
    const { leaveDomain } = nextProps
    if (!alreadyRedirected && leaveDomain) {
      this.setState({ alreadyRedirected: true })

      // signedIn && onClient are both true here.
      // `.replace` so users can't click "the back button"
      // /sign-in redirects to cookies[:return_to] or /me
      window.location.replace('/sign-in')
    }
  }

  componentWillUnmount() {
    setPageTitle('')
  }

  render() {
    const { redirectTo, signedIn } = this.props
    if (signedIn) {
      return redirectTo ? <Redirect to={redirectTo} /> : <Redirect to="/me" />
    }

    return <Form />
  }
}

export const mapState = ({ auth }, { location }) => {
  const signedIn = !!auth.id
  let redirectTo = location.state
  let leaveDomain = false

  if (redirectTo) {
    redirectTo = redirectTo.from.pathname
  } else if (signedIn && onClient) {
    leaveDomain = true
  }

  return { leaveDomain, redirectTo, signedIn }
}

export default connect(mapState)(SignInCont)
