/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react'
import { Field } from 'react-final-form'
import css from 'components/taxes/search/styles'

const SearchComp = () => (
  <div className={`form-group ${css.inputWrapper}`}>
    <Field
      className="form-control"
      type="text"
      component="input"
      placeholder="Search"
      id="location"
      name="location"
    />
  </div>
)

export default SearchComp
