const isMember = $$person => {
  let roles = $$person.get('roles')
  let result = false
  if (roles && roles.size > 0) {
    roles = roles.toJS()
    result = roles.includes('member')
    result = result || roles.includes('manager')
    result = result || roles.includes('admin')
  }

  return result
}

export default isMember
