import { fromJS } from 'immutable'
import { uniq } from 'lodash'
import types from 'common/comments/actions'

export const initState = fromJS([])

export default function commentsAllIdsReducer($$state = initState, action) {
  switch (action.type) {
    case types.COMMENTS_FETCH_SUCCESS: {
      const uniqIDs = uniq(action.data.result.concat($$state.toJS()))

      return fromJS(uniqIDs)
    }

    case types.COMMENT_CREATE_SUCCESS: {
      return $$state.unshift(action.data.result)
    }

    default: {
      return $$state
    }
  }
}
