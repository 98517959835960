/* eslint import/prefer-default-export: 0 */
import api from 'components/api'
import history from 'components/history'
import notifyAdd, { notifyErrors } from 'common/notifications/actions/add'
import types from 'components/auth/actions'

export const forgot = values => dispatch => {
  dispatch({ type: types.FORGOT_START })

  const { pathname } = history.location
  return api
    .post(pathname, values)
    .then(({ data }) => {
      history.push('/sign-in')
      notifyAdd(dispatch, { text: data.msg, timeout: 8000 })
      dispatch({ type: types.FORGOT_SUCCESS })
    })
    .catch(({ response }) => {
      const custom = {}
      if (response.data && response.data.error) {
        custom.text = response.data.error
      }
      notifyErrors(dispatch, response, custom)

      dispatch({ type: types.FORGOT_FAILURE, status: response.status })
    })
}
