import types from 'components/people/person/actions'

export const initState = false

const kindLoadingReducer = (state = initState, action) => {
  switch (action.type) {
    case types.PERSON_KIND_ID_START: {
      return true
    }

    case types.PERSON_KIND_ID_SUCCESS:
    case types.PERSON_KIND_ID_FAILURE: {
      return false
    }

    // SIGN_OUT_SUCCESS is handled by 'components/reducers'
    default: {
      return state
    }
  }
}

export default kindLoadingReducer
