/* eslint import/prefer-default-export: 0 */
import notifyAdd from 'common/notifications/actions/add'
import api from 'components/api'
import typesAuth from 'components/auth/actions'
import history from 'components/history'

export const url = '/sign-out'

export const signOut = () => dispatch => {
  history.push('/')
  dispatch({ type: typesAuth.SIGN_IN_OUT_START })

  return api
    .delete(url)
    .then(() => {
      dispatch({ type: typesAuth.SIGN_OUT_SUCCESS })
      notifyAdd(dispatch, { text: 'Signed Out', timeout: 3000 })
    })
    .catch(({ response }) =>
      dispatch({ type: typesAuth.SIGN_OUT_FAILURE, status: response.status })
    )
}
