import React from 'react'
import PropTypes from 'prop-types'
import Interweave from 'interweave'
import { UrlMatcher } from 'interweave-autolink'
import moment from 'moment'
import ImmutablePropTypes from 'react-immutable-proptypes'
import css from 'common/comments/comment/styles'

CommentComponent.propTypes = {
  comment: ImmutablePropTypes.contains({
    id: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
  }).isRequired,
}

export default function CommentComponent({ comment }) {
  const timeForTitle = moment(new Date(comment.get('created_at'))).format(
    'M/D/Y [at] h:mma'
  )

  const timeForPage = moment(new Date(comment.get('created_at'))).fromNow()

  return (
    <li className={`list-group-item ${css.comment}`}>
      <Interweave
        content={comment.get('body')}
        matchers={[new UrlMatcher('url')]}
      />

      <div className={css.timestamp} title={timeForTitle}>
        {timeForPage} by {comment.get('who')}
      </div>
    </li>
  )
}
