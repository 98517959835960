/* eslint react/no-array-index-key: 0 */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import domain from 'components/utils/domain'
import Comments from 'common/comments'
import getPerson from 'components/people/person/get'
import Delete from 'components/people/person/admin/delete'
import MemberEdit from 'components/people/person/admin/memberEdit'
import KindEdit from 'components/people/person/admin/kindEdit'
import Tokens from 'components/people/person/admin/tokens'

export const AdminSectionComp = ({
  createdAt,
  createdAtInDays,
  dateHistory,
  daysActive,
  hh1Id,
  ipHistory,
}) => {
  const hh1Url = `${domain}/users/${hh1Id}`
  const hh1Link = (
    <span>
      <a href={hh1Url}>HH1 page</a> (auto-emails and email stats)
    </span>
  )

  const createdDate = moment(createdAt).format('L')
  const neverSignedIn = ' (never signed in)'
  const created = (
    <li>
      <b>Created:</b> {createdDate}
      {!daysActive && neverSignedIn}
    </li>
  )

  const daysActivePct = ((daysActive / createdAtInDays) * 100).toFixed(1)
  const activeDays = (
    <li>
      <b>Days Active:</b> {daysActivePct}% ({daysActive} of {createdAtInDays})
    </li>
  )

  const activity = (
    <li>
      <b>Activity (10 max):</b> {dateHistory && dateHistory.join(', ')}
    </li>
  )

  let ipHistoryDisplay
  if (daysActive) {
    const ips = ipHistory.map((ip, i) => [
      i > 0 && ', ',
      <a
        key={`${ip}-${i}`}
        target="_blank"
        rel="noopener noreferrer"
        href={`http://www.ip2location.com/${ip}`}
      >
        {ip}
      </a>,
    ])
    ipHistoryDisplay = (
      <li>
        <b>IP history (7 max):</b> {ips}
      </li>
    )
  }

  return (
    <>
      <hr />

      <MemberEdit />

      <ul className="list-unstyled">
        <KindEdit />
        {hh1Id && hh1Link}
        {created}
        {!!daysActive && activeDays}
        {!!daysActive && activity}
        {!!daysActive && ipHistoryDisplay}
      </ul>

      {!!daysActive && <Tokens />}

      <Comments />

      <Delete />
    </>
  )
}

AdminSectionComp.propTypes = {
  createdAt: PropTypes.string.isRequired,
  createdAtInDays: PropTypes.number.isRequired,
  dateHistory: PropTypes.arrayOf(PropTypes.string),
  daysActive: PropTypes.number,
  hh1Id: PropTypes.string,
  ipHistory: PropTypes.arrayOf(PropTypes.string),
}

AdminSectionComp.defaultProps = {
  dateHistory: null,
  daysActive: 0,
  hh1Id: null,
  ipHistory: null,
}

export const mapState = state => {
  const { id } = state.people.person
  const person = getPerson(state, id)

  return {
    createdAt: person.get('created_at'),
    createdAtInDays: person.getIn(['data', 'created_at_in_days']),
    dateHistory: person.getIn(['data', 'date_history']).toJS(),
    daysActive: person.getIn(['data', 'days_active_count']),
    hh1Id: person.get('hh1_id'),
    ipHistory: person.getIn(['data', 'ip_history']).toJS(),
  }
}

export default connect(mapState)(AdminSectionComp)
