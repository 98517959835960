import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Breadcrumb from 'common/breadcrumb/component'
import selectedNames from 'components/members/selectedNames'

export const Members = ({ names }) => {
  const memberList = names.map(name => (
    <li className="list-group-item" key={name}>
      {name}
    </li>
  ))

  return (
    <>
      <Breadcrumb>
        <li>HardinHomes.com Realtors</li>
      </Breadcrumb>

      <ul className="list-group">{memberList}</ul>
    </>
  )
}

Members.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
}

export const mapState = state => ({ names: selectedNames(state) })

export default connect(mapState)(Members)
