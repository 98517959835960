import { fromJS } from 'immutable'
import typesApp from 'components'
import typesAuth from 'components/auth/actions'
import typesDetails from 'components/details/actions'
import typesPeople from 'components/people/actions'
import typesPerson from 'components/people/person/actions'
import typesAddressee from 'components/addresses/address/addressee/actions'
import typesAddresses from 'components/addresses/actions'
import typesTies from 'components/ties/actions'
import { uniq } from 'lodash'

export const initState = fromJS({})

const peopleByIdReducer = ($$state = initState, action) => {
  switch (action.type) {
    case typesApp.HYDRATE:
    case typesPeople.PEOPLE_FETCH_SUCCESS: {
      const entities = action.data.entities
      if (entities && entities.people) {
        return fromJS(entities.people) // handles a deleted person
      }

      return $$state
    }

    case typesAuth.SIGN_IN_SUCCESS:
    case typesPeople.PEOPLE_MAIL_TO_ID_UPDATE:
    case typesPerson.PERSON_CREATE_SUCCESS:
    case typesPerson.PERSON_FETCH_SUCCESS: {
      return $$state.merge(action.data.entities.people)
    }

    case typesAddressee.ADDRESSEE_FETCH_SUCCESS: {
      return $$state.merge(action.people.entities.people)
    }

    case typesPerson.PERSON_DELETE_SUCCESS: {
      return $$state.delete(String(action.data.result))
    }

    case typesPerson.PERSON_KIND_ID_START:
    case typesPerson.PERSON_KIND_ID_FAILURE: {
      return $$state.setIn([action.personId, 'kind'], action.kindId)
    }

    // PERSON_NAME_UPDATE_SUCCESS because server can change capitalization
    case typesPerson.PERSON_NAME_UPDATE_START:
    case typesPerson.PERSON_NAME_UPDATE_SUCCESS:
    case typesPerson.PERSON_NAME_UPDATE_FAILURE: {
      return $$state.mergeIn([action.personId], action.data)
    }

    case typesPerson.PERSON_MEMBER_ID_START:
    case typesPerson.PERSON_MEMBER_ID_FAILURE: {
      return $$state.setIn([action.personId, 'member_id'], action.memberId)
    }

    case typesDetails.DETAIL_MOVE_START:
    case typesDetails.DETAIL_MOVE_FAILURE: {
      const id = String(action.personId)
      return $$state.setIn([id, 'details'], action.ids)
    }

    case typesAddresses.ADDRESS_MOVE_START:
    case typesAddresses.ADDRESS_MOVE_FAILURE: {
      const id = String(action.personId)
      return $$state.setIn([id, 'addresses'], action.ids)
    }

    case typesPerson.PERSON_MAIL_TO_ID_START:
    case typesPerson.PERSON_MAIL_TO_ID_ERROR: {
      return $$state.setIn(
        [action.personId, 'data', 'mail_to_id'],
        action.newVal
      )
    }

    case typesDetails.DETAIL_CREATE_SUCCESS: {
      const detailId = action.data.result
      const detail = action.data.entities.details[detailId]
      if (detail.asset_type === 'Person') {
        const person = $$state.get(String(detail.asset_id))
        const newDetailIds = uniq(
          // .toJS() so uniq will work
          person
            .get('details')
            .push(detailId)
            .toJS()
        )

        const updatedPerson = person.merge({ details: newDetailIds })
        // i.e. state.merge({ 10: updatedPerson })
        return $$state.merge({ [detail.asset_id]: updatedPerson })
      }

      return $$state
    }

    case typesDetails.DETAIL_DESTROY_SUCCESS: {
      const person = $$state.get(action.personId)

      const detailIds = person.get('details')
      const newDetailIds = detailIds.filter(detailId => detailId !== action.id)

      const updatedPerson = person.merge({ details: newDetailIds })
      // i.e. state.merge({ 10: updatedPerson })
      return $$state.merge({ [action.personId]: updatedPerson })
    }

    case typesAddresses.VERIFY_FETCH_SUCCESS: {
      const person = $$state.get(action.personId)
      const newAddresses = uniq(
        person
          .get('addresses')
          .push(action.data.result)
          .toJS()
      )

      return _updateAddresses($$state, action, person, fromJS(newAddresses))
    }

    case typesTies.TIES_DEL_PER_ADDR_SUCCESS: {
      let person = $$state.get(action.personId)
      const oldAddresses = person.get('addresses')
      const newAddresses = oldAddresses.filter(
        addrId => addrId !== action.addressId
      )

      const personMailToId = person.getIn(['data', 'mail_to_id'])
      if (personMailToId === action.addressId) {
        person = person.setIn(['data', 'mail_to_id'], null)
      }

      return _updateAddresses($$state, action, person, newAddresses)
    }

    // SIGN_OUT_SUCCESS is handled by 'components/reducers'
    default: {
      return $$state
    }
  }
}

const _updateAddresses = ($$state, action, person, newAddresses) => {
  const updatedPerson = person.merge({ addresses: newAddresses })

  // state.merge({ 10: updatedPerson })
  return $$state.merge({ [action.personId]: updatedPerson })
}

//   PEOPLE_FETCH_FAILURE: 'PEOPLE_FETCH_FAILURE',

//   PERSON_CREATE_FAILURE: 'PERSON_CREATE_FAILURE',

//   PERSON_DELETE_FAILURE: 'PERSON_DELETE_FAILURE',

//   PERSON_NAME_UPDATE_START: 'PERSON_NAME_UPDATE_START',
//   PERSON_NAME_UPDATE_FAILURE: 'PERSON_NAME_UPDATE_FAILURE',

export default peopleByIdReducer
