import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
// import fuzzy from 'fuzzy'
import { stringCheck } from 'common/formUtils/filters/index'
import { formName } from 'components/people/search'
import { promptVal } from 'components/people/search/kind'

const formValue = (state, field) => {
  const form = state.form[formName]
  if (form && form.values) {
    return form.values[field]
  }
}

const getFiltered = (
  postcards,
  memberId,
  kind,
  firstName,
  lastName,
  emailOrPhone,
  $$ids,
  $$people,
  $$idsFromDetails
) =>
  $$ids.reduce((arr, id) => {
    let passed = true
    const person = $$people.get(String(id))

    if (passed && memberId) {
      let actual = person.get('member_id') // might be null
      let userInput = null
      // '0' is "No member"
      if (memberId !== '0') {
        actual = String(actual)
        userInput = String(memberId)
      }

      passed = actual === userInput
    }

    if (passed && postcards) {
      let actual = !!person.get('postcards')
      passed = String(actual) === String(postcards)
    }

    if (passed && kind && kind !== promptVal) {
      const actual = String(person.get('kind'))
      const userInput = String(kind)
      passed = actual === userInput
    }

    if (passed && emailOrPhone && emailOrPhone.length > 1) {
      passed = $$idsFromDetails.includes(id)
    }

    if (passed && firstName) {
      const actual = person.get('first_name')
      const userInput = firstName
      passed = stringCheck(actual, userInput)
    }

    if (passed && lastName) {
      const actual = person.get('last_name')
      const userInput = lastName
      passed = stringCheck(actual, userInput)
    }

    // push returns new array so...
    // "return $$arr.push(person)" never works.
    // Need to return or assign it arr = arr.push(person)
    if (passed) {
      return arr.push(String(id))
    }

    // return arr.sort(sortSelect(sort))
    return arr
  }, fromJS([]))

// // old 'fuzzy' code to use later
// const fullName = `${firstName} ${lastName}`
// const memberIdFilter = memberId !== ''

// // 'active' filter?
// if (fullName) {
//   const list = $$people.valueSeq().reduce((arr, p) => {
//     arr.push({
//       id: p.get('id'),
//       name: `${p.get('first_name')} ${p.get('last_name')}`,
//     })

//     return arr
//   }, [])

//   const options = { extract: (p) => p.name } // which fields?
//   const results = fuzzy.filter(fullName, list, options)

//   $$ids = fromJS(results.map((element) => element.original.id))
// }

const getPostcards = state => formValue(state, 'postcards')
const getMemberId = state => formValue(state, 'memberId')
const getKind = state => formValue(state, 'kind')
const getFirstName = state => formValue(state, 'firstName')
const getLastName = state => formValue(state, 'lastName')
const getAllIds = ({ people }) => people.$$allIds
const getPeople = ({ people }) => people.$$byId
const getEmailOrPhone = state => {
  let value = formValue(state, 'emailOrPhone')
  if (value) {
    // are there any letters (non-numeric)?
    const notPhone = !!value.replace(/[^a-z0-9]/gi, '').match(/\D/)
    if (notPhone) {
      return value.toLowerCase()
    } else {
      return value.replace(/[^0-9]/g, '')
    }
  }
}

const getDetails = state => state.details.$$byId
const idsFromDetailsFilter = (emailOrPhone, $$details) => {
  if (emailOrPhone && emailOrPhone.length > 1) {
    return $$details.reduce((arr, detail) => {
      const actual = detail.get('info')
      const userInput = emailOrPhone

      if (stringCheck(actual, userInput)) {
        return arr.push(detail.get('asset_id'))
      }

      // if not already returned
      return arr
    }, fromJS([]))
  }
}

const getIdsFromDetails = createSelector(
  [getEmailOrPhone, getDetails],
  idsFromDetailsFilter
)

export default createSelector(
  [
    getPostcards,
    getMemberId,
    getKind,
    getFirstName,
    getLastName,
    getEmailOrPhone,
    getAllIds,
    getPeople,
    getIdsFromDetails,
  ],
  getFiltered
)
