import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React from 'react'
import { Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import PropTypes from 'prop-types'
import Layout from 'common/layout'
import Router from 'components/router'
import store from 'components/store'
import 'components/styles' // global

const Application = props => {
  const { location } = props

  // <Layout /> doesn't work because `<Route` passes `path`
  // https://tinyurl.com/react-router-blocked-updates
  return (
    <Provider store={store(props)}>
      <Router location={location}>
        <Route component={Layout} />
      </Router>
    </Provider>
  )
}

Application.propTypes = { location: PropTypes.string.isRequired }

export default Application
