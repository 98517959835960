import { fromJS } from 'immutable'
import typesApp from 'components'

export const initState = fromJS({})

const membersByIdReducer = (state = initState, action) => {
  switch (action.type) {
    case typesApp.HYDRATE: {
      return fromJS(action.members.entities.members)
    }

    default: {
      return state
    }
  }
}

export default membersByIdReducer
