import React from 'react'
import PropTypes from 'prop-types'
import { notGlar, withCommas } from 'components/listings/listing/utils'
import css from 'components/listings/listing/glar/styles'

export const SqFt = ({ listing }) => {
  if (notGlar(listing)) {
    return null
  }

  const sq = listing.get('sqData').toJS()

  return (
    <table className={`table ${css.wrapper}`}>
      <tbody>
        <tr className={css.attn}>
          <th>SqFt</th>
          <td>Finished</td>
          <td>Unfinished</td>
        </tr>
        <tr>
          <th>Above-grade</th>
          <td>{withCommas(sq.abvFin)}</td>
          <td>{withCommas(sq.abvUn)}</td>
        </tr>
        <tr>
          <th>Below-grade</th>
          <td>{withCommas(sq.beloFin)}</td>
          <td>{withCommas(sq.beloUn)}</td>
        </tr>
        <tr>
          <th>Non-conform</th>
          <td>{withCommas(sq.nonFin)}</td>
          <td>{withCommas(sq.nonUn)}</td>
        </tr>
        <tr className={css.attn}>
          <th>Total</th>
          <td>{withCommas(sq.totFin)}</td>
          <td>{withCommas(sq.totUn)}</td>
        </tr>
        <tr>
          <td colSpan="3" style={{ textAlign: 'center' }}>
            Finished Price Per SqFt: ${withCommas(Math.round(sq.pp))}
          </td>
        </tr>
        <tr>
          <td colSpan="3" style={{ textAlign: 'center' }}>
            Data Source: {withCommas(sq.src)}
          </td>
        </tr>
      </tbody>
    </table>
  )
}
SqFt.propTypes = { listing: PropTypes.shape() }
SqFt.defaultProps = { listing: undefined }

export default SqFt
