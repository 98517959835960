import types from 'common/comments/actions'

export const initState = false

const commentsLoadingReducer = (state = initState, action) => {
  switch (action.type) {
    case types.COMMENTS_FETCH_START:
    case types.COMMENT_CREATE_START: {
      return true
    }

    case types.COMMENTS_FETCH_SUCCESS:
    case types.COMMENTS_FETCH_FAILURE:
    case types.COMMENT_CREATE_FAILURE:
    case types.COMMENT_CREATE_SUCCESS: {
      return false
    }

    default: {
      return state
    }
  }
}

export default commentsLoadingReducer
