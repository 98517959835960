/* eslint camelcase: 0 */
/* eslint jsx-a11y/label-has-associated-control: 0 */
// labels with .sr-only can't wrap the input because
// they're hidden with CSS so the input will be too
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import create from 'components/contact/seller/actions'
import fetchPerson from 'components/people/person/actions/fetch'
import setPageTitle from 'components/utils/setPageTitle'
import { Form, Field } from 'react-final-form'
import { contactClass, mapState, validate } from 'components/contact/utils'
import tour from 'components/contact/seller/hardin-county-real-estate-virtual-tour.jpg'
import stats from 'components/contact/elizabethtown-real-estate-sales-stats.gif'

const msg = 'We will contact you ASAP'

const Success = () => (
  <h2
    className="text-success"
    style={{ margin: '0 auto', textAlign: 'center' }}
  >
    Sent.
    <br />
    {msg}.
  </h2>
)

const ContactForm = ({ handleSubmit, submitting, errors, touched }) => (
  <form
    onSubmit={handleSubmit}
    action="/contact"
    method="post"
    noValidate={true}
    style={{ maxWidth: '400px', margin: '0 auto' }}
  >
    <div className="form-group">
      <label className="sr-only" htmlFor="address">
        Address
      </label>
      <Field
        component="input"
        className="form-control"
        id="address"
        name="address"
        placeholder="Selling Address"
        type="text"
      />
    </div>

    <div style={{ display: 'flex' }}>
      <div className="form-group">
        <label className="sr-only" htmlFor="first_name">
          First Name
        </label>
        <Field
          component="input"
          className="form-control"
          id="first_name"
          name="first_name"
          placeholder="First Name"
          type="text"
        />
      </div>

      <div className="form-group" style={{ marginLeft: '1rem' }}>
        <label className="sr-only" htmlFor="last_name">
          Last Name
        </label>
        <Field
          component="input"
          className="form-control"
          id="last_name"
          name="last_name"
          placeholder="Last Name"
          type="text"
        />
      </div>
    </div>

    <div className={contactClass(touched, errors)}>
      <div className="form-group">
        <label className="sr-only" htmlFor="email">
          Email
        </label>
        <Field
          component="input"
          className="form-control"
          id="email"
          name="email"
          placeholder="Email"
          type="email"
        />
      </div>

      <div className="form-group" style={{ marginBottom: 0 }}>
        <label className="sr-only" htmlFor="phone">
          Phone
        </label>
        <Field
          component="input"
          className="form-control"
          id="phone"
          name="phone"
          placeholder="Phone"
          type="tel"
        />
      </div>
    </div>

    <div className="form-group" style={{ marginTop: '1rem' }}>
      <button className="btn btn-primary" type="submit" disabled={submitting}>
        Send
      </button>

      <span style={{ color: '#666', marginLeft: '0.75em' }}>
        {submitting ? 'Sending...' : msg}
      </span>
    </div>
  </form>
)

ContactForm.propTypes = {
  errors: PropTypes.shape({}).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  touched: PropTypes.shape({}).isRequired,
}

export const SellerComp = ({ contactInfo, create, fetchPerson }) => {
  const [fetched, setFetched] = useState(false)

  useEffect(() => {
    setPageTitle('Sell your house - Elizabethtown KY Realtors')

    const { id } = contactInfo
    if (id && !fetched) {
      setFetched(true)
      fetchPerson(id)
    }

    return () => setPageTitle('')
  }, [])

  return (
    <>
      <h1 style={{ textAlign: 'center' }}>Ready to get your house sold?</h1>

      <h4 style={{ textAlign: 'center', margin: '1em 0' }}>
        We will help you come up with the
        <br />
        plan that best fits your needs.
        <br />
        All with no obligation.
      </h4>

      <Form
        onSubmit={create}
        subscription={{
          errors: true,
          submitting: true,
          submitSucceeded: true,
          touched: true,
          values: true,
        }}
        initialValues={contactInfo}
        validate={validate}
      >
        {({ errors, handleSubmit, submitSucceeded, submitting, touched }) => {
          return submitSucceeded ? (
            <Success />
          ) : (
            <ContactForm
              errors={errors}
              handleSubmit={handleSubmit}
              submitting={submitting}
              touched={touched}
            />
          )
        }}
      </Form>

      <div style={{ margin: '3rem auto 0', maxWidth: '1176px' }}>
        <h3 style={{ textAlign: 'center' }}>
          A 3D Virtual Tour means an Open House 24/7.
          <br />
          It's the most realistic way to experience a home online.
        </h3>
        <a
          href="https://my.matterport.com/show/?m=BQZzzvyLsHr"
          style={{ display: 'block' }}
        >
          <img
            alt="3D Virtual Tour - Elizabethtown KY Real Estate"
            src={tour}
            style={{ maxWidth: '100%', height: 'auto' }}
            title="3D Virtual Tour - Elizabethtown KY Real Estate"
          />
        </a>
      </div>

      <div style={{ margin: '3rem auto 0', maxWidth: '589px' }}>
        <img
          alt="Elizabethtown real estate market sales statistics"
          src={stats}
          style={{ maxWidth: '100%', height: 'auto' }}
          title="Elizabethtown real estate market sales statistics"
        />
      </div>
    </>
  )
}

SellerComp.propTypes = {
  contactInfo: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.string,
  }),
  create: PropTypes.func.isRequired,
  fetchPerson: PropTypes.func.isRequired,
}

SellerComp.defaultProps = {
  contactInfo: PropTypes.shape({
    id: null,
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    address: '',
  }),
}

export default connect(mapState, { create, fetchPerson })(SellerComp)
