import api from 'components/api'
import { notifyErrors } from 'common/notifications/actions/add'
import getDetail from 'components/details/detail/get'
import types from 'components/details/actions'

const detailDestroy = id => (dispatch, getState) => {
  const state = getState()

  const detail = getDetail(state, id)
  const category = `${detail.get('category')}s`

  dispatch({
    type: types.DETAIL_DESTROY_START,
    id,
    category,
  })

  const personId = state.people.person.id
  return api
    .delete(`/people/${personId}/details/${id}`)
    .then(() =>
      dispatch({
        type: types.DETAIL_DESTROY_SUCCESS,
        id,
        category,
        personId: String(personId),
      })
    )
    .catch(({ response }) => {
      const custom = {}
      if (response.data.error) {
        custom.text = response.data.error
      }
      notifyErrors(dispatch, response, custom)

      dispatch({
        type: types.DETAIL_DESTROY_FAILURE,
        category,
        status: response.status,
      })
    })
}

export default detailDestroy
