import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Modal from 'common/modal'
import { modalOpen } from 'common/modal/actions'
import Recipients from 'components/addresses/address/addressee/recipients'
import Form from 'components/addresses/address/addressee/form'
import addresseeFetch from 'components/addresses/address/addressee/actions/fetch'
import css from 'components/addresses/address/addressee/styles'

export const TextContent = () => (
  <>
    <h3>How does this work?</h3>
    Addressee is automatically generated based on people tied to this address.
    <br />
    <ul>
      <li>
        <strong>1 person:</strong>
        &nbsp; "Mary Smith" stays "Mary Smith"
      </li>
      <li>
        <strong>2 people (all Smith):</strong>
        &nbsp; "Mary Smith" and "Jake Smith" become "Mary and Jake Smith"
      </li>
      <li>
        <strong>3+ people (all Smith):</strong>
        &nbsp; "Mary Smith" and "Jake Smith" and "Tommy Smith" become "Mary,
        Jake, and Tommy Smith"
      </li>
      <li>
        <strong>2 people (not all Smith):</strong>
        &nbsp; "Jane Doe" and "Mike Lee" become "Jane Doe and Mike Lee"
      </li>
      <li>
        <strong>3+ people (not all Smith):</strong>
        &nbsp; "Jane Doe" and "Mike Lee" and "Fred Brown" become "Jane Doe, Mike
        Lee, and Fred Brown"
      </li>
    </ul>
    <br />
    Situations where it <i>might</i> be useful:
    <ul>
      <li>The Smith Family</li>
      <li>Aunt Sally and Uncle Joe</li>
    </ul>
  </>
)

export const AddresseeComp = ({ modalOpen, addresseeFetch, addrId }) => (
  <div style={{ display: 'inline', paddingRight: '10px' }}>
    <button
      type="button"
      className="btn btn-sm btn-outline-secondary"
      onClick={modalOpen}
    >
      addressee
    </button>

    {/* Modal `name` has to match name set in reducer */}
    <Modal
      onOpen={addresseeFetch}
      title="Custom Addressee?"
      name="addressee"
      minWidth="0"
    >
      <div className={css.wrapper}>
        <Recipients />
        <Form addrId={addrId} />
        <br />
        <TextContent />
      </div>
    </Modal>
  </div>
)

AddresseeComp.propTypes = {
  addresseeFetch: PropTypes.func.isRequired,
  addrId: PropTypes.string.isRequired,
  modalOpen: PropTypes.func.isRequired,
}

export const mapState = (_, { addrId }) => ({ addrId: String(addrId) })

export const mapDispatch = (dispatch, { addrId }) => ({
  addresseeFetch: () => dispatch(addresseeFetch(addrId)),
  modalOpen: () => dispatch(modalOpen('addressee')),
})

export default connect(
  mapState,
  mapDispatch
)(AddresseeComp)
