export default {
  id: 236,
  sum: {
    first: 'John',
    last: 'Taylor',
    type: 'AGENT',
    email: 'John@HardinHomes.com',
    phone: '270-765-9345',
  },
}
