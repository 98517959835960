import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { clickify } from 'components/details/utils'
import isSelf from 'components/people/person/utils/isSelf'
import memberOwner from 'components/people/person/memberOwner'
import Verify from 'components/details/detail/verify'
import getDetail from 'components/details/detail/get'
import Edit from 'components/details/detail/edit'
import css from 'components/details/detail/styles'

export const DetailComp = ({ canConfirm, detail, mutating, provided }) => {
  let desc = detail.description
  if (desc) {
    desc = <span className={css.description}>- {desc}</span>
  }

  let classNames = `list-group-item ${css.item}`
  if (mutating) {
    classNames = `${classNames} ${css.mutating}`
  }

  const { dragHandleProps } = provided
  return (
    <li
      className={classNames}
      ref={provided.innerRef}
      {...provided.draggableProps}
    >
      {mutating && <Edit id={detail.id} dragProps={dragHandleProps} />}
      {!mutating && canConfirm && !detail.confirmed && (
        <Verify detail={detail} />
      )}
      <span className={css.content}>
        {clickify(detail)}
        {desc}
      </span>
    </li>
  )
}

DetailComp.propTypes = {
  canConfirm: PropTypes.bool.isRequired,
  detail: PropTypes.shape({
    confirmed: PropTypes.bool.isRequired,
    description: PropTypes.string,
    id: PropTypes.number.isRequired,
  }).isRequired,
  mutating: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  provided: PropTypes.shape({}).isRequired,
}

export const mapState = (state, { id }) => {
  const detail = getDetail(state, id).toJS()

  const { category } = detail
  const type = `${category}s`
  const mutating = state.details.mutating[type]

  const personId = state.people.person.id
  let canConfirm = false
  if (category === 'phone') {
    canConfirm = memberOwner(state, personId)
  }
  if (category === 'email') {
    const doerId = state.auth.id
    canConfirm = isSelf(doerId, personId)
  }

  return { canConfirm, detail, mutating }
}

export default connect(mapState)(DetailComp)
