import { combineReducers } from 'redux'
import { normalize } from 'normalizr'
import * as schema from 'components/taxes/actions/schema'
import $$allIds from 'components/taxes/reducers/allIds'
import $$byId from 'components/taxes/reducers/byId'
import $$byCounty from 'components/taxes/reducers/byCounty'
import byName from 'components/taxes/reducers/byName'

export const process = data => {
  return normalize(data, schema.arrayOfTaxes)
}

export default combineReducers({
  $$allIds,
  $$byId,
  $$byCounty,
  byName,
})
