/* guides don't currently need accessibility */
/* eslint 'jsx-a11y/click-events-have-key-events': 0 */
/* eslint 'jsx-a11y/no-noninteractive-element-interactions': 0 */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import convert from 'htmr'
import Fuse from 'fuse.js'
import setPageTitle from 'components/utils/setPageTitle'
import { FaTimes } from 'react-icons/fa'
import GuideSearchForm from 'components/guides/form'
import selected from 'components/guides/selected'
import fetch from 'components/guides/actions/fetch'
import types from 'components/guides/actions'
import css from 'components/guides/styles'

// ####### later
// break this file into multiple components
// propTypes
// Admin form for new/edit
// Update url with ?search=pattern
// // new window uses that search
// Filter for roles (except for juniors)
// Add cleaned text to redux so it doesn't need to be cleaned each keystroke

const clean = html => {
  // https://regex101.com/r/fBE4ub/1 for explanation
  const matchData = html.matchAll(/<a.*?href=(['"])(.*?)\1>(.*?)<\/a>/gi)
  const matches = Array.from(matchData, res => res)

  // replace linkTags with anchorText (url) for searching
  if (matches && matches.length) {
    matches.forEach(match => {
      const linkTag = match[0]
      const url = match[2]
      const anchorText = match[3]
      if (anchorText !== url) {
        const replacementText = `${anchorText} (${url})`
        html = html.replace(linkTag, replacementText)
      }
    })
  }

  // strip <style> https://regex101.com/r/C28OPE/1
  html = html.replace(/<style([\S\s]*?)>([\S\s]*?)<\/style>/g, '')

  // strip all html tags
  return html.replace(/<\/?[^>]+(>|$)/g, ' ')
}

const GuideComp = ({ expanded, html, onClick }) => {
  const klass = expanded ? css.expanded : css.collapsed
  let innerClass = css.expandedInner
  const attrs = {
    className: `list-group-item ${css.guide} ${klass}`,
  }
  if (!expanded) {
    attrs.onClick = onClick
    innerClass = css.collapsedInner
  }

  const collapse = (
    <div onClick={onClick} className={css.expandedClose}>
      <FaTimes />
    </div>
  )

  return (
    <li {...attrs}>
      {expanded ? collapse : null}
      <div className={innerClass}>{convert(html)}</div>
    </li>
  )
}

GuideComp.propTypes = {
  expanded: PropTypes.bool.isRequired,
  html: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export const mapStateGuide = ({ guides }, { id }) => {
  const expanded = guides.expanded.includes(id)
  const guide = guides.$$byId.get(String(id))
  let html = guide.get('html')
  if (!expanded) {
    html = clean(html)
  }

  return { html, expanded }
}

export const mapDispatch = (dispatch, { id }) => ({
  onClick: () => dispatch({ type: types.GUIDE_TOGGLE, id }),
})

const Guide = connect(
  mapStateGuide,
  mapDispatch
)(GuideComp)

const GuideList = ({ name, results, onClick }) => {
  const list = results.map(guide => <Guide id={guide.id} key={guide.id} />)

  return (
    <div className={css.guideList}>
      <div>
        {name}: {results.length}
      </div>
      <ul className="list-group">{list}</ul>
    </div>
  )
}

const GuidesComp = ({ mainResults, fetch, fuzzyResults }) => {
  const [fetched, setFetched] = useState(false)

  useEffect(() => {
    setPageTitle('Agent Guide')
    if (!fetched) {
      setFetched(true)
      fetch()
    }

    return () => setPageTitle('')
  })

  return (
    <>
      <GuideSearchForm />
      <GuideList name="Results" results={mainResults} />
      {!!fuzzyResults.length && (
        <GuideList name="Possible" results={fuzzyResults} />
      )}
    </>
  )
}

// GuidesComp.propTypes = {
//   mainResults: PropTypes.objectOf(
//     PropTypes.shape({
//       id: PropTypes.number.isRequired,
//       html: PropTypes.string.isRequired,
//     }).isRequired
//   ).isRequired,
// }

export const mapState = state => {
  let pattern = ''
  const form = state.form.GuideSearch
  if (form && form.values && form.values.pattern) {
    pattern = form.values.pattern
  }
  let mainResults = []
  const fuzzyResults = []

  let guides = selected(state).toJS()

  // remove html tags (div, span, etc) so they're not searched
  const list = guides.map(guide => ({
    ...guide,
    html: clean(guide.html),
  }))

  const options = {
    ignoreFieldNorm: true, // no weight based on string length
    ignoreLocation: true, // search the entire string
    includeScore: true,
    // minMatchCharLength: 2,
    threshold: 0.53,
    useExtendedSearch: true,
    keys: ['html'],
  }
  const fuse = new Fuse(list, options)
  const results = fuse.search(pattern)

  results.forEach(guide => {
    guide = {
      ...guides[guide.refIndex],
      score: guide.score,
    }
    if (guide.score > 0.19) {
      fuzzyResults.push(guide)
    } else {
      mainResults.push(guide)
    }
  })

  return {
    mainResults: pattern.length === 0 ? guides : mainResults,
    fuzzyResults,
  }
}

export default connect(
  mapState,
  { fetch }
)(GuidesComp)
