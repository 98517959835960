import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import setPageTitle from 'components/utils/setPageTitle'
import fetch from 'components/taxes/actions/fetch'
import fetchCounties from 'components/counties/actions/fetch'
import { Form } from 'react-final-form'
import TaxForm from 'components/taxes/form'

const TaxesComp = ({
  fetch,
  fetchCounties,
  onSubmit,
  needCounties,
  needTax,
}) => {
  const [fetched, setFetched] = useState(false)
  const [fetchingCounties, setFetchingCounties] = useState(false)

  useEffect(() => {
    setPageTitle('Kentucky Property Taxes')

    if (!fetchingCounties && needCounties) {
      setFetchingCounties(true)
      fetchCounties()
    }

    if (!fetched && needTax) {
      setFetched(true)
      fetch()
    }

    return () => setPageTitle('')
  })

  return (
    <Form
      onSubmit={onSubmit}
      component={TaxForm}
      subscription={{ submitting: true, values: true }}
    />
  )
}

TaxesComp.propTypes = {
  fetch: PropTypes.func.isRequired,
  fetchCounties: PropTypes.func.isRequired,
  needCounties: PropTypes.bool.isRequired,
  needTax: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export const mapState = ({ counties, taxes }) => ({
  onSubmit: event => event.preventDefault(),
  needCounties: counties.$$allIds.size === 0,
  needTax: taxes.$$allIds.size === 0,
})

export default connect(
  mapState,
  { fetch, fetchCounties }
)(TaxesComp)
