import getAgent from 'components/agents/get'

const getFirstName = (state, id) => {
  let firstName
  const agent = getAgent(state, id)
  if (agent) {
    firstName = agent.getIn(['sum', 'first'])
  }

  return firstName
}

export default getFirstName
