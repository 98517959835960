import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import history from 'components/history'
import domain from 'components/utils/domain'
import fetch from 'components/people/person/HomeBuying/fetch'

export class HomeBuyingCont extends PureComponent {
  static propTypes = {
    fetch: PropTypes.func.isRequired,
    hh1Id: PropTypes.string,
  }

  static defaultProps = { hh1Id: null }

  state = { searches: '?', likes: '?', dislikes: '?' }

  componentDidMount() {
    const { fetch, hh1Id } = this.props
    if (hh1Id) {
      fetch(hh1Id).then(({ data }) => this.setState(data))
    }
  }

  linkify = (hh1Id, path, text, value) => (
    <a href={`${domain}/users/${hh1Id}/${path}`}>{`${text}: ${value}`}</a>
  )

  render() {
    const { hh1Id } = this.props
    if (hh1Id) {
      const { searches, likes, dislikes } = this.state

      const { pathname } = history.location
      const searchPath =
        pathname === '/me' ? '/searches' : `${pathname}/searches`

      return (
        <h5 style={{ textAlign: 'center' }}>
          <a href={searchPath}>Searches: {searches}</a>
          {' | '}
          {this.linkify(hh1Id, 'favorites', 'Likes', likes)}
          {' | '}
          {this.linkify(hh1Id, 'unfavorites', 'Dislikes', dislikes)}
        </h5>
      )
    } else {
      return null
    }
  }
}

export default connect(null, { fetch })(HomeBuyingCont)
