import history from 'components/history'
import api from 'components/api'
import types from 'components/people/person/actions'
import { notifyErrors } from 'common/notifications/actions/add'
import { normalize } from 'normalizr'
import personSchema from 'components/people/person/actions/schema'

const personFetch = personId => dispatch => {
  dispatch({ type: types.PERSON_FETCH_START })

  const url = `/people/${personId}.json`
  return api
    .get(url)
    .then(res =>
      dispatch({
        type: types.PERSON_FETCH_SUCCESS,
        data: normalize(res.data, personSchema),
      })
    )
    .catch(({ response }) => {
      // Listing question and /sell-your-house don't need this
      const { pathname } = history.location
      if (pathname.toLowerCase().startsWith('/people')) {
        history.push('/people')
        notifyErrors(dispatch, response)
      }

      dispatch({
        type: types.PERSON_FETCH_FAILURE,
        status: response.status,
      })
    })
}

export default personFetch
