import { fromJS } from 'immutable'
import typesTaxes from 'components/taxes/actions'

export const initState = fromJS({})

const taxesByIdReducer = ($$state = initState, action) => {
  switch (action.type) {
    case typesTaxes.TAXES_FETCH_SUCCESS: {
      return fromJS(action.data.entities.taxes)
    }

    default: {
      return $$state
    }
  }
}

export default taxesByIdReducer
