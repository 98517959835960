import React from 'react'
import PropTypes from 'prop-types'

export const CompanyComp = ({ co, em }) => (
  <tr>
    <th>IDX: Courtesy of</th>
    <td>
      {co} {em && `—${em}`}
    </td>
  </tr>
)

CompanyComp.propTypes = {
  co: PropTypes.string.isRequired,
  em: PropTypes.string,
}

CompanyComp.defaultProps = { em: null }

export default CompanyComp
