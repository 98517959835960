/* eslint import/prefer-default-export: 0 */
import React from 'react'

export const acreDisplay = acreage => {
  const isOne = Number(acreage) === 1
  const text = isOne ? 'Acre' : 'Acres'
  return `${acreage} ${text}`
}

export const mdyyyy = seconds => {
  const milliseconds = seconds * 1000
  const date = new Date(milliseconds)

  return date.toLocaleDateString('en-US')
}

export const dateDisplay = seconds => {
  const milliseconds = seconds * 1000
  const date = new Date(milliseconds)
  const days = _dayDiff(date, Date.now())
  const plural = days === 1 ? '' : 's'

  return (
    <span title={date.toLocaleDateString('en-US')}>
      {withCommas(days)} day{plural} for sale
    </span>
  )
}

const _dayDiff = (first, second) => {
  // 'ceil' to avoid 0 days
  return Math.ceil((second - first) / (1000 * 60 * 60 * 24)) // 86400000 (a day)
}

export const withCommas = value => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const notGlar = listing => !listing.get('glar')

export const baseURL = glar =>
  glar ? '/louisville-homes-for-sale' : '/land-for-sale'
