import { fromJS } from 'immutable'
import types from 'components/bah/actions'
import typesApp from 'components'

export const initState = fromJS([])

const bahAllReducer = (state = initState, action) => {
  switch (action.type) {
    case typesApp.HYDRATE: {
      if (action.location.includes('/bah-rates')) {
        return fromJS(action.data)
      }

      return state
    }

    case types.BAH_RATES_FETCH_SUCCESS: {
      return fromJS(action.data)
    }

    default: {
      return state
    }
  }
}

export default bahAllReducer
