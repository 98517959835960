import api from 'components/api'
import types from 'components/addresses/actions'
import { notifyErrors } from 'common/notifications/actions/add'
import { normalize } from 'normalizr'
import * as schema from 'components/addresses/actions/schema'

const fetchUntied = () => dispatch => {
  dispatch({ type: types.ADDRESS_UNTIED_FETCH_START })

  return api
    .get('/addresses/untied.json')
    .then(res =>
      dispatch({
        type: types.ADDRESS_UNTIED_FETCH_SUCCESS,
        data: normalize(res.data, schema.arrayOfAddresses),
      })
    )
    .catch(({ response }) => {
      notifyErrors(dispatch, response)
      dispatch({
        type: types.ADDRESS_UNTIED_FETCH_FAILURE,
        status: response.status,
      })
    })
}

export default fetchUntied
