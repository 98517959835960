import { change } from 'redux-form'
import { formName } from 'components/people/search'

const peopleFilterClear = event => dispatch => {
  if (event.keyCode === 27) {
    // escape keycode is 27
    dispatch(change(formName, event.target.name, ''))

    // So redux-form (firefox) doesn't issue another 'change'
    // with old value which prevents resetting to ''
    event.preventDefault()
  }
}

export default peopleFilterClear
