import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import fetchUntied from 'components/addresses/actions/fetchUntied'
import ImmutablePropTypes from 'react-immutable-proptypes'

export class UntiedCont extends PureComponent {
  static propTypes = {
    addresses: ImmutablePropTypes.listOf(PropTypes.string).isRequired,
    fetchUntied: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { fetchUntied } = this.props
    fetchUntied()
  }

  address(addr) {
    return (
      <li key={addr} className="list-group-item">
        {addr}
      </li>
    )
  }

  render() {
    const { addresses } = this.props

    return (
      <>
        <h4>Addresses without Properties</h4>
        <ul className="list-group">
          {addresses.map(address => this.address(address))}
        </ul>
      </>
    )
  }
}

export const mapState = ({ addresses }) => ({
  addresses: addresses.$$untiedIds.map(id =>
    addresses.$$byId.get(String(id)).get('address')
  ),
})

export default connect(
  mapState,
  { fetchUntied }
)(UntiedCont)
