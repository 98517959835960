import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import getListing from 'components/listings/listing/get'
import Modal from 'react-modal'
import { modalClose } from 'common/modal/actions'
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'
import css from 'components/listings/listing/summary/row1/mapModal/styles'

export const modalName = 'map'

export const MapModalComp = ({
  address,
  google,
  isOpen,
  lat,
  lng,
  modalClose,
}) => {
  return (
    <Modal
      name={modalName}
      isOpen={isOpen}
      onRequestClose={modalClose}
      contentLabel="Modal"
      overlayClassName={`modal ${css.wrapper}`}
      className={`modal-dialog ${css.dialog}`}
    >
      <div className={`${css.content} modal-content`}>
        <div className={`${css.header} modal-header`}>
          <h5 className="modal-title">{address}</h5>

          <button
            type="button"
            className={`${css.headerKid} close`}
            style={{ maxWidth: '50px' }}
            aria-label="Close"
            onClick={modalClose}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div className="modal-body">
          <Map
            google={google}
            zoom={15}
            initialCenter={{ lat, lng }}
            containerStyle={{ position: 'unset' }}
          >
            <Marker position={{ lat, lng }} />
          </Map>
        </div>
      </div>
    </Modal>
  )
}
MapModalComp.propTypes = {
  address: PropTypes.string,
  google: PropTypes.shape({}).isRequired,
  isOpen: PropTypes.bool.isRequired,
  lat: PropTypes.number,
  lng: PropTypes.number,
  modalClose: PropTypes.func.isRequired,
}
MapModalComp.defaultProps = {
  address: null,
  lat: null,
  lng: null,
}

export const mapState = state => {
  const {
    name,
    props: { ml },
  } = state.modal
  const isOpen = name === modalName

  // modal might not be open yet
  if (!ml) {
    return { isOpen }
  }

  const listing = getListing(state, ml)
  return {
    address: `${listing.get('ad1')} ${listing.get('ci')}`,
    isOpen,
    lat: listing.get('lat'),
    lng: listing.get('long'),
  }
}

export default connect(
  mapState,
  { modalClose }
)(
  GoogleApiWrapper({
    apiKey: 'AIzaSyDzeofJu1_gTNeseQcxycriz7BWyW7XwVE',
  })(MapModalComp)
)
