import api from 'components/api'
import types from 'components/people/person/actions'
import history from 'components/history'
import { normalize } from 'normalizr'
import personSchema from 'components/people/person/actions/schema'
import { notifyErrors } from 'common/notifications/actions/add'

export const cleaned = person => {
  person.first_name = person.firstName
  person.last_name = person.lastName
  person.member_id = person.memberId

  // removes property from object // https://tinyurl.com/yyecp7qk
  let { firstName, lastName, memberId, ...cleanedPerson } = person
  return cleanedPerson
}

const personCreate = person => dispatch => {
  dispatch({ type: types.PERSON_CREATE_START })

  return api
    .post('/people', cleaned(person))
    .then(res => {
      dispatch({
        type: types.PERSON_CREATE_SUCCESS,
        data: normalize(res.data, personSchema),
      })

      history.push(`/people/${res.data.id}`)
    })
    .catch(({ response }) => {
      const custom = {}
      if (response.data && response.data.error) {
        custom.text = response.data.error
      }
      notifyErrors(dispatch, response, custom)

      dispatch({
        type: types.PERSON_CREATE_FAILURE,
        status: response.status,
      })
    })
}

export default personCreate
