import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import getListing from 'components/listings/listing/get'
import isBlank from 'common/formUtils/validations'

export const ToursComp = ({ tours }) => {
  const count = tours.length
  if (count) {
    return tours.map((url, i) => (
      <tr key={`key-${i}`}>
        <th>Tour {count === 1 ? '' : i + 1}</th>
        <td>
          <a href={url}>Virtual Tour / Slideshow</a>
        </td>
      </tr>
    ))
  }

  return null
}

ToursComp.propTypes = {
  tours: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export const mapState = (state, { ml }) => {
  const listing = getListing(state, ml)
  const tours = listing.get('tours').toJS()
  return { tours: tours.filter(tour => !isBlank(tour)) } // compact
}

export default connect(mapState)(ToursComp)
