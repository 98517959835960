import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import fetch from 'components/listings/listing/actions/fetch'
import { Link } from 'react-router-dom'
import Breadcrumb from 'common/breadcrumb/component'
import Summary from 'components/listings/listing/summary'
import Contact from 'components/listings/listing/contact'
import Schools from 'components/listings/listing/schools'
import setPageTitle from 'components/utils/setPageTitle'
import Resid from 'components/listings/listing/resid'
import Glar from 'components/listings/listing/glar'
import Land from 'components/listings/listing/land'
import RoomSizes from 'components/listings/listing/roomSizes'
import SqFtHKAR from 'components/listings/listing/resid/sqft'
import SqFtGLAR from 'components/listings/listing/glar/sqft'
import LotGlar from 'components/listings/listing/glar/lot'
import BldgGlar from 'components/listings/listing/glar/bldg'
import Disclaimer from 'components/listings/disclaimer'
import RegZ from 'components/listings/regZ'
import PicModal from 'components/listings/picModal'
import LegendModal from 'components/listings/listing/summary/row1/legendModal'
import MapModal from 'components/listings/listing/summary/row1/mapModal'
import css from 'components/listings/listing/styles'

class Listing extends PureComponent {
  static propTypes = {
    fetch: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    listing: PropTypes.shape(),
    loading: PropTypes.bool.isRequired,
  }

  static defaultProps = { listing: undefined }

  componentDidMount() {
    window.scrollTo(0, 0)

    const { fetch, id } = this.props
    fetch(id)
  }

  componentWillUnmount() {
    setPageTitle('')
  }

  render() {
    const { id, listing, loading } = this.props

    if (loading) {
      return (
        <>
          <Breadcrumb>
            <li>For Sale</li>
            <li>{id}</li>
          </Breadcrumb>

          <div>loading...</div>
        </>
      )
    } else if (!listing) {
      return <div>Not found</div>
    }

    const glar = !!listing.get('glar')

    return (
      <>
        <Breadcrumb>
          {glar ? (
            <li>
              <Link to="/louisville-homes-for-sale">
                Louisville Homes for Sale
              </Link>
            </li>
          ) : (
            <li>
              <Link to="/land-for-sale">Land for Sale</Link>
            </li>
          )}
          <li>{listing.get('ci')}</li>
          <li>{listing.get('ad1')}</li>
        </Breadcrumb>

        <div className={css.main}>
          <div className={css.left}>
            <Summary ml={id} show={true} />

            <Contact mls={id} />

            <Schools ml={id} />

            <div className={css.directions}>
              <strong>Directions:</strong> {listing.get('dir')}
            </div>

            {listing.get('dim') && (
              <RoomSizes sizes={listing.get('dim').toJS()} />
            )}

            <SqFtHKAR listing={listing} />
            <SqFtGLAR listing={listing} />

            <LotGlar listing={listing} />
            <BldgGlar listing={listing} />
          </div>

          <div className={css.right}>
            <div className={css.notes}>
              <strong>Realtor's notes:</strong> {listing.get('notes')}
            </div>

            <table className="table table-striped">
              {glar && <Glar ml={id} />}
              {!glar && listing.get('ki') === 0 && <Resid ml={id} />}
              {listing.get('ki') === 1 && <Land ml={id} />}
            </table>
          </div>
        </div>

        {/* <div className={css.comments} id="comments">
          {!listing.get('comment') && 'COMMENTS'}
        </div> */}

        <div>{listing.get('ki') === 0 && <RegZ />}</div>

        <Disclaimer glar={glar} />

        <PicModal />
        <LegendModal />
        <MapModal />
      </>
    )
  }
}

const mapState = ({ listings }, { match }) => {
  const id = match.params.id.split('-')[0]
  const listing = listings.$$byId.get(id)
  const loading = !(listing && listing.get('dir')) // dir doesn't load until show page
  let title = `${id} MLS Number`

  // need NOT FOUND to turn off loading

  if (!loading) {
    title = listing.get('ad1')
    title += ` ${listing.get('ad2') || ''}`
    title += ` ${listing.get('ci')}`
    title += ` KY ${listing.get('zi')}`
  }
  setPageTitle(title.trim())

  return { id, listing, loading }
}

export default connect(mapState, { fetch })(Listing)
