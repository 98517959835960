/* eslint jsx-a11y/label-has-associated-control: 0 */
// labels with .sr-only can't wrap the input because
// they're hidden with CSS so the input will be too
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import types from 'components/people/person/actions'
import onKeyDown from 'common/formUtils/onKeyDown'
import update from 'components/people/person/namePassword/name/edit/update'
import getPerson from 'components/people/person/get'
import validate from 'components/people/person/namePassword/name/validate'
import css from 'components/people/person/namePassword/name/edit/styles'

export const EditComp = ({ handleSubmit, onKeyDown, update }) => (
  <form className={css.form} onSubmit={handleSubmit(update)}>
    <div className={css.fields}>
      <div className={css.field}>
        <label className="sr-only" htmlFor="firstName">
          First Name
        </label>

        <Field
          className="form-control"
          id="firstName"
          name="firstName"
          component="input"
          type="text"
          placeholder="First"
          autoComplete="off"
          autoFocus={true}
          onKeyDown={onKeyDown}
        />
      </div>

      <div className={css.field}>
        <label className="sr-only" htmlFor="lastName">
          Last Name
        </label>

        <Field
          className="form-control"
          id="lastName"
          name="lastName"
          component="input"
          type="text"
          placeholder="Last"
          autoComplete="off"
          onKeyDown={onKeyDown}
        />
      </div>
    </div>

    <button type="submit" className={`btn btn-outline-primary ${css.save}`}>
      Save
    </button>
  </form>
)

EditComp.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
}

export const mapState = state => {
  const person = getPerson(state, state.people.person.id)

  return {
    initialValues: {
      firstName: person.get('first_name'),
      lastName: person.get('last_name'),
    },
    enableReinitialize: true, // values update on state change
  }
}

export const mapDispatch = dispatch => {
  const cb = () => dispatch({ type: types.PERSON_NAME_EDIT_TOGGLE })

  return {
    update: data => dispatch(update(data)),
    onKeyDown: event => onKeyDown(event, cb),
  }
}

export default connect(
  mapState,
  mapDispatch
)(
  reduxForm({
    form: 'PersonNameEdit',
    validate,
  })(EditComp)
)
