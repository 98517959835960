export default {
  ci: 'Elizabethtown',
  co: 'HardinHomes.com',
  dt: 1555286400,
  ki: 1,
  ml: 'MLSfromFactory',
  pi: 1,
  pr: 4375000,
  zi: '40165',
  ad1: '123 Main Street',
  dir: 'Use GPS, dumb-dumb',
  h2o: 'County',
  sew: 'County',
  lat: 37.974763615781,
  pva: 'none',
  str: 'Main Street',
  deed: 'none',
  dist: 10,
  elec: 'Available-On Property',
  long: -86.1148445011139,
  topo: 'Rolling',
  notes: 'These are the public remarks',
  county: 'Meade',
  acre_src: 'Other',
  loc_feat: 'Trees',
  acre_apro: 175.0,
}
