import { normalize } from 'normalizr'
import api from 'components/api'
import * as schema from 'components/listings/actions/schema'
import types from 'components/listings/actions'

const listingsFetch = pathname => dispatch => {
  dispatch({ type: types.LISTINGS_FETCH_START })

  return api
    .get(`${pathname.replace(/\/$/, '')}.json`)
    .then(res =>
      dispatch({
        type: types.LISTINGS_FETCH_SUCCESS,
        data: normalize(res.data, schema.arrayOflistings),
      })
    )
    .catch(error =>
      dispatch({
        type: types.LISTINGS_FETCH_FAILURE,
        status: error.status,
      })
    )
}

export default listingsFetch
