import React, { PureComponent, useRef, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import onClient from 'components/utils/onClient'
import fetch from 'components/listings/actions/fetch'
import Pagination, { paginationData } from 'common/pagination'
import setPageTitle from 'components/utils/setPageTitle'
import css from 'components/listings/styles'
import SearchForm from 'components/listings/search'
import selected from 'components/listings/search/selected'
import Summary from 'components/listings/listing/summary'
import PicModal from 'components/listings/picModal'
import LegendModal from 'components/listings/listing/summary/row1/legendModal'
import MapModal from 'components/listings/listing/summary/row1/mapModal'

export const ListingsComp = props => {
  const {
    recordCountTotal,
    selectedRecords,
    pageRecords,
    location,
    pageCount,
  } = props

  // 1201 so listingWidth set to 551 on server (reset on client)
  const [width, setWidth] = useState(1201)
  const parentRef = useRef(null)
  useEffect(() => {
    const handleResize = () => setWidth(parentRef.current.offsetWidth)

    if (onClient && parentRef.current) {
      handleResize()

      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  })

  // Parent has the 16px padding on each size (1rem)
  // 268 minimum width
  let listingWidth = 551
  const getListingWidth = () => {
    let pxBtwListings = 16

    if (width < 552) {
      listingWidth = width
    } else if (width < 836) {
      const listingSpace = width - pxBtwListings * 1
      listingWidth = listingSpace / 2
    } else if (width < 1120) {
      const listingSpace = width - pxBtwListings * 2
      listingWidth = listingSpace / 3
    } else if (width < 1201) {
      const listingSpace = width - pxBtwListings * 3
      listingWidth = listingSpace / 4
    }
  }

  let listingKind = 'land'
  if (location.pathname === '/louisville-homes-for-sale') {
    listingKind = 'homes'
  }

  getListingWidth()
  return (
    <div ref={parentRef}>
      <p style={{ textAlign: 'center' }}>
        All {listingKind} from all local Realtors. Updated every 60 seconds
        right from the source. It's the <strong>MOST</strong> accurate data
        available.
      </p>

      <div className={css.listings}>
        <SearchForm
          listingCount={selectedRecords.size}
          fullCount={recordCountTotal}
          width={listingWidth}
        />

        {pageRecords.map(l => (
          <Summary key={l.get('ml')} ml={l.get('ml')} width={listingWidth} />
        ))}
      </div>

      <Pagination location={location} pages={pageCount} />

      <PicModal />
      <LegendModal />
      <MapModal />
    </div>
  )
}

ListingsComp.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageCount: PropTypes.number.isRequired,
  pageRecords: ImmutablePropTypes.listOf(PropTypes.shape({}).isRequired)
    .isRequired,
  recordCountTotal: PropTypes.number.isRequired,
  selectedRecords: ImmutablePropTypes.list.isRequired,
}

export class ListingsCont extends PureComponent {
  static propTypes = {
    fetch: PropTypes.func.isRequired,
    goToPage: PropTypes.func.isRequired,
    location: PropTypes.shape({}).isRequired,
    page: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    selectedRecords: ImmutablePropTypes.list.isRequired,
  }

  constructor(props) {
    super(props)

    // fetching so someone can enter at ?page=6 and not redirect to ?page=1
    this.state = { fetching: true }
  }

  componentDidMount() {
    const { fetch } = this.props
    fetch().then(() => this.setState({ fetching: false }))
    this.pageTitleUpdate()
  }

  componentWillUpdate(nextProps) {
    this.pageNumberShouldChange(nextProps)
  }

  componentDidUpdate(prevProps) {
    this.pageShouldScrollToTop(prevProps)
    this.pageTitleShouldUpdate(prevProps)
  }

  componentWillUnmount() {
    setPageTitle('')
  }

  pageNumberShouldChange = nextProps => {
    const { goToPage, selectedRecords } = this.props

    const selectedRecordsChanged = selectedRecords !== nextProps.selectedRecords
    const { fetching } = this.state
    if (!fetching && selectedRecordsChanged) {
      goToPage(1) // selectedRecordsChanged so reset to page 1
    }
  }

  pageShouldScrollToTop = prevProps => {
    const { page } = this.props
    if (prevProps.page !== page) {
      window.scrollTo(0, 0)
    }
  }

  pageTitleShouldUpdate = prevProps => {
    const { page, pageCount } = this.props
    const prevPage = prevProps.page
    const prevPageCount = prevProps.pageCount
    const differentPage = page !== prevPage
    const differentPageCount = pageCount !== prevPageCount

    if (differentPage || differentPageCount) {
      this.pageTitleUpdate()
    }
  }

  pageTitleUpdate = () => {
    const { location, page, pageCount } = this.props

    let text = 'Land for Sale Near Elizabethtown Kentucky'
    if (location.pathname.toLowerCase().includes('louisville')) {
      text = 'Louisville KY Area Homes for Sale'
    }

    if (pageCount !== 0) {
      text = `${text}: page ${page} / ${pageCount}`
    }
    setPageTitle(text)
  }

  render() {
    return <ListingsComp {...this.props} />
  }
}

export const mapState = ({ form, listings }, { location }) =>
  paginationData(selected({ form, listings }), location, 23)

export const mapDispatch = (dispatch, { location }) => {
  return { fetch: () => dispatch(fetch(location.pathname)) }
}

export default connect(mapState, mapDispatch)(ListingsCont)
