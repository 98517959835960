export { signIn } from 'components/auth/actions/signIn'
export { signOut } from 'components/auth/actions/signOut'
export { forgot } from 'components/auth/actions/forgot'
export { resetPw } from 'components/auth/actions/resetPw'

export default {
  SIGN_IN_OUT_START: 'SIGN_IN_OUT_START',

  CLEAR_ERROR_MSG: 'CLEAR_ERROR_MSG',

  FORGOT_START: 'FORGOT_START',
  FORGOT_SUCCESS: 'FORGOT_SUCCESS',
  FORGOT_FAILURE: 'FORGOT_FAILURE',

  RESET_START: 'RESET_START',
  RESET_FAILURE: 'RESET_FAILURE',

  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',

  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',

  MORTGAGE: 'MORTGAGE',
}
