import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Droppable } from 'react-beautiful-dnd'
import LoadingBar from 'common/loading/bar'
import AddrNew from 'components/addresses/new/container'
import DetailForm from 'components/details/list/form'
import MutateBtn from 'components/details/list/mutateBtn'
import css from 'components/details/list/styles'

export const Form = ({ title }) => {
  if (title === 'Addresses') {
    return <AddrNew />
  } else {
    const singularCategory = title.slice(0, -1)
    return <DetailForm category={singularCategory} />
  }
}

export const Items = ({ canManage, children, provided, snapshot }) => {
  const backgroundColor = snapshot.isDraggingOver ? '#ECEFF1' : 'inherit'
  const classNames = `list-group ${canManage ? '' : css.cantManage}`

  return (
    <ul
      className={classNames}
      ref={provided.innerRef}
      style={{ backgroundColor }}
      {...provided.droppableProps}
    >
      {children}
      {provided.placeholder}
    </ul>
  )
}

Items.propTypes = {
  canManage: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  provided: PropTypes.shape({}).isRequired,
  snapshot: PropTypes.shape({}).isRequired,
}

export const ListComp = props => {
  const { canManage, children, mutating, loading, title } = props

  return (
    <div className={css.listWrapper}>
      {canManage && <MutateBtn title={title} />}

      <LoadingBar loading={loading} />

      <Droppable droppableId={title} type={title}>
        {(provided, snapshot) => (
          <Items provided={provided} snapshot={snapshot} canManage={canManage}>
            {children}
          </Items>
        )}
      </Droppable>

      {mutating && <Form title={title} />}
    </div>
  )
}

ListComp.propTypes = {
  canManage: PropTypes.bool.isRequired,
  children: PropTypes.node,
  loading: PropTypes.bool.isRequired,
  mutating: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
}

ListComp.defaultProps = { children: null }

export const mapState = ({ addresses, details }, { canManage, title }) => {
  const type = title.toLowerCase()

  let { loading, mutating } = addresses
  if (type !== 'addresses') {
    loading = details.loading[type]
    mutating = details.mutating[type]
  }
  mutating = canManage && mutating // ensure allowed

  return { loading, mutating }
}

export default connect(mapState)(ListComp)
