import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { modalClose } from 'common/modal/actions'
import Pics from 'components/listings/picModal/pics'
import css from 'components/listings/picModal/styles'

export const modalName = 'lightbox'

export const PicModalComp = ({ isOpen, ml, modalClose }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={modalClose}
    contentLabel="Modal"
    overlayClassName={`modal ${css.wrapper}`}
    className={`modal-dialog ${css.dialog}`}
  >
    <div className={`modal-content ${css.content}`}>
      <button
        type="button"
        className={`${css.closeBtn} btn btn-primary`}
        aria-label="Close"
        onClick={modalClose}
      >
        <span aria-hidden="true">close</span>
      </button>

      <Pics ml={ml} />
    </div>
  </Modal>
)

PicModalComp.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  ml: PropTypes.string,
  modalClose: PropTypes.func.isRequired,
}
PicModalComp.defaultProps = { ml: null }

export const mapState = ({ modal }) => ({
  isOpen: modal.name === modalName,
  ...modal.props,
})

export default connect(
  mapState,
  { modalClose }
)(PicModalComp)
