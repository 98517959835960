import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import getListing from 'components/listings/listing/get'
import { startCase, camelCase } from 'lodash'
import fetch from 'components/schools/actions/fetch'
import css from 'components/listings/listing/styles'

export const SchoolName = ({ link, name }) => {
  name = startCase(camelCase(name))
  return link ? <a href={link}>{name}</a> : name
}
SchoolName.propTypes = {
  link: PropTypes.string,
  name: PropTypes.string,
}
SchoolName.defaultProps = { link: null, name: null }

export const SchoolRating = ({ rating }) => (
  <span
    title="GreatSchools Rating"
    style={{ fontSize: '60%', verticalAlign: 'super' }}
  >
    {` ${rating}/10`}
  </span>
)
SchoolRating.propTypes = { rating: PropTypes.string }
SchoolRating.defaultProps = { rating: null }

export const SchoolRow = ({ school }) => (
  <tr>
    <th>{school.grades || school.label}</th>
    <td>
      <SchoolName link={school.link} name={school.name} />
      {/* {school.rating && <SchoolRating rating={school.rating} />} */}
    </td>
  </tr>
)
const schoolShape = {
  label: PropTypes.string.isRequired,
  id: PropTypes.number,
  name: PropTypes.string,
  mls: PropTypes.string,
  kind: PropTypes.string,
  rating: PropTypes.string,
  grades: PropTypes.string,
  link: PropTypes.string,
}
SchoolRow.propTypes = {
  school: PropTypes.shape({
    elem: PropTypes.shape(schoolShape),
    midd: PropTypes.shape(schoolShape),
    high: PropTypes.shape(schoolShape),
  }).isRequired,
}

export const SchoolVerify = ({ county }) => {
  county = county.toLowerCase()
  if (['hardin', 'meade'].includes(county)) {
    const num = county === 'hardin' ? '270-769-8800' : '270-422-7500'
    return (
      <tr>
        <th>Verify:</th>
        <td>{num}</td>
      </tr>
    )
  }

  return null
}
SchoolVerify.propTypes = { county: PropTypes.string.isRequired }

export const SchoolsComp = ({ county, schools, type }) => (
  <table className={`table ${css.school}`}>
    <tbody>
      <tr>
        <th>School Type:</th>
        <td>{type}</td>
      </tr>
      {Object.entries(schools).map(([key, school]) => (
        <SchoolRow key={key} school={school} />
      ))}
      <SchoolVerify county={county} />
    </tbody>
  </table>
)

SchoolsComp.propTypes = {
  county: PropTypes.string.isRequired,
  schools: PropTypes.shape({ kind: PropTypes.string }).isRequired,
  type: PropTypes.string,
}
SchoolsComp.defaultProps = { type: null }

export class SchoolCont extends PureComponent {
  static propTypes = {
    fetch: PropTypes.func.isRequired,
    isBlank: PropTypes.bool.isRequired,
  }

  componentDidMount() {
    const { fetch } = this.props
    fetch()
  }

  render() {
    const { isBlank } = this.props
    if (isBlank) {
      return null
    } else {
      return <SchoolsComp {...this.props} />
    }
  }
}

export const schoolData = (state, $$listing) => {
  const schools = {
    elem: { label: 'Elementary' },
    midd: { label: 'Middle' },
    high: { label: 'High' },
  }

  const {
    schools: { $$byId, $$byMls },
  } = state

  // schools fetched
  if ($$byMls.size) {
    Object.keys(schools).map(kind => {
      const school = $$listing.get(kind)
      if (school && school.toLowerCase() !== 'other') {
        const id = $$byMls.get(`${kind}-${school.toUpperCase()}`)
        if (id) {
          schools[kind] = {
            ...schools[kind],
            ...$$byId.get(String(id)).toJS(),
          }
        }
      } else {
        delete schools[kind]
      }
    })
  }

  return schools
}

export const mapState = (state, { ml }) => {
  const $$listing = getListing(state, ml)
  const county = $$listing.get('county')
  const schools = schoolData(state, $$listing)
  const type = $$listing.get('sch_typ')

  const correctCounty = !['hardin', 'meade'].includes(county.toLowerCase())
  const isBlank = correctCounty && Object.keys(schools).length === 0 && !type

  return {
    county,
    isBlank,
    schools,
    type,
  }
}

export default connect(mapState, { fetch })(SchoolCont)
