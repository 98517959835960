import { combineReducers } from 'redux'
import authTypes from 'components/auth/actions'
import addresses from 'components/addresses/reducers'
import agents from 'components/agents/reducers'
import auth from 'components/auth/reducers'
import bah from 'components/bah/reducers'
import comments from 'common/comments/reducers'
import counties from 'components/counties/reducers'
import details from 'components/details/reducers'
import guides from 'components/guides/reducers'
import listings from 'components/listings/reducers'
import { reducer as form } from 'redux-form'
import loading from 'common/loading/page/reducers'
import members from 'components/members/reducers'
import modal from 'common/modal/reducers'
import notifications from 'common/notifications/reducers'
import people from 'components/people/reducers'
import schools from 'components/schools/reducers'
import taxes from 'components/taxes/reducers'
import ties from 'components/ties/reducers'

const appReducer = combineReducers({
  addresses,
  agents,
  auth,
  bah,
  comments,
  counties,
  details,
  guides,
  listings,
  form,
  loading,
  members,
  modal,
  notifications,
  people,
  schools,
  taxes,
  ties,
})

const rootReducer = (state, action) => {
  // if (!action.type.includes('@@')) {
  //   console.log('rootReducer action', action)
  // }

  // force 'private' reducers to return their initStates (clear data)
  if (action.type === authTypes.SIGN_OUT_SUCCESS) {
    const initialAppState = appReducer(undefined, { type: 'blah' })
    const rootKeys = Object.keys(initialAppState)

    // clear every reducer by default. Exclude here:
    const exclude = [
      'agents',
      'bah',
      'counties',
      'members',
      'notifications',
      'taxes',
    ]

    rootKeys.forEach(rootKey => {
      const remove = exclude.indexOf(rootKey) < 0
      if (remove) {
        state[rootKey] = undefined
      }
    })
  }

  return appReducer(state, action)
}

export default rootReducer
