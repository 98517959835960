import React from 'react'
import { connect } from 'react-redux'
import LoadingBar from 'common/loading/bar'
import PropTypes from 'prop-types'
import memberSelector from 'components/members/selectTag/selected'
import selectOptions from 'components/members/selectTag/options'
import css from 'components/members/selectTag/styles'

export const Component = props => {
  const { handleChange, loading, selectValue } = props // `props` needed below

  return (
    <div className={css.wrapper}>
      <LoadingBar loading={loading} />

      <label htmlFor="memberId" className={css.label}>
        <select
          id="memberId"
          className="form-control"
          onChange={handleChange}
          value={selectValue}
        >
          {selectOptions(props)}
        </select>
        &nbsp; Team Member
      </label>
    </div>
  )
}

Component.propTypes = {
  handleChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  selectValue: PropTypes.string,
}

Component.defaultProps = { selectValue: null }

export const mapState = state => ({
  isAdmin: !!state.auth.isAdmin,
  members: memberSelector(state),
  loading: state.people.loading.member,
})

export default connect(mapState)(Component)
