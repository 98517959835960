import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import getPerson from 'components/people/person/get'
import BtnDestroyConfirm from 'common/buttons/destroyConfirm'
import { destroy } from 'components/details/actions'
import selected from 'components/details/selected'
import css from 'components/details/destroy/styles'

export const DestroyComp = ({ destroy, destroyable }) => {
  if (destroyable) {
    return (
      <BtnDestroyConfirm action={destroy} cancelPosition="left" css={css} />
    )
  }

  return null
}

DestroyComp.propTypes = {
  destroy: PropTypes.func.isRequired,
  destroyable: PropTypes.bool.isRequired,
}

export const mapState = (state, { id }) => {
  const detail = state.details.$$byId.get(String(id))

  let destroyable = true
  const isEmail = detail.get('category') === 'email'
  if (isEmail) {
    const personId = state.people.person.id
    const $$person = getPerson(state, personId)

    const details = selected(state, $$person, false)
    destroyable = details.emails.length > 1
  }

  return { destroyable }
}

export const mapDispatch = (dispatch, { id }) => ({
  destroy: () => dispatch(destroy(id)),
})

export default connect(
  mapState,
  mapDispatch
)(DestroyComp)
