import { OrderedSet } from 'immutable'
import typesApp from 'components'

export const initState = OrderedSet([])

const membersAllIdsReducer = (state = initState, action) => {
  switch (action.type) {
    case typesApp.HYDRATE: {
      return OrderedSet(action.members.result)
    }

    default: {
      return state
    }
  }
}

export default membersAllIdsReducer
