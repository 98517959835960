import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import getPerson from 'components/people/person/get'
import css from 'common/navMain/account/styles'

export const AccountLink = ({ path, text }) => (
  <a href={path} className={css.link}>
    {text}
  </a>
)

AccountLink.propTypes = {
  path: PropTypes.string,
  text: PropTypes.string,
}

AccountLink.defaultProps = {
  path: '/sign-in',
  text: 'Sign In',
}

const text = (state, id) => {
  const $$person = getPerson(state, id)
  const first = $$person.get('first_name')
  const last = $$person.get('last_name')
  return first || last || 'Account'
}

export const mapState = state => {
  const { id } = state.auth
  if (id) {
    return {
      text: text(state, id),
      path: '/me',
    }
  }

  return {} // not signed in
}

export default connect(mapState)(AccountLink)
