import getMember from 'components/members/get'
import getAgentFirstName from 'components/agents/getFirstName'

const getFirstName = (state, id) => {
  let firstName
  const member = getMember(state, id)
  if (member) {
    const agentId = member.get('agent')
    firstName = getAgentFirstName(state, agentId)
  }

  return firstName
}

export default getFirstName
