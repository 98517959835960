import { capitalize } from 'lodash'
import typesApp from 'components'
import types from 'components/auth/actions'

const spreadRoles = (roles = []) => {
  const result = {}

  roles.map(role => {
    result[`is${capitalize(role)}`] = true
  })

  if (result.isAdmin) {
    result.isManager = true
  }

  if (result.isManager) {
    result.isMember = true
  }

  return result
}

export const initState = {}

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case typesApp.HYDRATE: {
      if (action.data.verifyEmail) {
        return action.data
      }

      if (action.data.pwReset) {
        return action.data
      }

      return state
    }

    case types.CLEAR_ERROR_MSG: {
      // removes property from object // https://tinyurl.com/yyecp7qk
      const { pwReset, ...stateWithoutError } = state
      return stateWithoutError
    }

    case types.MORTGAGE: {
      let mortgage = {
        rate: action.data.mortgage,
        term: 30,
        down: 0,
      }

      const { user } = action.data
      if (user && user.mortgage) {
        mortgage = {
          ...mortgage,
          ...user.mortgage,
        }
      }

      return {
        ...state,
        mortgage,
      }
    }

    case types.SIGN_IN_SUCCESS: {
      const { result, entities } = action.data

      // no auth data (signed out)
      if (!entities) {
        return state
      }

      const id = result
      let { roles } = entities.people[id]

      return {
        ...state,
        id,
        ...spreadRoles(roles),
      }
    }

    // SIGN_OUT_SUCCESS is handled by 'components/reducers'
    default: {
      return state
    }
  }
}

export default authReducer
