import React from 'react'
import PropTypes from 'prop-types'

const ErrorMessage = ({ message }) => (
  <span>
    &nbsp;
    <span style={{ display: 'inline' }} className="invalid-feedback">
      {message}
    </span>
  </span>
)

ErrorMessage.propTypes = { message: PropTypes.string.isRequired }

export default ErrorMessage
