/* eslint react/no-array-index-key: 0 */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import getPerson from 'components/people/person/get'

export const TokensComp = ({ tokens }) => {
  if (!tokens) {
    return null
  }

  // "Using Maps as children is not yet fully supported"
  // use .entrySeq().map(([key, val]) since .map returns array of maps
  // https://goo.gl/XLiZQQ
  const list = tokens.entrySeq().map(([key, val]) => {
    const createdAt = val.get('created_at')
    const lastUsedAt = val.get('last_used_at')
    const history = val.get('date_history')
    const ipHistory = val.get('ip_history').toJS()
    const userAgent = val.get('user_agent')

    const ips = ipHistory.map((ip, i) => [
      i > 0 && ', ',
      <a
        key={`${ip}-${i}`}
        target="_blank"
        rel="noopener noreferrer"
        href={`http://www.ip2location.com/${ip}`}
      >
        {ip}
      </a>,
    ])

    return (
      <li className="list-group-item" key={key}>
        <div>
          <b>Signed-in:</b> {createdAt}
        </div>
        <div>
          <b>Last used:</b> {lastUsedAt}
        </div>
        <div>
          <b>Activity (10 max):</b> {history.join(', ')}
        </div>
        <div>
          <b>IP history (7 max):</b> {ips}
        </div>
        <div>{userAgent}</div>
      </li>
    )
  })

  return (
    <>
      <h4 style={{ marginTop: '1rem' }}>Signed-in Devices: {tokens.size}</h4>
      <ol className="list-unstyled">{list}</ol>
    </>
  )
}

TokensComp.propTypes = {
  tokens: ImmutablePropTypes.contains({
    created_at: PropTypes.string,
    date_history: PropTypes.arrayOf(PropTypes.string),
    ip_history: PropTypes.arrayOf(PropTypes.string),
    last_used_at: PropTypes.string,
    user_agent: PropTypes.string,
  }),
}

TokensComp.defaultProps = { tokens: null }

export const mapState = state => {
  const { id } = state.people.person
  const person = getPerson(state, id)

  return { tokens: person.get('tokens') }
}

export default connect(mapState)(TokensComp)
