import types from 'components/addresses/actions'

export const initState = null

export default function addrCurrentRequestReducer(state = initState, action) {
  switch (action.type) {
    case types.ADDRESS_SET_CURRENT_REQUEST: {
      // Function (and key) doesn't show in Redux DevTools
      return action.data
    }

    default: {
      return state
    }
  }
}
