import api from 'components/api'
import types from 'components/listings/listing/actions'
import { notifyErrors } from 'common/notifications/actions/add'
import { normalize } from 'normalizr'
import listingSchema from 'components/listings/listing/actions/schema'

const listingFetch = id => dispatch => {
  dispatch({ type: types.LISTING_FETCH_START })

  return api
    .get(`/land-for-sale/${id}.json`)
    .then(({ data }) =>
      dispatch({
        type: types.LISTING_FETCH_SUCCESS,
        data: normalize(data, listingSchema),
      })
    )
    .catch(({ response }) => {
      // prevent calling status on undefined
      // TypeError: Cannot read properties of undefined (reading 'status')
      if (response) {
        notifyErrors(dispatch, response)
        dispatch({
          type: types.LISTING_FETCH_FAILURE,
          status: response.status,
        })
      }
    })
}

export default listingFetch
