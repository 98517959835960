import { fromJS } from 'immutable'
import types from 'components/addresses/actions'
import typesApp from 'components'

export const initState = fromJS([])

export default function addressesReducer($$state = initState, action) {
  switch (action.type) {
    case types.SUGGESTIONS_FETCH_SUCCESS: {
      return fromJS(action.suggestions)
    }

    case typesApp.LOCATION_CHANGE:
    case types.SUGGESTIONS_CLEAR:
    case types.SUGGESTIONS_FETCH_ERROR:
    case types.VERIFY_FETCH_SUCCESS: {
      return initState // clear existing
    }

    default: {
      return $$state
    }
  }
}
