/* eslint import/prefer-default-export: 0 */
import _getCurrentPage from 'common/pagination/utils/_getCurrentPage'
import _goToPage from 'common/pagination/utils/_goToPage'
import _getPageCount from 'common/pagination/utils/_getPageCount'
import _getPageRecords from 'common/pagination/utils/_getPageRecords'

export const getCurrentPage = _getCurrentPage
export const goToPage = _goToPage

// records is expected to be Immutable due to the `records.size` call
export const paginationData = (records, location, perPage) => {
  const page = _getCurrentPage(location)

  return {
    selectedRecords: records, // all selected (not just page)
    goToPage: _goToPage,
    page,
    pageCount: _getPageCount(records.size, perPage),
    pageRecords: _getPageRecords({ records, page, perPage }),
    recordCountTotal: records.size,
  }
}
