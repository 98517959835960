import React from 'react'
import { Field } from 'react-final-form'
import css from 'components/taxes/valueBox/styles'

const ValueBoxComp = () => (
  <Field
    initialValue="200000"
    className={`form-control ${css.style}`}
    type="text"
    component="input"
    pattern="[0-9]*"
    autoComplete="off"
    placeholder="Value"
    id="value"
    name="value"
  />
)

export default ValueBoxComp
