import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import PropTypes from 'prop-types'
import update from 'components/addresses/address/addressee/actions/update'
import css from 'components/addresses/address/addressee/form/styles'

export const Component = ({ handleSubmit, update, addrId, auto }) => (
  <form onSubmit={handleSubmit(update.bind(null, addrId))}>
    Create other people, rather than customizing this.
    <Field
      name="addressee"
      component={FieldWithSubmit}
      placeholder={`auto: ${auto}`}
    />
  </form>
)

Component.propTypes = {
  addrId: PropTypes.string.isRequired,
  auto: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
}

Component.defaultProps = {
  auto: '',
}

// Pass mergeProps to connect to override during update testing.
// (so we know if the action is called or not)
const mergeProps = (stateProps, dispatchProps, ownProps) =>
  Object.assign({}, stateProps, dispatchProps, ownProps)

const mapState = (state, props) => {
  const addrId = String(props.addrId)
  const address = state.addresses.$$byId.get(addrId)

  return {
    addrId,
    auto: address.getIn(['addressee', 'auto']),
    enableReinitialize: true, // values update on state change
    initialValues: {
      addressee: address.getIn(['addressee', 'isSet']),
    },
  }
}

export default connect(
  mapState,
  { update },
  mergeProps
)(reduxForm({ form: 'AddressAddresseeUpdate' })(Component))

export const FieldWithSubmit = props => {
  const {
    input,
    meta: { submitting },
    placeholder,
  } = props

  return (
    <>
      <div className="form-group">
        <input
          {...input}
          className="form-control"
          id="addressee"
          type="text"
          placeholder={placeholder}
          disabled={submitting}
        />
      </div>

      <SubmitBtn submitting={submitting} />

      <span className={css.autoText}>{!!input.value && ` ${placeholder}`}</span>
    </>
  )
}

FieldWithSubmit.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    submitting: PropTypes.bool.isRequired,
  }).isRequired,
  placeholder: PropTypes.string.isRequired,
}

export const SubmitBtn = ({ submitting }) => (
  <button
    type="submit"
    className="btn btn-outline-primary"
    disabled={submitting}
  >
    Save
  </button>
)

SubmitBtn.propTypes = {
  submitting: PropTypes.bool.isRequired,
}
