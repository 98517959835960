import api from 'components/api'
import types from 'components/counties/actions'
import { process } from 'app/javascript/components/counties/reducers'

export const url = '/counties.json'

const countiesFetch = () => dispatch => {
  dispatch({ type: types.COUNTIES_FETCH_START })

  return api
    .get(url)
    .then(res =>
      dispatch({
        type: types.COUNTIES_FETCH_SUCCESS,
        data: process(res.data),
      })
    )
    .catch(error =>
      dispatch({
        type: types.COUNTIES_FETCH_FAILURE,
        status: error.status,
      })
    )
}

export default countiesFetch
