import api from 'components/api'
import types from 'components/people/person/actions'

const fetch = hh1Id => dispatch => {
  dispatch({ type: types.PERSON_HH1_START })

  const req = api.get(`/people/hh1/${hh1Id}.json`)

  req
    .then(() => dispatch({ type: types.PERSON_HH1_SUCCESS }))
    .catch(() => dispatch({ type: types.PERSON_HH1_FAILURE }))

  return req // return the promise so it's thenable
}

export default fetch
