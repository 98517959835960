import { combineReducers } from 'redux'
import $$byId from 'components/details/reducers/byId'
import $$allIds from 'components/details/reducers/allIds'
import mutating from 'components/details/reducers/mutating'
import loading from 'components/details/reducers/loading'

export default combineReducers({
  $$byId,
  $$allIds,
  mutating,
  loading,
})
