import { fromJS } from 'immutable'
import typesTaxes from 'components/taxes/actions'

export const initState = fromJS({})

const taxesByCountyReducer = ($$state = initState, action) => {
  switch (action.type) {
    case typesTaxes.TAXES_FETCH_SUCCESS: {
      return fromJS(organize(action.data))
    }

    default: {
      return $$state
    }
  }
}

const organize = ({ entities: { taxes }, result }) =>
  result.reduce((obj, taxId) => {
    const tax = taxes[taxId]
    let countyId = tax.county_id
    if (countyId === null) {
      countyId = 'state'
    }

    if (!obj[countyId]) {
      obj[countyId] = {}
    }

    const code = tax.code
    if (!obj[countyId][code]) {
      obj[countyId][code] = []
    }

    obj[countyId][code].push(tax)

    return obj
  }, {})

export default taxesByCountyReducer
