import typesApp from 'components'
import types from 'components/details/actions'

export const initState = {
  phones: false,
  emails: false,
  dates: false,
  urls: false,
  others: false,
  refocusNeeded: false,
}

const detailMutatingReducer = (state = initState, action) => {
  switch (action.type) {
    case types.DETAILS_MUTATE_TOGGLE: {
      const prev = state[action.category]
      return { ...state, [action.category]: !prev }
    }

    case types.DETAIL_EDIT_TOGGLE: {
      const prev = state[action.category] // true || id
      const refocusNeeded = action.category
      let next = action.id
      if (next === prev) {
        next = true // toggling off so reset
      }

      return { ...state, [action.category]: next, refocusNeeded }
    }

    case types.DETAIL_UPDATE_SUCCESS: {
      return {
        ...state,
        [action.category]: true, // done editing. Show create
        refocusNeeded: action.category,
      }
    }

    case types.DETAIL_CREATE_SUCCESS:
    case types.DETAIL_CREATE_FAILURE: /// focus is not working for this
    case types.DETAIL_DESTROY_SUCCESS: {
      return {
        ...state,
        refocusNeeded: action.category,
      }
    }

    case types.DETAIL_FORM_REINITIALIZED: {
      return {
        ...state,
        refocusNeeded: false,
      }
    }

    case typesApp.LOCATION_CHANGE: {
      return initState
    }

    default: {
      return state
    }
  }
}

export default detailMutatingReducer
