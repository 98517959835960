import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import getListing from 'components/listings/listing/get'
import Photos from 'components/listings/listing/summary/row3/photos'
import css from 'components/listings/listing/summary/row3/styles'

export const Row3Comp = ({ ml, pics, width }) => (
  <div className={`${css.row} pic-row`} style={{ height: `${width * 0.75}px` }}>
    <Photos ml={ml} picArr={pics} />
  </div>
)

Row3Comp.propTypes = {
  ml: PropTypes.string.isRequired,
  pics: PropTypes.arrayOf(PropTypes.string).isRequired,
  width: PropTypes.number.isRequired,
}

export const mapState = (state, { ml }) => {
  const listing = getListing(state, ml)
  return { pics: listing.get('pics').toJS() }
}

export default connect(mapState)(Row3Comp)
