import types from 'components/addresses/actions'
import typesApp from 'components'

export const initState = false

export default function madeSuggestion(state = initState, action) {
  switch (action.type) {
    case types.SUGGESTION_SELECTED: {
      return action.madeSuggestion // true or false
    }

    case types.SUGGESTIONS_CLEAR:
    case typesApp.LOCATION_CHANGE:
    case types.VERIFY_FETCH_SUCCESS: {
      return initState
    }

    default: {
      return state
    }
  }
}
