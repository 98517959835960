import { combineReducers } from 'redux'
import person from 'components/people/person/reducers'
import $$byId from 'components/people/reducers/byId'
import $$allIds from 'components/people/reducers/allIds'
import loading from 'components/people/reducers/loading'

export default combineReducers({
  $$byId,
  $$allIds,
  person,
  loading,
})
