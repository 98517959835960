import { combineReducers } from 'redux'
import $$byId from 'common/comments/reducers/byId'
import $$allIds from 'common/comments/reducers/allIds'
import loading from 'common/comments/reducers/loading'
import stale from 'common/comments/reducers/stale'

export default combineReducers({
  $$byId,
  $$allIds,
  loading,
  stale,
})
