import React from 'react'
import { connect } from 'react-redux'
import { FormSpy } from 'react-final-form'
import PropTypes from 'prop-types'
import selected from 'components/taxes/county/rates/selected'
import ValueBox from 'components/taxes/valueBox'
import Row from 'components/taxes/county/rates/row'
import Sums from 'components/taxes/county/rates/sums'
import { withCommas } from 'components/listings/listing/utils'
import css from 'components/taxes/county/rates/styles'

const RatesComp = ({ countyId, countyName, rates, value, years }) => (
  <ul className="list-group">
    {rates.map(codeArr => (
      <Row
        countyId={countyId}
        countyName={countyName}
        tax={codeArr}
        value={value}
        key={codeArr[0].id}
        years={years}
      />
    ))}

    <li className={`list-group-item ${css.totalRow}`}>
      <FormSpy subscription={{ values: true }}>
        {({ values }) => (
          <>
            <div className={css.valueBox}>
              <ValueBox />
              <div>${withCommas(values.value || 0)}</div>
            </div>

            <Sums values={values} years={years} id={countyId} />
          </>
        )}
      </FormSpy>
    </li>
  </ul>
)

RatesComp.propTypes = {
  countyId: PropTypes.number.isRequired,
  countyName: PropTypes.string.isRequired,
  // all rates for this county
  rates: PropTypes.arrayOf(
    // all rates for this code
    PropTypes.arrayOf(
      // newest to oldest year
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        year: PropTypes.number.isRequired,
        county_id: PropTypes.number,
        kind: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        rate: PropTypes.number.isRequired,
        code: PropTypes.string.isRequired,
      }).isRequired
    )
  ).isRequired,
  years: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
}

export const mapState = ({ taxes }, { county }) => {
  const { rates, years } = selected(taxes, county.id)
  const countyName = county.name.toLowerCase()
  return {
    countyId: county.id,
    countyName,
    form: `taxes.${countyName}`,
    rates,
    years,
  }
}

export default connect(mapState)(RatesComp)
