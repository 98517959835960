import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'
import { modalOpen } from 'common/modal/actions'
import { modalName } from 'components/listings/picModal'
import images from 'components/listings/listing/summary/row3/photos/images'
import css from 'components/listings/listing/summary/row3/photos/styles'

SwiperCore.use([Pagination])

const renderFraction = (currentClass, totalClass) => `
<span class="${css.paginationWrap}">
  <span class="${currentClass}"></span>
  /
  <span class="${totalClass}"></span>
</span>
`

export const PhotosComp = ({ ml, open, picArr }) => {
  const [lazy, setLazy] = useState(true)
  const onSlideChange = swiper => {
    if (lazy && swiper.activeIndex !== 1) {
      setLazy(false)
    }
  }

  let pics = images(ml, picArr, lazy) // initial render
  useEffect(() => {
    pics = images(ml, picArr, lazy)
  })
  // useEffect is componentDidMount, componentDidUpdate, and componentWillUnmount combined
  // https://reactjs.org/docs/hooks-effect.html

  const swiperParams = {
    loop: picArr.length !== 1,
    // shortSwipes: false, // prevents click from being considered a short drag (hard to swipe with finger though)
    keyboard: { enabled: true },
    pagination: { type: 'fraction', renderFraction },
    onSlideChange,
  }

  return (
    <Swiper {...swiperParams}>
      {pics.map(img => (
        <SwiperSlide className={css.slide} key={`img-${img.key}`}>
          <a href={img.props.src} onClick={open}>
            {img}
          </a>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

PhotosComp.propTypes = {
  ml: PropTypes.string.isRequired,
  open: PropTypes.func.isRequired,
  picArr: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export const mapDispatch = (dispatch, { ml }) => ({
  open: event => {
    event.preventDefault()
    dispatch(modalOpen(modalName, { ml }))
  },
})

export default connect(
  null,
  mapDispatch
)(PhotosComp)
