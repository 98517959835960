import { createSelector } from 'reselect'

const conflictingPeople = (ids, people$$byId, memberNamesMap) =>
  ids.map(id => {
    const person = people$$byId.get(String(id))

    const personMemberId = person.get('member_id')
    return {
      id,
      name: `${person.get('first_name')} ${person.get('last_name')}`,
      member: memberNamesMap[personMemberId],
    }
  })

const memberIdsSelector = ({ members }) => members.$$allIds
const members$$byIdSelector = ({ members }) => members.$$byId
const agents$$byIdSelector = ({ agents }) => agents.$$byId
const memberNamesMapSelector = createSelector(
  memberIdsSelector,
  members$$byIdSelector,
  agents$$byIdSelector,
  (memberIds, members$$byId, agents$$byId) =>
    memberIds.reduce(
      (arr, id) => {
        const agentId = members$$byId.get(String(id)).get('agent')
        const agent = agents$$byId.get(String(agentId))
        arr[id] = agent.getIn(['sum', 'first'])
        return arr
      },
      { null: 'No Member' }
    )
)

const people$$byIdSelector = ({ people }) => people.$$byId
const conflictIdsSelector = ({ people }) => people.person.conflictIds

export default createSelector(
  conflictIdsSelector,
  people$$byIdSelector,
  memberNamesMapSelector,
  conflictingPeople
)
