import { OrderedSet } from 'immutable'
import types from 'components/details/actions'
import typesAuth from 'components/auth/actions'
import typesPerson from 'components/people/person/actions'
import typesApp from 'components'

export const initState = OrderedSet([])

const detailsIdsReducer = ($$state = initState, action) => {
  switch (action.type) {
    case types.DETAIL_FETCH_SUCCESS:
    case types.DETAIL_CREATE_SUCCESS:
    case typesAuth.SIGN_IN_SUCCESS:
    case typesPerson.PERSON_FETCH_SUCCESS:
    case typesApp.HYDRATE: {
      const entities = action.data.entities
      if (entities) {
        const desiredObjects = entities.details
        if (desiredObjects) {
          return $$state.union(OrderedSet.fromKeys(desiredObjects))
        }
      }

      return $$state
    }

    case types.DETAIL_DESTROY_SUCCESS: {
      return $$state.subtract([action.id])
    }

    default: {
      return $$state
    }
  }
}

export default detailsIdsReducer
