import { fromJS } from 'immutable'
import typesApp from 'components'
import typesListings from 'components/listings/actions'
import typesListing from 'components/listings/listing/actions'
import { process } from 'app/javascript/components/listings/reducers'

export const initState = fromJS({})

const listingsByIdReducer = ($$state = initState, action) => {
  switch (action.type) {
    case typesApp.HYDRATE: {
      if (action.location.includes('/land-for-sale')) {
        return fromJS(process(action).entities.listings)
      }

      return $$state
    }

    case typesListings.LISTINGS_FETCH_SUCCESS: {
      return fromJS(action.data.entities.listings)
    }

    case typesListing.LISTING_FETCH_SUCCESS: {
      return $$state.merge(action.data.entities.listing)
    }

    default: {
      return $$state
    }
  }
}

export default listingsByIdReducer
