import { OrderedSet } from 'immutable'
import typesApp from 'components'
import typesCounties from 'components/counties/actions'
import { process } from 'app/javascript/components/counties/reducers'

export const initState = OrderedSet([])

const countiesAllIdsReducer = ($$state = initState, action) => {
  switch (action.type) {
    case typesApp.HYDRATE: {
      if (action.location.includes('/counties')) {
        return OrderedSet(process(action.data).result)
      }

      return $$state
    }

    case typesCounties.COUNTIES_FETCH_SUCCESS: {
      return OrderedSet(action.data.result)
    }

    default: {
      return $$state
    }
  }
}

export default countiesAllIdsReducer
