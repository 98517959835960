import api from 'components/api'
import types from 'components/guides/actions'
import { process } from 'app/javascript/components/guides/reducers'

export const url = '/guide.json'

const guidesFetch = () => dispatch => {
  dispatch({ type: types.GUIDES_FETCH_START })

  return api
    .get(url)
    .then(res =>
      dispatch({
        type: types.GUIDES_FETCH_SUCCESS,
        data: process(res.data),
      })
    )
    .catch(error =>
      dispatch({
        type: types.GUIDES_FETCH_FAILURE,
        status: error.status,
      })
    )
}

export default guidesFetch
