import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import getListing from 'components/listings/listing/get'
import css from 'components/listings/listing/summary/row10/styles'

export const Row10Comp = ({ co }) => <div className={css.row}>{co}</div>

Row10Comp.propTypes = { co: PropTypes.string.isRequired }

export const mapState = (state, { ml }) => {
  const listing = getListing(state, ml)
  return { co: listing.get('co') }
}

export default connect(mapState)(Row10Comp)
