import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import getListing from 'components/listings/listing/get'
import Tours from 'components/listings/listing/tours'
import Company from 'components/listings/listing/company'

export const ResidComp = ({ listing }) => {
  const row = (label, field, alt = '') => {
    const value = listing.get(field)

    return (
      <tr>
        <th>{label}</th>
        <td>{value || alt}</td>
      </tr>
    )
  }

  return (
    <tbody>
      {row('REO (foreclosure)', 'reo')}
      {row('Warranty?', 'warr_yn')}
      {listing.get('warr_yn') && row('Warranty Info', 'warr')}
      {row('PVA', 'pva')}
      {row('Deed Book/Page', 'deed')}
      <Tours ml={listing.get('ml')} />
      {row('Subdivision', 'sub')}
      {row('Home Style', 'sty')}
      {row('Garage?', 'gar_yn') /* convert 1/0 to yes/no */}
      {row('Garage Info', 'gar')}
      {row('Basement', 'bs') /* convert 1/0 to yes/no */}
      {row('Basement', 'bsmt')}
      {row('Fireplace #', 'fire_ct')}
      {row('Fireplace Info', 'fire')}
      {row('Roof', 'ruf')}
      {row('Heat', 'heat')}
      {row('Air', 'air')}
      {row('Dining', 'din')}
      {row('Utility', 'util')}
      {row('Other Room(s)', 'oth')}
      {row('Appliances, etc', 'equ')}
      {row('Interior', 'intf')}
      {row('Exterior', 'extf')}
      {row('Exterior Type', 'ext')}
      {row('Lake Features', 'lake')}
      {row('Location', 'loc')}
      {row('Water', 'h2o')}
      {row('Sewer', 'sew')}
      {row('Road', 'road')}
      {row('County', 'county')}
      {row('Possession', 'poss')}
      {row('Road Frontage', 'road_fr')}
      {row('Zoning', 'zone')}
      <Company co={listing.get('co')} />
      {row('Wooded Acres', 'acre_wood')}
      {row('Pasture Acres', 'acre_pastu')}
      {row('Tillable Acres', 'acre_till')}
      {row('Acreage Source', 'acre_src')}
      {row('SqFt Source', 'sqsrc')}
      {row('Foundation', 'foun')}
      {listing.get('ass') && row('HOA Fee Includes', 'ass')}
    </tbody>
  )
}

ResidComp.propTypes = { listing: PropTypes.shape().isRequired }

export const mapState = (state, { ml }) => ({ listing: getListing(state, ml) })

export default connect(mapState)(ResidComp)
