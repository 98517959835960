// import { create, update } from 'components/details/actions'
import { create, update } from 'components/details/actions'

const detailCreateOrUpdate = detail => (dispatch, getState) => {
  const state = getState()
  const personId = state.people.person.id

  const category = `${detail.category}s`
  const creating = state.details.mutating[category] === true
  if (creating) {
    return dispatch(create(detail, personId))
  }

  detail.id = state.details.mutating[category]
  return dispatch(update(detail, personId))
}

export default detailCreateOrUpdate
