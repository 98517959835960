/* inputProps needs to be 'PropTypes.object' (for now) */
/* eslint 'react/forbid-prop-types': 0 */
import React from 'react'
import PropTypes from 'prop-types'
import Autosuggest from 'react-autosuggest'
import css from 'components/addresses/new/styles'

const AddressNewComponent = ({
  getSuggestionValue,
  inputProps,
  onSubmit,
  onSuggestionsFetchRequested,
  onSuggestionSelected,
  renderSuggestion,
  suggestions,
}) => (
  <form onSubmit={onSubmit}>
    <Autosuggest
      alwaysRenderSuggestions={true}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      theme={css}
      suggestions={suggestions}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      onSuggestionSelected={onSuggestionSelected}
      inputProps={inputProps}
    />
  </form>
)

AddressNewComponent.propTypes = {
  getSuggestionValue: PropTypes.func.isRequired,
  inputProps: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
  onSuggestionsFetchRequested: PropTypes.func.isRequired,
  renderSuggestion: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
}

export default AddressNewComponent
