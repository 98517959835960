import Airbrake from 'airbrake-js'

const projectId = 110774
const projectKey = '869e6e77b2f0d3c8359c88c57c74e592'

const prod = process.env.NODE_ENV === 'production'
const fakeAirbrake = { notify: () => {} }
const airbrake = prod ? new Airbrake({ projectId, projectKey }) : fakeAirbrake

export default airbrake
