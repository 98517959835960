import { OrderedSet } from 'immutable'
import typesTaxes from 'components/taxes/actions'

export const initState = OrderedSet([])

const taxesAllIdsReducer = ($$state = initState, action) => {
  switch (action.type) {
    case typesTaxes.TAXES_FETCH_SUCCESS: {
      return OrderedSet(action.data.result)
    }

    default: {
      return $$state
    }
  }
}

export default taxesAllIdsReducer
