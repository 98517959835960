import React from 'react'
import PropTypes from 'prop-types'

export const DisclaimerComp = ({ glar }) => {
  const year = new Date().getFullYear()
  let text = `Listing data is updated every 60 seconds and comes in part from the Internet Data Exchange (IDX) program, © ${year} Heart of Kentucky Multiple Listing Service, Inc. All rights reserved. Information deemed reliable but not guaranteed; confirm before making a decision to purchase. Listings include the name of the brokerage company.`

  if (glar) {
    text = `The data relating to real estate for sale on this web site comes in part from the Internet Data Exchange Program of Metro Search, Inc. Real estate listings held by brokerage firms other than HardinHomes.com LLC are marked with the Internet Data Exchange logo or the Internet Data Exchange thumbnail logo and detailed information about each listing includes the name of the listing broker. Information is provided exclusively for consumers’ personal, non-commercial use; it may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. Data is deemed reliable but is not guaranteed accurate. © ${year} Metro Search, Inc.`
  }

  const style = { margin: '2rem 0' }
  return <div style={style}>{text}</div>
}

DisclaimerComp.propTypes = { glar: PropTypes.bool.isRequired }

export default DisclaimerComp
