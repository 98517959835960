import { createCachedSelector } from 're-reselect'

const $$byCounty = taxes => taxes.$$byCounty
const id = (_, id) => id

const sorted = ($$byCounty, id) => {
  const state = $$byCounty.get('state').toJS()
  // { code1: [{2021}, {2020}], code2: [{2021}, {2020}] }
  const county = $$byCounty.get(String(id)).toJS()
  const countyRates = { ...state, ...county }

  const years = {}
  const ratesByYear = []
  const codes = Object.keys(countyRates).sort()
  codes.forEach(code => {
    // [{2021}, {2020}]
    const allYears = countyRates[code]

    const ratesNewToOld = allYears.sort((a, b) => {
      // collect every year
      years[a.year] = true
      years[b.year] = true

      return b.year - a.year
    })

    ratesByYear.push(ratesNewToOld)
  })

  let kindState = []
  let kindCounty = []
  let kindschool = []
  let kindSpecial = []
  let kindCity = []
  ratesByYear.forEach(codeWithYears => {
    const rate = codeWithYears[0]

    // eslint-disable-next-line default-case
    switch (rate.kind) {
      case 'STATE':
        kindState.push(codeWithYears)
        break
      case 'COUNTY':
        kindCounty.push(codeWithYears)
        break
      case 'SCHOOL':
        kindschool.push(codeWithYears)
        break
      case 'CITY':
        kindCity.push(codeWithYears)
        break
      case 'SPECIAL':
        kindSpecial.push(codeWithYears)
        break
    }
  })

  const sortFn = (a, b) => (a.name > b.name ? -1 : 1)
  const modifiedKindCity = kindCity.sort(sortFn)

  const countyId = id
  const fakeID = Math.round(Date.now() / 1000)
  const noCity = {
    code: String(fakeID),
    id: fakeID,
    county_id: countyId,
    kind: 'CITY',
    name: 'NONE',
    rate: 0,
    year: 2021,
  }
  modifiedKindCity.push([noCity])

  const ratesByKind = kindState
    .sort(sortFn)
    .concat(kindCounty.sort(sortFn))
    .concat(kindschool.sort(sortFn))
    .concat(modifiedKindCity)
    .concat(kindSpecial.sort(sortFn))

  return {
    rates: ratesByKind,
    years: Object.keys(years).sort((a, b) => b - a),
  }
}

export default createCachedSelector($$byCounty, id, sorted)(
  (_, countyId) => countyId
)
