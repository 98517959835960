export const stringCheck = (actual, inputStr) => {
  if (!actual) {
    return false // can't be a match if doesn't exist
  }

  if (inputStr) {
    inputStr = inputStr.trim().toLowerCase()
    return actual.toLowerCase().indexOf(inputStr) > -1
  }

  return true
}

export default stringCheck
