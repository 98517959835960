/* eslint jsx-a11y/label-has-associated-control: 0 */
// labels with .sr-only can't wrap the input because
// they're hidden with CSS so the input will be too
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import create from 'components/listings/listing/contact/question'
import fetchPerson from 'components/people/person/actions/fetch'
import { Form, Field } from 'react-final-form'
import { contactClass, mapState, validate } from 'components/contact/utils'

const Success = () => (
  <h2
    className="text-success"
    style={{
      textAlign: 'center',
      padding: '0.5rem',
      fontSize: '1.4rem',
      background: 'rgb(223, 240, 216)',
    }}
  >
    Message Sent to Realtor
  </h2>
)

const ContactForm = ({ handleSubmit, submitting, errors, touched }) => (
  <form
    onSubmit={handleSubmit}
    action="/contact"
    method="post"
    noValidate={true}
    style={{
      padding: '1rem',
      paddingBottom: 0,
      marginBottom: '1rem',
      border: '1px solid #dee2e6',
    }}
  >
    <h4>Contact Realtor</h4>

    <div style={{ display: 'flex' }}>
      <div className="form-group">
        <label className="sr-only" htmlFor="first_name">
          First Name
        </label>
        <Field
          component="input"
          className="form-control"
          id="first_name"
          name="first_name"
          placeholder="First Name"
          type="text"
        />
      </div>

      <div className="form-group" style={{ marginLeft: '1rem' }}>
        <label className="sr-only" htmlFor="last_name">
          Last Name
        </label>
        <Field
          component="input"
          className="form-control"
          id="last_name"
          name="last_name"
          placeholder="Last Name"
          type="text"
        />
      </div>
    </div>

    <div className={contactClass(touched, errors)}>
      <div className="form-group">
        <label className="sr-only" htmlFor="email">
          Email
        </label>
        <Field
          component="input"
          className="form-control"
          id="email"
          name="email"
          placeholder="Email"
          type="email"
        />
      </div>

      <div className="form-group" style={{ marginBottom: 0 }}>
        <label className="sr-only" htmlFor="phone">
          Phone
        </label>
        <Field
          component="input"
          className="form-control"
          id="phone"
          name="phone"
          placeholder="Phone"
          type="tel"
        />
      </div>
    </div>

    <div className="form-group" style={{ marginTop: '1rem' }}>
      <label className="sr-only" htmlFor="question">
        Message or Question
      </label>
      <Field
        component="textarea"
        className="form-control"
        id="question"
        name="question"
        placeholder="Message or question..."
        type="text"
      />
    </div>

    <div className="form-group">
      <button className="btn btn-primary" type="submit" disabled={submitting}>
        Send
      </button>

      <span style={{ color: '#666', marginLeft: '0.75em' }}>
        {submitting ? 'Sending...' : ''}
      </span>
    </div>
  </form>
)

ContactForm.propTypes = {
  errors: PropTypes.shape({}).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  touched: PropTypes.shape({}).isRequired,
}

export const ContactComp = ({ contactInfo, create, fetchPerson }) => {
  const [fetched, setFetched] = useState(false)

  useEffect(() => {
    const { id } = contactInfo
    if (id && !fetched) {
      setFetched(true)
      fetchPerson(id)
    }
  }, [])

  return (
    <Form
      onSubmit={create}
      subscription={{
        errors: true,
        submitting: true,
        submitSucceeded: true,
        touched: true,
        values: true,
      }}
      initialValues={contactInfo}
      validate={validate}
    >
      {({ errors, handleSubmit, submitSucceeded, submitting, touched }) => {
        return submitSucceeded ? (
          <Success />
        ) : (
          <ContactForm
            errors={errors}
            handleSubmit={handleSubmit}
            submitting={submitting}
            touched={touched}
          />
        )
      }}
    </Form>
  )
}

ContactComp.propTypes = {
  contactInfo: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
  }),
  create: PropTypes.func.isRequired,
  fetchPerson: PropTypes.func.isRequired,
}

ContactComp.defaultProps = {
  contactInfo: PropTypes.shape({
    id: null,
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
  }),
}

const mapDispatch = (dispatch, { mls }) => ({
  create: values => dispatch(create({ ...values, mls, type: 'question' })),
  fetchPerson: id => dispatch(fetchPerson(id)),
})

export default connect(mapState, mapDispatch)(ContactComp)
