import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import personDelete from 'components/people/person/actions/delete'
import BtnDestroyConfirm from 'common/buttons/destroyConfirm'
import isSelf from 'components/people/person/utils/isSelf'
import isMember from 'components/people/person/isMember'
import getPerson from 'components/people/person/get'

export const DeleteComp = ({ hh1Account, hidden, personDelete }) => {
  if (hidden) {
    return null
  }

  let content = (
    <span style={{ color: 'red', opacity: '0.35' }}>
      - Linked HH1 account: Email the URL to Jason asking to have this person
      deleted -
    </span>
  )

  if (!hh1Account) {
    content = <BtnDestroyConfirm action={personDelete} cancelPosition="right" />
  }

  return <div style={{ textAlign: 'center', margin: '1rem' }}>{content}</div>
}

DeleteComp.propTypes = {
  hh1Account: PropTypes.bool.isRequired,
  hidden: PropTypes.bool.isRequired,
  personDelete: PropTypes.func.isRequired,
}

export const mapState = state => {
  const { id } = state.people.person
  const subject = getPerson(state, id) // this is not the current_user
  const hh1Account = !!subject.get('hh1_id')
  const self = isSelf(state.auth.id, id) // can't delete self
  const subjectIsMember = isMember(subject) // members can't be deleted

  return {
    id,
    hidden: self || subjectIsMember,
    hh1Account,
  }
}

export default connect(
  mapState,
  { personDelete }
)(DeleteComp)
