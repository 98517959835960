/* eslint import/prefer-default-export: 0 */
/* eslint camelcase: 0 */
import React from 'react'
import PropTypes from 'prop-types'

export const clickify = ({ category, info, info_formatted }) => {
  let href

  switch (category) {
    case 'email': {
      href = `mailto:${info}`
      break
    }

    case 'url': {
      href = info
      break
    }

    default: {
      return info_formatted
    }
  }

  // emails and urls fall through to this
  return (
    <a target="_blank" rel="noopener noreferrer" href={href}>
      {info_formatted}
    </a>
  )
}

clickify.propTypes = {
  category: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  info_formatted: PropTypes.string.isRequired,
}
