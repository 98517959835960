import React from 'react'
// import { Link } from 'react-router-dom'
import Account from 'common/navMain/account'
import domain from 'components/utils/domain'
import css from 'common/navMain/styles'

// wrapped in <div> so the blue bar can span 100% width
const NavMain = () => (
  <div className={css.navMain}>
    <nav>
      <a href="/">Search</a>
      <a href={`${domain}/pages/31-resources`}>Resources</a>
      <a href={`${domain}/contact-hardinhomes-fort-knox-agents`}>Contact</a>
      <Account />
    </nav>
  </div>
)

export default NavMain
