import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { CSSTransitionGroup } from 'react-transition-group'
import Notification from 'common/notifications/notification'
import css from 'common/notifications/styles'

export const transitionTime = 300

// CSSTransitionGroup key is required (key="1")
export const NotifsComp = ({ ids }) => (
  <div className={css.container}>
    <CSSTransitionGroup
      key="1"
      transitionName={css}
      transitionEnterTimeout={transitionTime} // required
      transitionLeaveTimeout={transitionTime} // required
    >
      {ids.map(id => (
        <Notification key={id} id={id} />
      ))}
    </CSSTransitionGroup>
  </div>
)

NotifsComp.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number).isRequired,
}

export const mapState = ({ notifications }) => ({
  ids: notifications.$$allIds.toJS(),
})

export default connect(mapState)(NotifsComp)
