import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { acreDisplay } from 'components/listings/listing/utils'
import getListing from 'components/listings/listing/get'
import css from 'components/listings/listing/summary/row8/styles'

export const Row8Comp = ({ acre, ml, kind, topo }) =>
  kind === 0 ? (
    <div className={css.row}>
      <div>{acreDisplay(acre)}</div>
      <div>MLS: {ml}</div>
    </div>
  ) : (
    <div className={`${css.row} ${css.rowLand}`}>Terrain: {topo}</div>
  )

Row8Comp.propTypes = {
  acre: PropTypes.number.isRequired,
  kind: PropTypes.number.isRequired,
  ml: PropTypes.string.isRequired,
  topo: PropTypes.string.isRequired,
}

export const mapState = (state, { ml }) => {
  const listing = getListing(state, ml)
  return {
    acre: listing.get('acre_apro'),
    ml: listing.get('ml'),
    kind: listing.get('ki'),
    topo: listing.get('topo') || '',
  }
}

export default connect(mapState)(Row8Comp)
