import { fromJS } from 'immutable'
import types from 'components/schools/actions'

export const initState = fromJS([])

const schoolsAllIdsReducer = ($$state = initState, action) => {
  switch (action.type) {
    case types.SCHOOLS_FETCH_SUCCESS: {
      return fromJS(action.data.result)
    }

    default: {
      return $$state
    }
  }
}

export default schoolsAllIdsReducer
