import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withCommas } from 'components/listings/listing/utils'
import css from 'components/taxes/county/rates/sums/styles'

const SumsComp = ({ sumByYear, years }) => (
  <div className={css.sumRow}>
    {sumByYear.map((sum, i) => (
      <div className={css.sumCell} key={years[i]}>
        <div>{years[i]}</div>

        <div className={css.value}>
          ${withCommas(sum.toFixed(2).replace('.00', ''))}
        </div>

        <div className={css.perMonth}>
          ${withCommas((sum / 12).toFixed(2).replace('.00', ''))}/mo
        </div>
      </div>
    ))}
  </div>
)

SumsComp.propTypes = {
  sumByYear: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  years: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
}

const removeNonDigits = val => String(val).replace(/[^\d\.]/g, '')

const sumsMapState = ({ taxes }, { id, values, years }) => {
  const $$rates = taxes.$$byCounty.get(String(id))
  const sumByYear = Array.from(Array(years.length)).fill(0)
  const value = removeNonDigits(values.value)
  const rateCalc = (rate, value) => rate * (value / 10000)
  const stateCode = '000000'
  let codeRates = taxes.$$byCounty.get('state').toJS()[stateCode]
  const codes = Object.values(values[`county-${id}`]).flat()
  codes.forEach(code => {
    // remove all non-digits (NO CITY will become '')
    const cleanCode = removeNonDigits(code)
    if (cleanCode !== '') {
      if (cleanCode !== stateCode) {
        codeRates = $$rates.get(String(cleanCode)).toJS()
      }

      codeRates.forEach(codeRate => {
        const i = years.indexOf(String(codeRate.year))
        const codeTotal = rateCalc(codeRate.rate, value)
        sumByYear[i] += codeTotal
      })
    }
  })

  return { value, sumByYear }
}

export default connect(sumsMapState)(SumsComp)
