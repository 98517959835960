import { createSelector } from 'reselect'

const id = (_, id) => String(id)
const $$byId = ({ people }) => people.$$byId
const person = (id, $$byId) => $$byId.get(id)
const getPerson = createSelector(
  id,
  $$byId,
  person
)

export default getPerson
