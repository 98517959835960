import types from 'components/people/person/actions'

export const initState = false

const personLoadingReducer = (state = initState, action) => {
  switch (action.type) {
    case types.PERSON_NAME_UPDATE_START:
    case types.PERSON_PASSWORD_UPDATE_START: {
      return true
    }

    case types.PERSON_NAME_UPDATE_SUCCESS:
    case types.PERSON_NAME_UPDATE_FAILURE:
    case types.PERSON_PASSWORD_UPDATE_SUCCESS:
    case types.PERSON_PASSWORD_UPDATE_FAILURE: {
      return false
    }

    default: {
      return state
    }
  }
}

export default personLoadingReducer
