import { fromJS } from 'immutable'
import types from 'components/bah/actions'
import typesApp from 'components'

const processed = ({ data }) => {
  const byRank = {}
  data.map(bah => {
    const rank = bah.rank
    if (!byRank.hasOwnProperty(rank)) {
      byRank[rank] = []
    }
    byRank[rank].push(bah)
  })

  return fromJS(byRank)
}

export const initState = fromJS({})

const bahByRankReducer = ($$state = initState, action) => {
  switch (action.type) {
    case typesApp.HYDRATE: {
      if (action.location.includes('/bah-rates')) {
        return processed(action)
      }

      return $$state
    }

    case types.BAH_RATES_FETCH_SUCCESS: {
      return processed(action)
    }

    default: {
      return $$state
    }
  }
}

export default bahByRankReducer
