import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { modalClose } from 'common/modal/actions'
import css from 'common/modal/styles'

Modal.defaultStyles.overlay.opacity = 0.01
Modal.setAppElement('body')

export const ModalWrapperComp = ({
  children,
  isOpen,
  minWidth,
  modalClose,
  onOpen,
  title,
}) => (
  <Modal
    isOpen={!!isOpen}
    onAfterOpen={onOpen}
    onRequestClose={modalClose}
    contentLabel="Modal"
    overlayClassName={`${css.wrapper} modal`}
    closeTimeoutMS={200} // css transition
    className={`modal-dialog ${css.modalDialog}`}
  >
    <div className={`${css.content} modal-content`} style={{ minWidth }}>
      <div className={`${css.header} modal-header`}>
        <h5 className="modal-title">{title}</h5>

        <button
          type="button"
          className="close"
          style={{ maxWidth: '50px' }}
          aria-label="Close"
          onClick={modalClose}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div className="modal-body">{children}</div>
    </div>
  </Modal>
)

ModalWrapperComp.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  minWidth: PropTypes.string,
  modalClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  title: PropTypes.string.isRequired,
}

ModalWrapperComp.defaultProps = {
  minWidth: '300px',
  onOpen: null,
}

export const mapState = ({ modal }, { name }) => ({
  isOpen: modal.name === name,
})

export default connect(
  mapState,
  { modalClose }
)(ModalWrapperComp)
