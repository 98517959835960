import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import ExtLinkIcon from 'common/icons/extLink'
import selected from 'components/addresses/address/addressee/recipients/selected'
import css from 'components/addresses/address/addressee/recipients/styles'

const RecipientComp = props => {
  const { person, personId } = props

  const memberName = person.member.toLowerCase()
  const memberDisplay = <span className={css.quiet}> - {memberName}</span>

  const thisPerson = String(personId) === String(person.id)

  return (
    <li>
      <Link
        to={`/people/${person.id}`}
        target="_blank"
        rel="noopener noreferrer"
        className={thisPerson ? css.stealthLink : undefined}
      >
        {person.name}
      </Link>

      <ExtLinkIcon />

      {memberDisplay}
    </li>
  )
}

RecipientComp.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  personId: PropTypes.string.isRequired,
}

const Recipient = connect(state => ({ personId: state.people.person.id }))(
  RecipientComp
)

const Recipients = ({ people }) => {
  const recipients = people.map(person => (
    <Recipient key={person.id} person={person} />
  ))

  return (
    <>
      Mailing to:
      <ul>{recipients}</ul>
    </>
  )
}

Recipients.propTypes = {
  people: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default connect(state => ({ people: selected(state) }))(Recipients)
