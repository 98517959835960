import types from 'components/people/person/actions'

export const initState = false

// disables mailToId checkbox while loading
export default function mailToIdLoadingReducer(state = initState, action) {
  switch (action.type) {
    case types.PERSON_MAIL_TO_ID_START: {
      return true
    }

    case types.PERSON_MAIL_TO_ID_SUCCESS:
    case types.PERSON_MAIL_TO_ID_ERROR: {
      return false
    }

    default: {
      return state
    }
  }
}
