export default {
  COMMENTS_LOADING: 'COMMENTS_LOADING',

  COMMENTS_FETCH_START: 'COMMENTS_FETCH_START',
  COMMENTS_FETCH_SUCCESS: 'COMMENTS_FETCH_SUCCESS',
  COMMENTS_FETCH_FAILURE: 'COMMENTS_FETCH_FAILURE',

  COMMENT_CREATE_START: 'COMMENT_CREATE_START',
  COMMENT_CREATE_SUCCESS: 'COMMENT_CREATE_SUCCESS',
  COMMENT_CREATE_FAILURE: 'COMMENT_CREATE_FAILURE',
}
