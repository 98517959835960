import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Destroy from 'components/details/destroy'
import { FaRegEdit } from 'react-icons/fa'
import DragIndicator from '@material-ui/icons/DragIndicator'
import getDetail from 'components/details/detail/get'
import { edit } from 'components/details/actions'
import css from 'components/details/detail/edit/styles'

export const EditBtn = ({ edit, editingThis }) => {
  if (editingThis) {
    const style = { fontStyle: 'italic', color: '#CFD8DC' }
    return <span style={style}>editing</span>
  }

  return (
    <button
      type="button"
      className={`btn btn-sm btn-outline-primary ${css.edit}`}
      onClick={edit}
    >
      <FaRegEdit />
    </button>
  )
}

EditBtn.propTypes = {
  edit: PropTypes.func.isRequired,
  editingThis: PropTypes.bool,
}

EditBtn.defaultProps = { editingThis: false }

export const mapDispatch = (dispatch, { id }) => {
  return { edit: () => dispatch(edit(id)) }
}

const Edit = connect(
  null,
  mapDispatch
)(EditBtn)

// A drag handle must be a HTMLElement and DragIndicator is SVG
const Drag = ({ dragProps }) => (
  <span {...dragProps}>
    <DragIndicator className={css.drag} />
  </span>
)

Drag.propTypes = { dragProps: PropTypes.shape({}).isRequired }

export const EditComp = props => {
  const { draggable, dragProps, id, editingThis, otherEdit } = props

  if (editingThis || otherEdit) {
    return (
      <span style={{ width: '101.1px', textAlign: 'center' }}>
        {editingThis && <Edit id={id} editingThis={editingThis} />}
      </span>
    )
  }

  return (
    <span className={css.wrapper}>
      {draggable && <Drag dragProps={dragProps} />}
      <Edit id={id} />
      <Destroy id={id} />
    </span>
  )
}

EditComp.propTypes = {
  draggable: PropTypes.bool.isRequired,
  dragProps: PropTypes.shape({}),
  editingThis: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  otherEdit: PropTypes.bool.isRequired,
}

EditComp.defaultProps = { dragProps: {} }

export const mapState = (state, { dragProps, id }) => {
  const detail = getDetail(state, id)
  const category = `${detail.get('category')}s`
  const editingId = state.details.mutating[category]
  const editingThis = id === editingId
  const otherEdit = !editingThis && typeof editingId === 'number'

  return {
    draggable: !!dragProps,
    editingThis,
    otherEdit,
  }
}

export default connect(mapState)(EditComp)
