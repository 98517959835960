import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import canManage from 'components/people/person/utils/canManage'
import isSelf from 'components/people/person/utils/isSelf'
import Button from 'components/people/person/namePassword/buttons/button'
import css from 'components/people/person/namePassword/buttons/styles'

export const ButtonsComp = ({ canManage, visible }) => {
  if (canManage) {
    return (
      <div className={css.wrapper}>
        <Button title="Name" />
        {visible && <Button title="Password" />}
      </div>
    )
  }

  return null
}

ButtonsComp.propTypes = {
  canManage: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
}

export const mapState = state => ({
  canManage: canManage(state, state.people.person.id),
  visible: isSelf(state.auth.id, state.people.person.id),
})

export default connect(mapState)(ButtonsComp)
