import { createSelector } from 'reselect'

const pageRecords = (records, page, perPage) => {
  const start = (page - 1) * perPage // page 1 starts at 0th record
  const end = page * perPage
  return records.slice(start, end)
}

const recordSelector = ({ records }) => records
const pageSelector = ({ page }) => page
const perPageSelector = ({ perPage }) => perPage
export default createSelector(
  recordSelector,
  pageSelector,
  perPageSelector,
  pageRecords
)
