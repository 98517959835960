import React from 'react'
import PropTypes from 'prop-types'
import Row1 from 'components/listings/listing/summary/row1'
import Row2 from 'components/listings/listing/summary/row2'
import Row3 from 'components/listings/listing/summary/row3'
import Row4 from 'components/listings/listing/summary/row4'
import Row5 from 'components/listings/listing/summary/row5'
import Row6 from 'components/listings/listing/summary/row6'
import Row7 from 'components/listings/listing/summary/row7'
import Row8 from 'components/listings/listing/summary/row8'
import Row9 from 'components/listings/listing/summary/row9'
import Row10 from 'components/listings/listing/summary/row10'
import css from 'components/listings/listing/summary/styles'

export const SummaryComp = ({ ml, show, width }) => (
  <div className={css.summary} style={{ width: `${width}px` }}>
    <Row1 ml={ml} />
    <Row2 ml={ml} show={show} />
    <Row3 ml={ml} width={width} />
    <Row4 ml={ml} />
    <Row5 ml={ml} />
    <Row6 ml={ml} />
    <Row7 ml={ml} />
    <Row8 ml={ml} />
    <Row9 ml={ml} />
    {!show && <Row10 ml={ml} />}
  </div>
)

SummaryComp.propTypes = {
  ml: PropTypes.string.isRequired,
  show: PropTypes.bool,
  width: PropTypes.number,
}
SummaryComp.defaultProps = {
  show: false,
  width: 330,
}

export default SummaryComp
