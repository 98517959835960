import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import LoadingBar from 'common/loading/bar'

export const LoadingComp = ({ loading }) => <LoadingBar loading={loading} />

LoadingComp.propTypes = { loading: PropTypes.bool.isRequired }

export const mapState = ({ people }) => ({ loading: people.person.loading })

export default connect(mapState)(LoadingComp)
