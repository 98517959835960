const calc = (price, rate, term, down, round = true) => {
  down = convert(down, price)
  const loan = price - down
  let payment = 0

  if (loan > 0) {
    rate = rate / 100 / 12 // (rate here is 5 instead of 0.05)
    const months = term * 12
    payment = (loan * rate) / (1 - (1 + rate) ** -months)
  }

  if (round) {
    payment = Math.round(payment)
  }

  return payment
}

const convert = (down, price) => {
  if (down < 1) {
    return price * down // percentage
  }

  return down
}

export default calc
