import React from 'react'
import PropTypes from 'prop-types'
import { notGlar, withCommas } from 'components/listings/listing/utils'
import css from 'components/listings/listing/glar/styles'

export const Bldg = ({ listing }) => {
  if (notGlar(listing)) {
    return null
  }

  const bldg = listing.get('bldg').toJS()

  const present = Object.values(bldg).filter(value => value) // compact null
  if (present.length === 0) {
    return null
  }

  return (
    <table className={`table ${css.wrapper}`}>
      <tbody>
        <tr className={css.attn}>
          <td colSpan="3" style={{ textAlign: 'center' }}>
            Building Summary
          </td>
        </tr>
        <tr>
          <th>Number</th>
          <td>{bldg.num}</td>
        </tr>
        <tr>
          <th>Maintenance Fee</th>
          <td>{bldg.fee && `${withCommas(bldg.fee)}`}</td>
        </tr>
        <tr>
          <th>Location In Bldg</th>
          <td>{bldg.loc}</td>
        </tr>
        <tr>
          <th>Number of Floors</th>
          <td>{bldg.flrs}</td>
        </tr>
        <tr>
          <th>Parking Spaces Incl.</th>
          <td>{bldg.prkSpce}</td>
        </tr>
        <tr>
          <th>Details</th>
          <td>{bldg.deta}</td>
        </tr>
      </tbody>
    </table>
  )
}
Bldg.propTypes = { listing: PropTypes.shape() }
Bldg.defaultProps = { listing: undefined }

export default Bldg
