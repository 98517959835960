import api from 'components/api'
import history from 'components/history'
import { reset } from 'redux-form'
import { notifyErrors } from 'common/notifications/actions/add'
import { normalize } from 'normalizr'
import * as schema from 'common/comments/actions/schema'
import types from 'common/comments/actions'

const commentCreate = ({ comment }) => dispatch => {
  dispatch({ type: types.COMMENT_CREATE_START })
  const { pathname } = history.location

  return api
    .post(`${pathname}/comments`, { comment })
    .then(res => {
      dispatch(reset('NewComment'))

      dispatch({
        type: types.COMMENT_CREATE_SUCCESS,
        data: normalize(res.data, schema.comment),
      })
    })
    .catch(({ response }) => {
      notifyErrors(dispatch, response)

      dispatch({
        type: types.COMMENT_CREATE_FAILURE,
        status: response.status,
      })
    })
}

export default commentCreate
