import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { verify } from 'components/details/actions'
import Button from 'common/buttons'
import css from 'components/details/detail/verify/styles'

export const BtnVerify = ({ action, classes, text }) => (
  <Button action={action} classes={classes}>
    {text}
  </Button>
)

BtnVerify.propTypes = {
  action: PropTypes.func.isRequired,
  classes: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export const mapState = (_, { detail }) => {
  let text = 'verify'
  let classes = `${css.wrapper} btn btn-sm `
  if (detail.category === 'phone') {
    text = 'confirm'
    classes = classes.concat('btn-outline-secondary')
  } else {
    classes = classes.concat('btn-danger')
  }

  return { text, classes }
}

export const mapDispatch = (dispatch, { detail }) => ({
  action: () => dispatch(verify(detail)),
})

export default connect(
  mapState,
  mapDispatch
)(BtnVerify)
