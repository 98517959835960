import typesGuides from 'components/guides/actions'

export const initState = []

const guidesExpandedReducer = (state = initState, action) => {
  switch (action.type) {
    case typesGuides.GUIDE_TOGGLE: {
      const { id } = action
      if (state.includes(id)) {
        state = state.filter(x => x !== id)
      } else {
        state = [...state, id]
      }

      return state
    }

    case typesGuides.GUIDES_TOGGLE_ALL: {
      return initState
    }

    default: {
      return state
    }
  }
}

export default guidesExpandedReducer
