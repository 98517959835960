import { Schema } from 'normalizr'
import { arrayOfAddresses } from 'components/addresses/actions/schema'
import { arrayOfDetails } from 'components/details/actions/schema'

const person = new Schema('people')

person.define({
  addresses: arrayOfAddresses,
  details: arrayOfDetails,
})

export default person
