import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import getListing from 'components/listings/listing/get'
import Pic from 'components/listings/picModal/pics/pic'

export const PicsComp = ({ num, ml, pics }) =>
  pics.map((id, i) => <Pic num={num} i={i} id={id} key={id} ml={ml} />)

PicsComp.propTypes = {
  num: PropTypes.number,
  picArr: PropTypes.arrayOf(PropTypes.string),
}

PicsComp.defaultProps = {
  num: 0,
  picArr: [],
}

export const mapState = (state, { ml }) => {
  const $$listing = getListing(state, ml)
  const pics = $$listing ? $$listing.get('pics').toJS() : []
  const num = pics.length
  return { num, pics }
}

export default connect(mapState)(PicsComp)
