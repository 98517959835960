import typesTaxes from 'components/taxes/actions'

export const initState = []

const taxesByNameReducer = ($$state = initState, action) => {
  switch (action.type) {
    case typesTaxes.TAXES_FETCH_SUCCESS: {
      return organize(action.data)
    }

    default: {
      return $$state
    }
  }
}

const organize = ({ entities: { taxes }, result }) =>
  result.reduce((obj, taxId) => {
    const tax = taxes[taxId]
    if (tax.kind.toLowerCase() === 'city') {
      obj.push(`${tax.county_id}---${tax.name}`)
    }

    return obj
  }, [])

export default taxesByNameReducer
