import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import LinearProgress from '@material-ui/core/LinearProgress'
import css from 'common/loading/bar/styles'

export const LoadingBarComp = ({ classes, loading }) => {
  if (loading) {
    return <LinearProgress className={`${css.loading} ${classes}`} />
  }

  return <div className={css.notLoading} />
}

LoadingBarComp.propTypes = {
  classes: PropTypes.string,
  loading: PropTypes.bool.isRequired,
}

LoadingBarComp.defaultProps = { classes: null }

class LoadingBarCont extends PureComponent {
  static propTypes = {
    classes: PropTypes.string,
    loading: PropTypes.bool,
    minTimeTrue: PropTypes.number,
  }

  static defaultProps = {
    classes: null,
    loading: false,
    minTimeTrue: 700,
  }

  constructor(props) {
    super(props)

    this.state = {
      loading: props.loading,
      minTimeTrue: props.minTimeTrue,
      trueAt: Date.now(), // seconds
    }
  }

  componentWillReceiveProps(nextProps) {
    const { loading, minTimeTrue, trueAt } = this.state
    if (loading !== nextProps.loading) {
      if (nextProps.loading) {
        this.setState({
          loading: true,
          trueAt: Date.now(),
        })
      } else {
        const min = trueAt + minTimeTrue
        const now = Date.now()
        this.loadTimeout = setTimeout(
          () => this.setState({ loading: false }),
          this.calcTimeout(min, now)
        )
      }
    }
  }

  componentWillUnmount() {
    this.loadTimeout && clearTimeout(this.loadTimeout)
    this.loadTimeout = false
  }

  calcTimeout(min, now) {
    return min < now ? 0 : min - now
  }

  render() {
    const { classes } = this.props
    const { loading } = this.state
    return <LoadingBarComp classes={classes} loading={loading} />
  }
}

export default LoadingBarCont
