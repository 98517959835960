import { fromJS } from 'immutable'
import types from 'components/schools/actions'

export const initState = fromJS({})

const schoolsByMlsReducer = ($$state = initState, action) => {
  switch (action.type) {
    case types.SCHOOLS_FETCH_SUCCESS: {
      const schools = {}
      const values = Object.values(action.data.entities.schools)
      values.forEach(sch => (schools[`${sch.kind}-${sch.mls}`] = sch.id))
      return fromJS(schools)
    }

    default: {
      return $$state
    }
  }
}

export default schoolsByMlsReducer
