import { combineReducers } from 'redux'
import { normalize } from 'normalizr'
import * as schema from 'components/counties/actions/schema'
import $$byId from 'components/counties/reducers/byId'
import $$allIds from 'components/counties/reducers/allIds'

export const process = data => {
  return normalize(data, schema.arrayOfCounties)
}

export default combineReducers({
  $$byId,
  $$allIds,
})
