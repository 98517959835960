export default {
  id: 28,
  info: 'frank@smith.com',
  info_formatted: 'Frank@Smith.com',
  description: 'work',
  admin: null,
  category: 'email',
  row_order: 0,
  asset_id: 1,
  asset_type: 'Person',
}
