export default {
  ADDRESSES_MUTATE_TOGGLE: 'ADDRESSES_MUTATE_TOGGLE',

  ADDRESS_LOADING_START: 'ADDRESS_LOADING_START',

  ADDRESS_UNTIED_FETCH_START: 'ADDRESS_UNTIED_FETCH_START',
  ADDRESS_UNTIED_FETCH_SUCCESS: 'ADDRESS_UNTIED_FETCH_SUCCESS',
  ADDRESS_UNTIED_FETCH_FAILURE: 'ADDRESS_UNTIED_FETCH_FAILURE',

  ADDRESS_MOVE_START: 'ADDRESS_MOVE_START',
  ADDRESS_MOVE_SUCCESS: 'ADDRESS_MOVE_SUCCESS',
  ADDRESS_MOVE_FAILURE: 'ADDRESS_MOVE_FAILURE',

  SUGGESTION_SEARCH_TEXT: 'SUGGESTION_SEARCH_TEXT',
  SUGGESTION_SELECTED: 'SUGGESTION_SELECTED',
  SUGGESTIONS_FETCH_SUCCESS: 'SUGGESTIONS_FETCH_SUCCESS',
  SUGGESTIONS_FETCH_ERROR: 'SUGGESTIONS_FETCH_ERROR',
  SUGGESTIONS_CLEAR: 'SUGGESTIONS_CLEAR',

  VERIFY_FETCH_SUCCESS: 'VERIFY_FETCH_SUCCESS',
  VERIFY_FETCH_ERROR: 'VERIFY_FETCH_ERROR',

  ADDRESS_SET_CURRENT_REQUEST: 'ADDRESS_SET_CURRENT_REQUEST',
}
