/* eslint react/jsx-no-bind: 0 */ /* it's allowed here */
// https://tylermcginnis.com/react-router-pass-props-to-components/
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { notifyErrors } from 'common/notifications/actions/add'

export class PrivateRouteComp extends PureComponent {
  componentWillMount() {
    const { notify, signedIn } = this.props
    if (!signedIn) {
      notify()
    }
  }

  render() {
    const { component: Component, location, signedIn, ...rest } = this.props

    const render = props => {
      if (signedIn) {
        return <Component {...props} />
      }

      return (
        <Redirect to={{ pathname: '/sign-in', state: { from: location } }} />
      )
    }

    return <Route {...rest} render={render} />
  }
}

PrivateRouteComp.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.shape({}).isRequired,
  notify: PropTypes.func.isRequired,
  rest: PropTypes.shape({}),
  signedIn: PropTypes.bool.isRequired,
}

PrivateRouteComp.defaultProps = { rest: {} }

export const mapState = ({ auth }) => ({ signedIn: !!auth.id })

export const mapDispatch = dispatch => ({
  notify: () => notifyErrors(dispatch, { status: 401 }),
})

export default connect(
  mapState,
  mapDispatch
)(PrivateRouteComp)
