import isBlank from 'common/formUtils/validations'

const validate = ({ firstName, lastName }) => {
  const errors = {}

  if (isBlank(firstName) && isBlank(lastName)) {
    const msg = 'Needs a first and/or last name'
    errors.firstName = msg // so field will get error
    errors.lastName = msg // so field will get error
    errors._errors = msg
  }

  return errors
}

export default validate
