import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import getListing from 'components/listings/listing/get'
import css from 'components/listings/listing/summary/row9/styles'

export const Row9Comp = ({ dist }) => (
  <div className={css.row}>
    <div>{dist} Miles to Fort Knox</div>
    {/* <div>
      <a href="#comments">Comment</a>
    </div> */}
  </div>
)

Row9Comp.propTypes = { dist: PropTypes.number.isRequired }

export const mapState = (state, { ml }) => {
  const listing = getListing(state, ml)
  return { dist: listing.get('dist') }
}

export default connect(mapState)(Row9Comp)
