import { createSelector } from 'reselect'

const id = (_, id) => id
const $$byId = ({ listings }) => listings.$$byId
const listing = (id, $$byId) => $$byId.get(String(id))
const getListing = createSelector(
  id,
  $$byId,
  listing
)

export default getListing
