import api from 'components/api'
import { reset } from 'redux-form'
import { notifyErrors } from 'common/notifications/actions/add'
import { normalize } from 'normalizr'
import * as schema from 'components/details/actions/schema'
import types from 'components/details/actions'

const detailCreate = (detail, personId) => dispatch => {
  const category = `${detail.category}s`

  if (!detail.info_formatted && !detail.description) {
    dispatch({
      type: types.DETAILS_MUTATE_TOGGLE,
      category,
    })
  } else {
    dispatch({
      type: types.DETAIL_CREATE_START,
      category,
    })

    const url = `/people/${personId}/details`
    return api
      .post(url, detail)
      .then(res => {
        const formName = `detail-${detail.category}`
        dispatch(reset(formName))

        dispatch({
          type: types.DETAIL_CREATE_SUCCESS,
          category,
          data: normalize(res.data, schema.detail),
        })
      })
      .catch(({ response }) => {
        const custom = {}
        if (response.data.error) {
          custom.text = response.data.error
        }
        notifyErrors(dispatch, response, custom)

        dispatch({
          type: types.DETAIL_CREATE_FAILURE,
          category,
          status: response.status,
        })
      })
  }
}

export default detailCreate
