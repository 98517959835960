import { combineReducers } from 'redux'
import { normalize } from 'normalizr'
import * as schema from 'components/listings/actions/schema'
import $$byId from 'components/listings/reducers/byId'
import $$allIds from 'components/listings/reducers/allIds'
import $$photos from 'components/listings/reducers/photos'

export const process = action => {
  let actionData = action.data // don't mutate action's data
  if (actionData.length === undefined) {
    actionData = [actionData] // since it's just one listing (object)
  }
  return normalize(actionData, schema.arrayOflistings)
}

export default combineReducers({
  $$byId,
  $$allIds,
  $$photos,
})
