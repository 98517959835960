import types from 'components/ties/actions'

export const initState = false

export default function tiesDeletingReducer(state = initState, action) {
  switch (action.type) {
    case types.TIES_DEL_PER_ADDR_START: {
      return action.addressId
    }

    case types.TIES_DEL_PER_ADDR_FAILURE:
    case types.TIES_DEL_PER_ADDR_SUCCESS: {
      return false
    }

    default: {
      return state
    }
  }
}
