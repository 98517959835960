import { combineReducers } from 'redux'
import kind from 'components/people/reducers/loading/kind'
import member from 'components/people/reducers/loading/member'
import deletingTie from 'components/people/reducers/loading/deletingTie'
import mailToId from 'components/people/reducers/loading/mailToId'

export default combineReducers({
  deletingTie,
  kind,
  mailToId,
  member,
})
