import typesApp from 'components'
import types from 'components/details/actions'

export const initState = {
  phones: false,
  emails: false,
  dates: false,
  urls: false,
  others: false,
}

const detailLoadingReducer = (state = initState, action) => {
  switch (action.type) {
    case types.DETAIL_CREATE_START:
    case types.DETAIL_UPDATE_START:
    case types.DETAIL_VERIFY_START:
    case types.DETAIL_MOVE_START:
    case types.DETAIL_DESTROY_START: {
      return { ...state, [action.category]: true }
    }

    case types.DETAIL_CREATE_SUCCESS:
    case types.DETAIL_CREATE_FAILURE:
    case types.DETAIL_UPDATE_SUCCESS:
    case types.DETAIL_UPDATE_FAILURE:
    case types.DETAIL_VERIFY_SUCCESS:
    case types.DETAIL_VERIFY_FAILURE:
    case types.DETAIL_MOVE_SUCCESS:
    case types.DETAIL_MOVE_FAILURE:
    case types.DETAIL_DESTROY_SUCCESS:
    case types.DETAIL_DESTROY_FAILURE: {
      return { ...state, [action.category]: false }
    }

    case typesApp.LOCATION_CHANGE: {
      return initState
    }

    default: {
      return state
    }
  }
}

export default detailLoadingReducer
