import types from 'components/people/person/actions'

export const initState = false

export default function memberLoadingReducer(state = initState, action) {
  switch (action.type) {
    case types.PERSON_MEMBER_ID_START: {
      return true
    }

    case types.PERSON_MEMBER_ID_SUCCESS: {
      return false
    }

    case types.PERSON_MEMBER_ID_FAILURE: {
      return false
    }

    // SIGN_OUT_SUCCESS is handled by 'components/reducers'
    default: {
      return state
    }
  }
}
