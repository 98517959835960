export default {
  id: 22,
  active: true,
  visible: true,
  agent: {
    id: 236,
    sum: {
      first: 'John',
      last: 'Taylor',
      email: 'John@HardinHomes.com',
      phone: '270-765-9345',
    },
  },
}
