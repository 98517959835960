import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import currMemId from 'components/people/search/currMemId'
import { formName } from 'components/people/search'
import opts from 'components/people/person/admin/kindEdit/options'
import css from 'components/people/search/styles'

export const promptVal = 'prompt'

export const Component = ({ hide, input, prompt }) => {
  if (hide) {
    return null
  }

  const options = [
    <option key="prompt" value={promptVal}>
      {prompt}
    </option>,

    <option key={0} value={0}>
      Unset (todo)
    </option>,

    ...opts,
  ]

  const placeholderColor = {}
  const val = input.value
  if (val === promptVal) {
    placeholderColor.color = '#999'
  }

  return (
    <select
      className={`form-control ${css.inline}`}
      style={placeholderColor}
      {...input}
    >
      {options}
    </select>
  )
}

Component.propTypes = {
  hide: PropTypes.bool,
  input: PropTypes.shape({ value: PropTypes.string }).isRequired,
  prompt: PropTypes.string.isRequired,
}

Component.defaultProps = { hide: false }

export const mapState = (state, { meta }) => {
  let hide = false

  // Is form initialized yet?
  const form = state.form[formName]
  if (form && form.values) {
    const notManager = !state.auth.isManager
    if (notManager) {
      hide = form.values.memberId !== currMemId(state)
    }
  }

  // Show blank when select is 'open' (or has focus).
  const prompt = meta.active ? '' : 'Kind'

  return { hide, prompt }
}

export default connect(mapState)(Component)
