import api from 'components/api'
import types from 'components/addresses/actions'
import { notifyErrors } from 'common/notifications/actions/add'
import { normalize } from 'normalizr'
import * as schema from 'components/addresses/actions/schema'

const getVerify = addr => (dispatch, getState) => {
  dispatch({ type: types.ADDRESS_LOADING_START })

  const personId = getState().people.person.id

  let url = `/people/${personId}/addresses`
  let data = { addr }

  return api
    .post(url, data)
    .then(res =>
      dispatch({
        type: types.VERIFY_FETCH_SUCCESS,
        data: normalize(res.data, schema.address),
        personId,
      })
    )
    .catch(({ response }) => {
      const custom = {}
      if (response.status === 404) {
        custom.text = 'Sorry, it seems that address is invalid'
        custom.timeout = 10000
      }
      notifyErrors(dispatch, response, custom)

      dispatch({
        type: types.VERIFY_FETCH_ERROR,
        status: response.status,
      })
    })
}

export default getVerify
