import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { DragDropContext, Draggable } from 'react-beautiful-dnd'
import Address from 'components/addresses/address'
import getPerson from 'components/people/person/get'
import memberOwner from 'components/people/person/memberOwner'
import canManage from 'components/people/person/utils/canManage'
import List from 'components/details/list'
import { move } from 'components/details/actions'
import selected from 'components/details/selected'
import Detail from 'components/details/detail'

export const DetailsComp = ({
  addresses,
  canManage,
  details,
  mutating,
  move,
  seeAll,
}) => {
  const type = 'Addresses'
  const isDragDisabled = !canManage || addresses.size < 2 || !mutating[type]
  const addrs = addresses.map((id, index) => (
    <Draggable
      draggableId={id}
      index={index}
      key={id}
      type={type}
      isDragDisabled={isDragDisabled}
    >
      {provided => <Address key={id} addrId={id} provided={provided} />}
    </Draggable>
  ))

  const lists = [
    <List key="addresses" title={type} canManage={canManage}>
      {addrs}
    </List>,
  ]

  let typesEditable = ['Phones', 'Emails']
  if (seeAll) {
    typesEditable = ['Phones', 'Emails', 'Dates', 'URLs', 'Others']
  }

  typesEditable.forEach(type => {
    const typeLower = type.toLowerCase()
    const group = details[typeLower]

    const notMutating = !mutating[typeLower]
    const isDragDisabled = !canManage || group.length < 2 || notMutating

    const list = group.map((detail, index) => (
      <Draggable
        draggableId={detail.id}
        index={index}
        key={detail.id}
        type={typeLower}
        isDragDisabled={isDragDisabled}
      >
        {provided => (
          <Detail
            id={detail.id}
            detail={detail}
            provided={provided}
            type={type}
            typeLower={typeLower}
            canManage={canManage}
          />
        )}
      </Draggable>
    ))

    lists.push(
      <List key={type} title={type} canManage={canManage}>
        {list}
      </List>
    )
  })

  return <DragDropContext onDragEnd={move}>{lists}</DragDropContext>
}

const detailMutatingType = PropTypes.oneOfType([
  PropTypes.bool,
  PropTypes.number,
]).isRequired

DetailsComp.propTypes = {
  addresses: PropTypes.arrayOf(PropTypes.number).isRequired,
  canManage: PropTypes.bool.isRequired,
  details: PropTypes.shape({
    phones: PropTypes.array.isRequired,
    emails: PropTypes.array.isRequired,
    dates: PropTypes.array,
    urls: PropTypes.array,
    others: PropTypes.array,
  }).isRequired,
  move: PropTypes.func.isRequired,
  mutating: PropTypes.shape({
    phones: detailMutatingType,
    emails: detailMutatingType,
    dates: detailMutatingType,
    urls: detailMutatingType,
    others: detailMutatingType,
    Addresses: PropTypes.bool.isRequired,
  }).isRequired,
  seeAll: PropTypes.bool.isRequired,
}

export const mapState = state => {
  const personId = state.people.person.id
  const $$person = getPerson(state, personId)
  const seeAll = memberOwner(state, personId)

  const mutating = {
    ...state.details.mutating,
    Addresses: state.addresses.mutating,
  }
  return {
    addresses: $$person.get('addresses').toJS(),
    canManage: canManage(state, personId), // memberOwner || isSelf
    details: selected(state, $$person, seeAll),
    mutating,
    seeAll, // can't seeAll just because isSelf (`Other` etc)
  }
}

export default connect(
  mapState,
  { move }
)(DetailsComp)
