import { combineReducers } from 'redux'
import id from 'components/people/person/reducers/id'
import conflictIds from 'components/people/person/reducers/conflictIds'
import editing from 'components/people/person/reducers/editing'
import loading from 'components/people/person/reducers/loading'

export default combineReducers({
  id,
  conflictIds,
  editing,
  loading,
})
