import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import types from 'components/listings/listing/actions'
import loading from 'components/listings/listing/summary/row3/photos/images/loading.gif'

export const PicComp = ({ num, i, id, ml, status, updateStatus }) => {
  let srcSet = ''
  let url
  if (id.includes('.jpg')) {
    url = `https://HardinHomes.com/listing-pics/${id}`
    if (status === 'hd') {
      srcSet = url.replace('medium@2x', 'full')
    }
  } else {
    const small = '640x480'
    url = `https://cdn.resize.sparkplatform.com/lou/${small}/true/${id}-o.jpg`

    if (status === 'hd') {
      srcSet = `
        ${url.replace(small, '800x600')} 800w,
        ${url.replace(small, '1024x768')} 1024w,
        ${url.replace(small, '1280x1024')} 1280w,
        ${url.replace(small, '1600x1200')} 1600w,
        ${url.replace(small, '2048x1600')} 2048w
      `
    }
  }

  let src = loading
  if (status) {
    src = url
  }

  return (
    <img
      alt={`${i + 1} of ${num} for MLS listing ${ml}`}
      src={src}
      srcSet={srcSet}
      sizes="auto"
      loading="lazy"
      onLoad={updateStatus}
    />
  )
}

PicComp.propTypes = {
  i: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  ml: PropTypes.string.isRequired,
  num: PropTypes.number.isRequired,
  status: PropTypes.string,
  updateStatus: PropTypes.func.isRequired,
}
PicComp.defaultProps = { status: undefined }

export const mapStateImage = ({ listings }, { id }) => ({
  status: listings.$$photos.get(id),
})

export const mapDispatch = (dispatch, { id }) => ({
  updateStatus: () => {
    dispatch({ id, type: types.LISTING_PHOTO_STATUS_SET })
  },
})

export default connect(
  mapStateImage,
  mapDispatch
)(PicComp)
