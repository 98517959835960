import history from 'components/history'
import { getCurrentPage } from 'common/pagination/utils'

const goToPage = page => {
  page = Number(page)
  const currentPage = getCurrentPage(history.location)
  if (currentPage !== page) {
    history.push({ search: _getSearch(page) })
  }
}

const _getSearch = page => (page === 1 ? '' : `?page=${page}`)

export default goToPage
