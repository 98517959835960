// other ideas
// https://github.com/erikras/react-redux-universal-hot-example/blob/master/src/utils/validation.js

const isBlank = value => {
  // !value (prevents null)
  // !str (prevents '' and ' ')
  const str = String(value).trim()
  if (!value || !str) {
    return true
  }

  return false
}

export default isBlank
