import { createSelector } from 'reselect'
import isBlank from 'common/formUtils/validations'
import { stringCheck } from 'common/formUtils/filters/index'

const $$byId = ({ counties }) => counties.$$byId
const $$allIds = ({ counties }) => counties.$$allIds
const byName = ({ taxes }) => taxes.byName
const location = (_, location) => location

const sorted = ($$byId, $$allIds, byName, location) => {
  let list

  // Wait for XHR fetch to finish so we don't show only Hardin + Meade
  if ($$allIds.size > 0) {
    let hardin
    let meade
    list = []

    if (!isBlank(location)) {
      const counties = []
      $$allIds.forEach(id => {
        const county = $$byId.get(String(id)).toJS()
        counties.push(`${id}---${county.name} County`)
      })

      const names = counties.concat(byName)

      const countyObj = names.reduce((obj, name) => {
        const [id, str] = name.split('---')
        if (stringCheck(str, location)) {
          const county = $$byId.get(String(id)).toJS()
          obj[id] = county
        }

        return obj
      }, {})

      list = Object.values(countyObj)
      list = list.sort((a, b) => (a.name > b.name ? 1 : -1))
    } else {
      $$allIds.reduce((list, id) => {
        const county = $$byId.get(String(id)).toJS()
        const name = county.name

        if (name.toLowerCase() === 'hardin') {
          hardin = county
        } else if (name.toLowerCase() === 'meade') {
          meade = county
        } else {
          list.push(county)
        }

        return list
      }, list)

      list = list.sort((a, b) => (a.name > b.name ? 1 : -1))
      list.unshift(hardin, meade)
    }
  }

  return list || []
}

export default createSelector(
  $$byId,
  $$allIds,
  byName,
  location,
  sorted
)
