import { createSelector } from 'reselect'
import getDetail from 'components/details/detail/get'

const seeAll = (_, __, seeAll) => seeAll
const personDetails = (_, $$person) => $$person.get('details')
const $$byId = ({ details }) => details.$$byId

const detailsTypes = seeAll => {
  const details = {
    phones: [],
    emails: [],
  }

  if (seeAll) {
    details.dates = []
    details.urls = []
    details.others = []
  }

  return details
}

const detailsOrdered = (seeAll, personDetails, $$byId) => {
  const details = detailsTypes(seeAll)

  personDetails.forEach(id => {
    const state = { details: { $$byId } }
    const detail = getDetail(state, id)
    const category = `${detail.get('category')}s`
    const detailTypeAllowed = details[category]

    if (detailTypeAllowed) {
      details[category].push({
        id: detail.get('id'),
        info: detail.get('info'),
        info_formatted: detail.get('info_formatted'),
        description: detail.get('description'),
      })
    }
  })

  return details
}

const getDetails = createSelector(
  seeAll,
  personDetails,
  $$byId,
  detailsOrdered
)

export default getDetails
