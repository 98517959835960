import React from 'react'
import loading from 'components/listings/listing/summary/row3/photos/images/loading.gif'

const images = (ml, pics, lazy) => {
  const count = pics.length

  // url is HH URL or GLAR pic ID
  return pics.map((url, i) => {
    let src = loading
    if (!lazy || i < 2) {
      src = url
    }

    const load = src.includes(loading)
    const hh = src.includes('medium@2x')
    if (!load) {
      if (hh) {
        src = `https://HardinHomes.com/listing-pics/${src}`
      } else {
        src = `https://cdn.resize.sparkplatform.com/lou/640x480/true/${src}-o.jpg`
      }
    }

    // sometimes 0 / 0 displays and scroll is messed up
    const delay = count === 1 || i !== 0 ? 'eager' : 'lazy'
    return (
      <img
        key={url}
        alt={`${i + 1} of ${count} for MLS listing ${ml}`}
        src={src}
        loading={delay}
      />
    )
  })
}

export default images
