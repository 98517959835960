import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import getListing from 'components/listings/listing/get'
// import { open as openLegend } from 'components/listings/listing/summary/row1/legendModal'
import { modalOpen } from 'common/modal/actions'
import { modalName } from 'components/listings/listing/summary/row1/mapModal'
import {
  FaMapMarkerAlt,
  // FaRegThumbsUp,
  // FaRegThumbsDown,
  // FaRegArrowAltCircleDown,
  // FaRegArrowAltCircleUp,
  // FaUndoAlt,
} from 'react-icons/fa'
// import { ImFire } from 'react-icons/im'
import css from 'components/listings/listing/summary/row1/styles'

// export const Row1Comp = ({ openLegend, openMap }) => (
export const Row1Comp = ({ openMap }) => (
  <div className={css.row}>
    <div>
      <a href="#map" onClick={openMap}>
        <FaMapMarkerAlt />
      </a>
    </div>

    {/* <div>
      <a href="#up">
        <FaRegThumbsUp />
      </a>
    </div>

    <div>
      <a href="#dn">
        <FaRegThumbsDown />
      </a>
    </div>

    <div>
      <a
        href="#status"
        onClick={openLegend}
        style={{ cursor: 'help' }}
        title="Click for Legend"
      >
        <FaUndoAlt />
      </a>
    </div> */}
  </div>
)

Row1Comp.propTypes = { openMap: PropTypes.func.isRequired }

export const mapState = (state, { ml }) => {
  const listing = getListing(state, ml)
  return {
    lat: listing.get('lat'),
    long: listing.get('long'),
  }
}

export const mapDispatch = (dispatch, { ml }) => ({
  openMap: event => {
    event.preventDefault()
    dispatch(modalOpen(modalName, { ml }))
  },
  // openLegend: () => dispatch(openLegend),
})

export default connect(
  mapState,
  mapDispatch
)(Row1Comp)
