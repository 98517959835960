import api from 'components/api'
import types from 'components/details/actions'
import { notifyErrors } from 'common/notifications/actions/add'
import { normalize } from 'normalizr'
import { arrayOfDetails } from 'components/details/actions/schema'

const detailsFetch = () => dispatch =>
  api
    .get('/details.json')
    .then(res =>
      dispatch({
        type: types.DETAIL_FETCH_SUCCESS,
        data: normalize(res.data, arrayOfDetails),
      })
    )
    .catch(({ response }) => {
      notifyErrors(dispatch, response)
      dispatch({ type: types.DETAIL_FETCH_FAILURE, status: response.status })
    })

export default detailsFetch
