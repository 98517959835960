import React from 'react'
import notFound from 'components/errors/not-found.jpg'

const ErrorNotFound = () => {
  const imgStyle = {
    maxWidth: '757px',
    width: '100%',
    height: 'auto',
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <img alt="Page Not Found" src={notFound} style={imgStyle} />
    </div>
  )
}

export default ErrorNotFound
