import typesApp from 'components'
import typesAddr from 'components/addresses/actions'
import typesAddressee from 'components/addresses/address/addressee/actions'
import typesAuth from 'components/auth/actions'
import typesBah from 'components/bah/actions'
import typesListing from 'components/listings/listing/actions'
import typesListings from 'components/listings/actions'
import typesPeople from 'components/people/actions'
import typesPerson from 'components/people/person/actions'

export const initState = false

const LoadingPageReducer = (state = initState, action) => {
  switch (action.type) {
    case typesAddr.ADDRESS_UNTIED_FETCH_START:
    case typesAddressee.ADDRESSEE_FETCH_START:
    case typesAddressee.ADDRESSEE_UPDATE_START:
    case typesAuth.SIGN_IN_OUT_START:
    case typesAuth.FORGOT_START:
    case typesAuth.RESET_START:
    case typesBah.BAH_RATES_FETCH_START:
    case typesListing.LISTING_FETCH_START:
    case typesListings.LISTINGS_FETCH_START:
    case typesPeople.PEOPLE_FETCH_START:
    case typesPerson.PERSON_HH1_START:
    case typesPerson.PERSON_CREATE_START:
    case typesPerson.PERSON_FETCH_START: {
      return true
    }

    // SIGN_OUT_SUCCESS: handled by 'components/reducers'
    case typesApp.HYDRATE:
    case typesAddr.ADDRESS_UNTIED_FETCH_SUCCESS:
    case typesAddr.ADDRESS_UNTIED_FETCH_FAILURE:
    case typesAddressee.ADDRESSEE_FETCH_SUCCESS:
    case typesAddressee.ADDRESSEE_FETCH_FAILURE:
    case typesAddressee.ADDRESSEE_UPDATE_SUCCESS:
    case typesAddressee.ADDRESSEE_UPDATE_FAILURE:
    case typesAuth.SIGN_IN_SUCCESS:
    case typesAuth.SIGN_IN_FAILURE:
    case typesAuth.SIGN_OUT_FAILURE:
    case typesAuth.FORGOT_SUCCESS:
    case typesAuth.FORGOT_FAILURE:
    case typesAuth.RESET_FAILURE:
    case typesBah.BAH_RATES_FETCH_SUCCESS:
    case typesBah.BAH_RATES_FETCH_FAILURE:
    case typesListing.LISTING_FETCH_SUCCESS:
    case typesListing.LISTING_FETCH_FAILURE:
    case typesListings.LISTINGS_FETCH_SUCCESS:
    case typesListings.LISTINGS_FETCH_FAILURE:
    case typesPeople.PEOPLE_FETCH_SUCCESS:
    case typesPeople.PEOPLE_FETCH_FAILURE:
    case typesPerson.PERSON_HH1_SUCCESS:
    case typesPerson.PERSON_HH1_FAILURE:
    case typesPerson.PERSON_CREATE_SUCCESS:
    case typesPerson.PERSON_CREATE_FAILURE:
    case typesPerson.PERSON_FETCH_SUCCESS:
    case typesPerson.PERSON_FETCH_FAILURE: {
      return false
    }

    default: {
      return state
    }
  }
}

export default LoadingPageReducer
