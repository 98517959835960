import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import fetch from 'components/bah/actions/fetch'
import { Link } from 'react-router-dom'
import Breadcrumb from 'common/breadcrumb/component'
import Row from 'components/bah/row'
import setPageTitle from 'components/utils/setPageTitle'
import css from 'components/bah/styles'

export const Component = ({ rank, rates, title, year }) => {
  let firstBreadcrumb = `${year} Fort Knox KY BAH Rates`

  // show page
  if (rank) {
    firstBreadcrumb = <Link to="/bah-rates">{firstBreadcrumb}</Link>
  }

  const rows = rates.map(bah => {
    const key = `${bah.get('rank')}${bah.get('year')}`
    return <Row key={key} bah={bah} rank={rank} />
  })

  return (
    <>
      <Breadcrumb>
        <li>{firstBreadcrumb}</li>
        {rank && <li>Rank: {rank}</li>}
      </Breadcrumb>

      <div className={css.wrapper}>
        <h1>{title}</h1>

        <div className={css.videoTitle}>
          <strong>
            VIDEO: Rate Protection?
            <br />
            Your Fort Knox BAH rate can go up but&nbsp;not&nbsp;down&nbsp;(1:58)
          </strong>
        </div>

        <div className={css.videoWrapper}>
          <iframe
            title="BAH Rate Protection"
            src="//www.youtube-nocookie.com/embed/6v5aqP7nr-A?rel=0"
            frameBorder="0"
            allowFullScreen={true}
          />
        </div>

        {rank && <h3>Rank: {rank}</h3>}

        <table className={`table table-striped ${css.table}`}>
          <thead>
            <tr>
              <th>Year</th>
              {!rank && <th>Rank</th>}
              <th>
                With
                <br />
                Dependents
              </th>
              <th>
                Without
                <br />
                Dependents
              </th>
            </tr>
          </thead>

          <tbody>{rows}</tbody>
        </table>

        <p>{`${year +
          1} Fort Knox BAH rates will be posted when they're available`}</p>
      </div>
    </>
  )
}

Component.propTypes = {
  rank: PropTypes.string,
  rates: ImmutablePropTypes.listOf(
    ImmutablePropTypes.contains({ rank: PropTypes.string.isRequired })
  ).isRequired,
  title: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
}

Component.defaultProps = { rank: null }

export class Container extends PureComponent {
  static propTypes = {
    fetch: PropTypes.func.isRequired,
    rank: PropTypes.string,
    rates: ImmutablePropTypes.listOf(PropTypes.shape({})).isRequired,
    title: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired,
  }

  static defaultProps = { rank: null }

  componentDidMount() {
    const { fetch, title } = this.props
    setPageTitle(title)
    fetch()
  }

  componentWillUnmount() {
    setPageTitle('')
  }

  render() {
    return <Component {...this.props} />
  }
}

export const mapState = ({ bah }, { match }) => {
  // set default for rates (bah_rates#index)
  const year = bah.all.get(0).get('year')
  let title = `${year} BAH Rates Fort Knox Kentucky with history`
  const returnObj = { rates: bah.all, year }

  // Check if this is the bah_rates#show page
  const { params } = match
  if (params.hasOwnProperty('id')) {
    const { id } = params
    // only keep characters before the first hyphen
    const rank = id.split('-')[0].toUpperCase()
    returnObj.rank = rank
    returnObj.rates = bah.byRank.get(rank)
    title = `${rank} BAH Rates Fort Knox Kentucky ${year} with history`
  }

  returnObj.title = title
  return returnObj
}

export default connect(mapState, { fetch })(Container)
