import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import transit from 'transit-immutable-js'
import { hydrate } from 'components'
import onClient from 'components/utils/onClient'
import reducers from 'components/reducers'

const storeCreator = props => {
  const thunkMiddleware = composeWithDevTools(applyMiddleware(thunk))

  const state = startingState() || {}
  const store = createStore(reducers, state, thunkMiddleware)
  const emptyState = Object.keys(state).length === 0
  if (emptyState) {
    // happens on server OR on Client when not prerendering
    store.dispatch(hydrate(props))
  }

  return store
}

const startingState = () => {
  if (onClient) {
    // console.log('******** on client ********')
    const propsElement = document.getElementsByTagName('div')[0]
    propsElement.removeAttribute('data-react-props') // remove from DOM (theft)

    // no element unless prerendering on server
    const stateElement = document.querySelectorAll('[data-store]')[0]
    if (stateElement) {
      const stateString = stateElement.getAttribute('data-store')
      const state = transit.fromJSON(stateString)
      stateElement.removeAttribute('data-store') // remove from DOM (theft)
      return state
    }
  }
}

export default storeCreator
