import getPerson from 'components/people/person/get'

const currMemId = state => {
  const person = getPerson(state, state.auth.id)
  if (person) {
    return String(person.get('member_id'))
  }
}

export default currMemId
