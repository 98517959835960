import { normalize } from 'normalizr'
import { arrayOfMembers } from 'components/members/actions/schema'
import arrayOfPeopleSchema from 'components/people/actions/schema'
import personSchema from 'components/people/person/actions/schema'
import typesAuth from 'components/auth/actions'

export const hydrate = props => dispatch => {
  const { members, mortgage, user } = props

  const action = {
    type: typesApp.HYDRATE,
    ...props,
    data: _processed(props),
    members: normalize(members, arrayOfMembers),
  }
  delete action.user // dispatch separately
  dispatch(action)

  if (user) {
    dispatch({
      type: typesAuth.SIGN_IN_SUCCESS,
      data: normalize(user, personSchema),
    })
  }

  dispatch({
    type: typesAuth.MORTGAGE,
    data: { user, mortgage },
  })
}

const typesApp = {
  HYDRATE: 'HYDRATE',
  LOCATION_CHANGE: 'LOCATION_CHANGE',
}

export const _processed = ({ data, location }) => {
  const me = location === '/me'
  if (me || location.includes('/people')) {
    // data is either [{person}, {person}] or {person} (just one person)
    // convert data to array for people allIds reducer
    data = data.length === undefined ? [data] : data
    return normalize(data, arrayOfPeopleSchema)
  }

  return data || {}
}

export default typesApp
