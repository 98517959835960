import { OrderedSet } from 'immutable'
import typesPeople from 'components/people/actions'
import typesPerson from 'components/people/person/actions'
import typesApp from 'components'
import typesAuth from 'components/auth/actions'

export const initState = OrderedSet([])

const peopleAllIdsReducer = ($$state = initState, action) => {
  switch (action.type) {
    case typesApp.HYDRATE: {
      const entities = action.data.entities
      if (entities && entities.people) {
        return OrderedSet(action.data.result)
      }

      return $$state
    }

    case typesAuth.SIGN_IN_SUCCESS:
    case typesPeople.PEOPLE_MAIL_TO_ID_UPDATE:
    case typesPerson.PERSON_CREATE_SUCCESS: {
      // OrderedSet is always unique (so just `add`)
      return $$state.add(action.data.result)
    }

    case typesPeople.PEOPLE_FETCH_SUCCESS: {
      // replace (vs merge) state because another user could delete a person
      return OrderedSet(action.data.result)
    }

    case typesPerson.PERSON_DELETE_SUCCESS: {
      const id = Number(action.data.result)
      return $$state.subtract([id])
    }

    default: {
      return $$state
    }
  }
}

export default peopleAllIdsReducer
