import api from 'components/api'
import { notifyErrors } from 'common/notifications/actions/add'
import types from 'components/people/person/actions'
import getPerson from 'components/people/person/get'

const personMemberId = event => (dispatch, getState) => {
  const nextId = event.target.value
  const personId = getState().people.person.id
  const person = getPerson(getState(), personId)
  const prevId = person.get('kind')

  // change person now (optimistic update)
  dispatch({
    type: types.PERSON_KIND_ID_START,
    personId,
    kindId: Number(nextId),
  })

  const url = `/people/${personId}`
  return api
    .patch(url, { kind: nextId })
    .then(res => dispatch({ type: types.PERSON_KIND_ID_SUCCESS }))
    .catch(({ response }) => {
      notifyErrors(dispatch, response)
      dispatch({
        type: types.PERSON_KIND_ID_FAILURE,
        personId,
        kindId: prevId,
        status: response.status,
      })
    })
}

export default personMemberId
