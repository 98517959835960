import tiesTypes from 'components/ties/actions'

export const initState = false

export default function deletingTieReducer(state = initState, action) {
  switch (action.type) {
    case tiesTypes.TIES_DEL_PER_ADDR_START: {
      return action.addressId
    }

    default: {
      return state
    }
  }
}
