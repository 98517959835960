export default {
  LISTING_FETCH_START: 'LISTING_FETCH_START',
  LISTING_FETCH_SUCCESS: 'LISTING_FETCH_SUCCESS',
  LISTING_FETCH_FAILURE: 'LISTING_FETCH_FAILURE',

  LISTING_PHOTO_STATUS_SET: 'LISTING_PHOTO_STATUS_SET',
  LISTING_PHOTO_SETUP: 'LISTING_PHOTO_SETUP',

  LISTING_SCHOOL_FETCH_START: 'LISTING_SCHOOL_FETCH_START',
  LISTING_SCHOOL_FETCH_SUCCESS: 'LISTING_SCHOOL_FETCH_SUCCESS',
  LISTING_SCHOOL_FETCH_FAILURE: 'LISTING_SCHOOL_FETCH_FAILURE',
}
