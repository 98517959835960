import api from 'components/api'
import { notifyErrors } from 'common/notifications/actions/add'
import getPerson from 'components/people/person/get'
import getDetail from 'components/details/detail/get'
import typesDetails from 'components/details/actions'
import typesAddresses from 'components/addresses/actions'

const move = result => (dispatch, getState) => {
  const { destination, source, type, draggableId } = result
  if (destination && destination.index !== source.index) {
    const state = getState()
    const id = String(draggableId)
    const personId = state.people.person.id
    const $$person = getPerson(state, personId)
    const context = { state, dispatch, result, personId, $$person, id }
    return type === 'Addresses' ? handleAddress(context) : handleDetail(context)
  }
}

const handleAddress = ({ dispatch, result, personId, $$person, id }) => {
  const { destination, source, draggableId } = result

  const ids = $$person.get('addresses')

  dispatch({
    type: typesAddresses.ADDRESS_MOVE_START,
    personId,
    ids: ids.delete(source.index).insert(destination.index, draggableId),
  })

  const url = `/ties/0?address_id=${id}&person_id=${personId}`
  return api
    .patch(url, { position: destination.index })
    .then(() => dispatch({ type: typesAddresses.ADDRESS_MOVE_SUCCESS }))
    .catch(({ response }) => {
      notifyErrors(dispatch, response)

      dispatch({
        type: typesAddresses.ADDRESS_MOVE_FAILURE,
        personId,
        ids,
        status: response.status,
      })
    })
}

const handleDetail = ({ state, dispatch, result, personId, $$person, id }) => {
  const { destination, source, draggableId } = result

  const ids = $$person.get('details')
  // `ids` is master list of all ids from every category (email, phone, etc).
  const startIndex = ids.indexOf(draggableId) // where in the master list?

  // `destination.index` and `source.index` are based on one single category.
  // Determine how it moved in the entire `ids` array?
  const change = destination.index - source.index // (i.e. -3 or 3)
  const endIndex = startIndex + change

  const detail = getDetail(state, id)
  // Need category for every action (loading indicator)
  const category = `${detail.get('category')}s`

  dispatch({
    type: typesDetails.DETAIL_MOVE_START,
    category,
    personId, // replace person's detail ids with modified ids
    ids: ids.delete(startIndex).insert(endIndex, draggableId), // immutable
  })

  const url = `/people/${personId}/details/${id}`
  return api
    .patch(url, { row_order_position: destination.index })
    .then(() => dispatch({ type: typesDetails.DETAIL_MOVE_SUCCESS, category }))
    .catch(({ response }) => {
      notifyErrors(dispatch, response)

      dispatch({
        type: typesDetails.DETAIL_MOVE_FAILURE,
        category, // stop loading indicator
        ids,
        personId, // roll back person's detail ids to these original ids
        status: response.status,
      })
    })
}

export default move
