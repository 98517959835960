import React from 'react'

const selectOptions = props => {
  const { isAdmin, members, promptDisabled, prompt, selectValue } = props

  const options = []
  if (prompt && !selectValue) {
    options.push(
      <option
        key="this-is-the-prompt"
        disabled={promptDisabled}
        value="" // since this is just the prompt
      >
        {prompt}
      </option>
    )
  }

  options.push(
    <option key="no-member" value={0}>
      No Member
    </option>
  )

  const inactiveSymbol = 'x '
  members.forEach(member => {
    if (member.isActive || isAdmin) {
      options.push(
        <option key={member.id} value={member.id}>
          {!member.isActive && inactiveSymbol}
          {member.firstName}
        </option>
      )
    }
  })

  return options
}

export default selectOptions
