import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import getListing from 'components/listings/listing/get'
import Company from 'components/listings/listing/company'
import { mdyyyy, withCommas } from 'components/listings/listing/utils'

export const ResidComp = ({ listing }) => {
  const tr = (label, value) => (
    <tr>
      <th>{label}</th>
      <td>{value}</td>
    </tr>
  )

  const row = (label, field, alt = '') => {
    let value = listing.get(field)
    if (value === true) {
      value = 'Yes'
    }

    return tr(label, value || alt)
  }

  const bsmtPlusFinished = () => {
    const bsmtYn = listing.get('bs') ? 'Yes' : 'No'

    if (bsmtYn === 'No') {
      return tr('Basement', bsmtYn)
    }

    const finPct = listing.get('bsmtPctFin')
    return tr('Basement', `${bsmtYn} (${finPct} finished)`)
  }

  const PrOrig = () => {
    const pr = listing.get('pr')
    const orig = listing.get('prOrig')

    if (pr === orig) {
      return null
    }

    return tr('Price', `$${withCommas(pr)} (original: $${withCommas(orig)})`)
  }

  const closetsOrFireplaces = (label, field) => {
    const data = listing.get(field).toJS()

    const one = `1st(${data.one || ' '})`
    const two = `2nd(${data.two || ' '})`
    const three = `3rd(${data.three || ' '})`
    const bsmt = `basement(${data.bsmt || ' '})`

    const content = `${one} ${two} ${three} ${bsmt}`
    return tr(`${label}: ${data.num}`, content)
  }

  const Green = () => {
    const green = listing.get('green').toJS()

    const { deta, hers, ene, bldg, leed } = green
    const final = [deta]
    const others = [
      ['HERS Score: ', hers],
      ['Energy Star Certified', ene],
      ["Nat'l Green Bldg Cert: ", bldg],
      ['LEED Certification: ', leed],
    ]

    others.forEach(set => {
      if (set[1]) {
        final.push(`${set[0]} ${set[1]}`)
      }
    })

    return tr('Green Features', final.join('. '))
  }

  return (
    <tbody>
      {row('Home Style', 'sty')}
      {row('Subdivision', 'sub')}
      {row('Garage?', 'garYn')}
      {row('Spaces', 'garSpc')}
      {row('Garage Details', 'gar')}
      {bsmtPlusFinished()}
      {row('Basement Details', 'bsmt')}
      {row('Laundry 1st Floor', 'laund1st')}
      {tr(
        'Listed',
        `${mdyyyy(listing.get('dt'))} (entered: ${mdyyyy(listing.get('entr'))})`
      )}
      <PrOrig />
      {row('Type', 'typ')}
      {row('Area', 'area')}
      {row('Style', 'sty')}
      {row('Construction', 'const')}
      {row('Heat', 'heat')}
      {row('Air', 'air')}
      {row('Roof', 'ruf')}
      {row('Exterior Features', 'extf')}
      {row('Fencing', 'fenc')}
      {row('1st fl Main Br', 'pbr1st')}
      {row('Num of Rooms', 'numRm')}
      {row('Utilities', 'utils')}
      {row('Municipality', 'muni')}
      <Company co={listing.get('co')} em={listing.get('co_em')} />
      {row('Construction Status', 'constStat')}
      {row('School District', 'schTyp')}
      {row('Preservation District', 'preservDist')}
      {row('M Struct Flood Plain', 'flood')}
      {row('HOA Fee', 'hoaFee')}
      {row('HOA Annual Cost', 'hoaCost')}
      {row('Included in maintenance', 'maintYn')}
      {closetsOrFireplaces('Closets', 'clst')}
      {closetsOrFireplaces('Fireplaces', 'fire')}
      {row('Farm Features', 'farm')}
      {row('Sub/Condo Amenities', 'subConFeat')}
      <Green />
    </tbody>
  )
}

ResidComp.propTypes = { listing: PropTypes.shape().isRequired }

export const mapState = (state, { ml }) => ({ listing: getListing(state, ml) })

export default connect(mapState)(ResidComp)
