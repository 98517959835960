const types = {
  MODAL_OPEN: 'MODAL_OPEN',
  MODAL_CLOSE: 'MODAL_CLOSE',
}
export default types

export const modalOpen = (name, props) => {
  document.body.style.overflow = 'hidden' // prevent body scroll behind modal

  return {
    type: types.MODAL_OPEN,
    name,
    props,
  }
}

export const modalClose = () => {
  document.body.style.overflow = 'unset' // allow body scroll again

  return { type: types.MODAL_CLOSE }
}
