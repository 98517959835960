import React from 'react'
import { Router, StaticRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import onClient from 'components/utils/onClient'
import history from 'components/history'

const UniversalRouter = ({ children, location }) => {
  if (onClient) {
    return <Router history={history}>{children}</Router>
  }

  return (
    <StaticRouter location={location} context={{}}>
      {children}
    </StaticRouter>
  )
}

UniversalRouter.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.string.isRequired,
}

export default UniversalRouter
