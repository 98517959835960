import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withCommas } from 'components/listings/listing/utils'
import calc from 'components/common/paymentCalc'
import getListing from 'components/listings/listing/get'
import css from 'components/listings/listing/summary/row4/styles'

export const Row4Comp = ({ acres, kind, mortgage, price }) => {
  let className
  let right
  if (kind === 0) {
    right = _paymentDisplay(price, mortgage)
  } else {
    className = `text-muted small ${css.rightLand}`
    right = _acreDisplay(price, acres)
  }

  return (
    <div className={css.row}>
      <div>{`$${withCommas(price)}`}</div>
      <div className={className}>{right}</div>
    </div>
  )
}

Row4Comp.propTypes = {
  acres: PropTypes.number.isRequired,
  kind: PropTypes.number.isRequired,
  mortgage: PropTypes.shape({
    down: PropTypes.number.isRequired,
    rate: PropTypes.number.isRequired,
    term: PropTypes.number.isRequired,
  }).isRequired,
  price: PropTypes.number.isRequired,
}

const _acreDisplay = (price, acres) => {
  const pricePerAcre = Math.round(price / acres)
  return `$${withCommas(pricePerAcre)} per acre`
}

const _paymentDisplay = (price, mortgage) => {
  const { rate, term, down } = mortgage
  const pmnt = calc(price, rate, term, down)
  return `${withCommas(pmnt)}/mo*`
}

export const mapState = (state, { ml }) => {
  const mortgage = state.auth.mortgage
  const listing = getListing(state, ml)
  return {
    acres: listing.get('acre_apro'),
    kind: listing.get('ki'),
    mortgage,
    price: listing.get('pr'),
  }
}

export default connect(mapState)(Row4Comp)
