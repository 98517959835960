import types from 'components/people/person/actions'
import typesApp from 'components'

export const initState = false

const personEditReducer = (state = initState, action) => {
  switch (action.type) {
    case types.PERSON_NAME_EDIT_TOGGLE: {
      return state === 'name' ? false : 'name'
    }

    case types.PERSON_PASSWORD_EDIT_TOGGLE: {
      return state === 'password' ? false : 'password'
    }

    case typesApp.LOCATION_CHANGE:
    case types.PERSON_NAME_UPDATE_SUCCESS:
    case types.PERSON_PASSWORD_UPDATE_SUCCESS: {
      return false
    }

    default: {
      return state
    }
  }
}

export default personEditReducer
