import React from 'react'

const options = [
  <option key={1} value={1}>
    Lead (1-way communication)
  </option>,
  <option key={2} value={2}>
    Prospect (2-way communication)
  </option>,
  <option key={3} value={3}>
    Opportunity (HOT)
  </option>,
  <option key={4} value={4}>
    Client (pending)
  </option>,
  <option key={5} value={5}>
    Repeat Client (past)
  </option>,
  <option key={6} value={6}>
    Never (no chance ever)
  </option>,
]

export default options
