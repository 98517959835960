import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { acreDisplay, dateDisplay } from 'components/listings/listing/utils'
import getListing from 'components/listings/listing/get'
import css from 'components/listings/listing/summary/row5/styles'

export const Row5Comp = ({ acre, ba, br, dt, kind }) => {
  let left, right

  if (kind === 0) {
    left = `${br} Beds ${_baths(ba)}`
  } else {
    left = acreDisplay(acre)
    right = <div className={css.rightLand}>{dateDisplay(dt)}</div>
  }

  return (
    <div className={css.row}>
      <div>{left}</div>
      {right}
    </div>
  )
}

Row5Comp.propTypes = {
  acre: PropTypes.number.isRequired,
  ba: PropTypes.number,
  br: PropTypes.number,
  dt: PropTypes.number.isRequired,
  kind: PropTypes.number.isRequired,
}

Row5Comp.defaultProps = { ba: null, br: null }

export const _baths = baths => {
  baths = String(baths).split('.')

  let half = baths[1]
  if (half) {
    const plural = half === '1' ? '' : 's'
    half = ` ${half} Half Bath${plural}`
  }
  half = half || ''

  return `${baths[0]} Full Baths${half}`
}

export const mapState = (state, { ml }) => {
  const listing = getListing(state, ml)
  return {
    acre: listing.get('acre_apro'),
    ba: listing.get('ba'),
    br: listing.get('br'),
    dt: listing.get('dt'),
    kind: listing.get('ki'),
  }
}

export default connect(mapState)(Row5Comp)
