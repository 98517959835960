import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import commentCreate from 'common/comments/actions/create'
import isBlank from 'common/formUtils/validations'
import ErrorMessage from 'common/errorMessage'

export const SubmitBtn = ({ submitting }) => (
  <button
    type="submit"
    className="btn btn-outline-primary"
    disabled={submitting}
  >
    Submit
  </button>
)

SubmitBtn.propTypes = { submitting: PropTypes.bool.isRequired }

export const FieldWithSubmit = props => {
  const {
    input,
    meta: { error, submitting, submitFailed },
  } = props
  const showError = submitFailed && error
  const classes = `form-control ${showError && 'is-invalid'}`

  return (
    <>
      <div className="form-group">
        <textarea
          {...input}
          className={classes}
          id="comment"
          type="text"
          placeholder="Comment"
          disabled={submitting}
        />
      </div>

      <SubmitBtn submitting={submitting} />

      {showError && <ErrorMessage message={error} />}
    </>
  )
}

FieldWithSubmit.propTypes = {
  input: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    submitting: PropTypes.bool.isRequired,
    submitFailed: PropTypes.bool.isRequired,
  }).isRequired,
}

export const Form = ({ commentCreate, handleSubmit }) => (
  <form onSubmit={handleSubmit(commentCreate)}>
    <Field component={FieldWithSubmit} name="comment" />
  </form>
)

Form.propTypes = {
  commentCreate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export const validate = ({ comment }) => {
  const errors = {}

  if (isBlank(comment)) {
    errors.comment = "Comment can't be blank"
  }

  return errors
}

export default connect(
  null,
  { commentCreate }
)(reduxForm({ form: 'NewComment', validate })(Form))
