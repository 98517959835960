import { fromJS, OrderedSet } from 'immutable'
import types from 'components/listings/listing/actions'
import typesModal from 'common/modal/actions'

export const initState = fromJS({ small: OrderedSet([]) })

const listingsPhotosReducer = ($$state = initState, action) => {
  switch (action.type) {
    case typesModal.MODAL_CLOSE: {
      return initState
    }

    case types.LISTING_PHOTO_STATUS_SET: {
      let { id } = action
      let status = $$state.get(id)
      let small = $$state.get('small')

      if (!status) {
        status = 'loading-small'
      } else if (status === 'loading-small') {
        if (small.isEmpty()) {
          status = 'hd'
        }
        small = small.add(id) // queue this for HD download
      } else if (status === 'hd') {
        small = small.delete(id)
        id = small.first()
        status = 'hd'
      }

      return $$state.merge({ small, [id]: status })
    }

    default: {
      return $$state
    }
  }
}

export default listingsPhotosReducer
