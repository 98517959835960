import typesApp from 'components'

export const initState = null

const peopleShowPage = url => url === '/me' || url.includes('/people/')

const personIdReducer = (state = initState, action) => {
  switch (action.type) {
    case typesApp.HYDRATE: {
      if (peopleShowPage(action.location)) {
        return String(action.data.result)
      }

      return state
    }

    // add to state for all components to use
    case typesApp.LOCATION_CHANGE: {
      const { url } = action
      if (peopleShowPage(url)) {
        let id = action.authId
        if (url !== '/me') {
          // replace the first dash and everything after /12-Jo-Le
          id = url.replace(/\/people\//, '').replace(/-.*/, '')
        }
        return String(id)
      }

      return initState
    }

    default: {
      return state
    }
  }
}

export default personIdReducer
