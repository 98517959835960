/* eslint jsx-a11y/no-autofocus: 0 */
/* eslint react/no-did-update-set-state: 0 */
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import isBlank from 'common/formUtils/validations'
import onKeyDown from 'common/formUtils/onKeyDown'
import getDetail from 'components/details/detail/get'
import types, { createOrUpdate } from 'components/details/actions'

export const InfoField = props => {
  const {
    category,
    input,
    meta: { error, submitting, submitFailed },
    onKeyDown,
  } = props
  const showError = submitFailed && error
  const classes = `form-control ${showError && 'is-invalid'}`
  const errMsg = showError ? error : ''

  const keyboardTypes = { Email: 'email', Phone: 'tel', URL: 'url' }

  return (
    <input
      {...input}
      className={classes}
      type={keyboardTypes[category] || 'text'}
      placeholder={`${category} ${errMsg}`}
      autoComplete="off"
      disabled={submitting}
      autoFocus={true}
      onKeyDown={onKeyDown}
      style={{ marginRight: '0.5rem' }}
    />
  )
}

InfoField.propTypes = {
  category: PropTypes.string.isRequired,
  input: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    submitting: PropTypes.bool.isRequired,
    submitFailed: PropTypes.bool.isRequired,
  }).isRequired,
  onKeyDown: PropTypes.func.isRequired,
}

export const SubmitBtn = ({ dirty }) => {
  const type = dirty ? 'primary' : 'outline-secondary'
  const classNames = `btn btn-${type}`

  return (
    <button type="submit" className={classNames} style={{ width: '9rem' }}>
      {dirty ? 'Save' : 'Cancel'}
    </button>
  )
}

SubmitBtn.propTypes = { dirty: PropTypes.bool.isRequired }

export class FormCont extends PureComponent {
  componentDidUpdate() {
    const { type, reinitializeForm, refocusNeeded } = this.props
    if (refocusNeeded === type) {
      reinitializeForm()
    }
  }

  render() {
    const {
      action,
      handleSubmit,
      category,
      dirty,
      onKeyDown,
      refocusNeeded,
      type,
    } = this.props

    if (refocusNeeded === type) {
      return <div style={{ height: '40px' }} /> // no page jump
    }

    return (
      <form
        noValidate={true} // skip HTML5 validation
        onSubmit={handleSubmit(action)}
        style={{ display: 'flex', width: '100%' }}
      >
        <Field
          component={InfoField}
          name="info_formatted"
          onKeyDown={onKeyDown}
          category={category}
        />

        <Field
          className="form-control"
          name="description"
          onKeyDown={onKeyDown}
          component="input"
          type="text"
          placeholder="Label"
          style={{ marginRight: '0.5rem' }}
        />

        <SubmitBtn dirty={dirty} />
      </form>
    )
  }
}

FormCont.propTypes = {
  action: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
  dirty: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  refocusNeeded: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
    .isRequired,
  reinitializeForm: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
}

export const validate = (formData, { category }) => {
  const { description } = formData
  const infoFormatted = formData.info_formatted
  const errors = {}

  if (isBlank(infoFormatted)) {
    if (!isBlank(description)) {
      errors.info_formatted = '(required)'
    }
  } else {
    let pattern
    if (category === 'Email') {
      pattern = '\\S+@\\S+\\.\\S+' // need double escapes (eslint) `//`
    }
    if (category === 'URL') {
      pattern = '\\S+\\.\\S+' // need double escapes (eslint) `//`
    }

    if (!infoFormatted.match(pattern)) {
      errors.info_formatted = '(wrong format)' // "Placeholder" won't show
    }
  }

  return errors
}

export const mapState = (state, { category }) => {
  const type = `${category.toLowerCase()}s`

  const result = {
    form: `detail-${category.toLowerCase()}`,
    refocusNeeded: state.details.mutating.refocusNeeded,
    type,
  }

  const editingId = state.details.mutating[type]
  if (typeof editingId === 'number') {
    const detail = getDetail(state, editingId)

    result.initialValues = {
      description: detail.get('description'),
      info_formatted: detail.get('info_formatted'),
    }

    result.enableReinitialize = true // resets after detail update
  }

  return result
}

export const mapDispatch = (dispatch, { category }) => {
  const cb = () =>
    dispatch({
      type: types.DETAILS_MUTATE_TOGGLE,
      category: `${category.toLowerCase()}s`,
    })

  return {
    action: data =>
      dispatch(createOrUpdate({ ...data, category: category.toLowerCase() })),
    onKeyDown: event => onKeyDown(event, cb),
    reinitializeForm: () => dispatch({ type: types.DETAIL_FORM_REINITIALIZED }),
  }
}

export default connect(
  mapState,
  mapDispatch
)(reduxForm({ validate })(FormCont))
